import { useContext, useEffect, useRef, useState } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, getParentData, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const RadioControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    // const controlDomain = state?.domain?.get(control.props.domainCategoryCode) as DomainElement[];
    const [defaultValue, setDefaultValue] = useState(control?.props?.customProperties?.default ?? "");
    const radioTextClassName = control?.props?.radioTextClassName;
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const readOnly =
        control.readOnly ||
        evaluateExpression(control.readOnlyExpression, state?.data, getControlValueFromState(parentDataKey as string, state as State));

    useEffect(() => {
        const errorMessages = validateFormField(control, data, state, control?.props?.label, dataKey);
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
        
         // Set the default value in the state if the data is empty
         if (isEmpty(data) && !isEmpty(defaultValue)) {
            handleControlValueChange({ control, value: defaultValue, dataKey, parentDataKey, state, dispatch });
        }
    }, []);

    const parentData = control.props.parentId && getParentData(parentDataKey + '.' + control.props.parentId, state as State);

    let controlDomain =
        (state?.domain?.get(control.props.domainCategoryCode) as DomainElement[])?.filter((domain: DomainElement) => {
            if (
                parentData == null ||
                control.props.parentId === null ||
                control.props.parentId === undefined ||
                control.props.parentId.length === 0
            )
                return true;
            else return domain.parentCode == parentData;
        }) ?? [];

    //const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => console.log(`${event.target.id} - ${event.target.value}`);

    return (
        <fieldset>
            {control.props.label && <legend className="form-label w-100">{`${control.props.label} `}</legend>}

            {controlDomain?.length > 0 &&
                controlDomain.map((domain, index) => (
                    <div key={domain.code} className={`form-check form-check-inline`}>
                        <input
                            id={`${control.id}-${index}`} // Create a unique id using the index
                            data-testid={`${control.id}-${index}`} // Update test id too if needed
                            className={`form-check-input rounded-circle`}
                            type="radio"
                            name={dataKey}
                            value={domain.code}
                            // checked={data === domain.code}
                            checked={(!isEmpty(data) && data === domain.code) || (isEmpty(data) && defaultValue === domain.code) }
                            onChange={(event) =>
                                handleControlValueChange({ control, value: event.target.value, dataKey, parentDataKey, state, dispatch })
                            }
                            disabled={readOnly ?? false}
                            ref={formControlRef}
                        />
                        <label className="form-check-label" htmlFor={`${control.id}-${index}`}>
                            {domain.value}
                        </label>
                    </div>
                ))}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </fieldset>
    );
};

export default RadioControl;
