import React, { useContext, useState, useEffect, useRef } from 'react';
import { SimpleFormControlArguments, State } from '../../../library/Core/SmartTypes';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { Link } from 'react-router-dom';
import axios from 'axios';

const Draftmail = ({ pageTitle, ...args }: { pageTitle: any; }) => {
    document.title = pageTitle;

    const limitMessage = (message: string, maxLength: number) => {
        if (message.length > maxLength) {
            return message.substring(0, maxLength) + '...'; // Display an ellipsis if the message is longer
        }
        return message;
    };

    const currentDate = new Date();
    const date = currentDate.toDateString();
    const urlParams = new URLSearchParams(window.location.search);
    const templateId = urlParams.get('template_id');
    const [templateData, setTemplateData] = useState<any[]>([]);
    let sesinstituteId = 0;
    const userDataString = sessionStorage.getItem('user');
    console.log(userDataString, 'session');
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.primaryInstitute;
    }
    const schoolId = sesinstituteId;
    const fetchData = async () => {
        try {
            const jwtToken = localStorage.getItem('user-jwt-token');
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/get-all-template`, {
                school_ref_id: sesinstituteId,
            }, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`  // Using Bearer token authentication
                }
            });
            setTemplateData(response.data.templates);
            const responseData = response.data; // Assuming that the response is a JSON object
            console.log(responseData);
            // Extract the "message" and "subject" from the response

            const messageFromAPI = responseData.message;
            const subjectFromAPI = responseData.subject;

            // Use the extracted data as needed
            console.log('Message from API:', messageFromAPI);
            console.log('Subject from API:', subjectFromAPI);

            //setAttachedFilePath(attachedFilePathFromAPI);
            // Rest of your code
        } catch (error) {
            // Handle errors here
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function when the component mounts
    }, []); // Empty dependency array to run once on mount

    const selectedStudentsString = sessionStorage.getItem('student_data_for_email');

    if (selectedStudentsString) {
        // Parse it back to an array
        const selectedStudents: string[] = JSON.parse(selectedStudentsString);
        const parsedStudents: string[] = selectedStudents.map((uuid: string) => {
            // Replace 'parseUuid' with your parsing logic
            return uuid;
        });
        console.log('user', parsedStudents);
    }

    return (
        <div className="main flex-1">
            <div className="max-1140 mx-auto d-flex h-100">
                <div className="flex-1 d-flex flex-column mb-5 w-100">
                    <div className="pageContent flex-1">
                        <div className="mb-5 pb-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div
                                        className="white-block
                                        white-block-notopborderradius h-100
                                        p-0">
                                        <div
                                            className="d-flex
                                            justify-content-between
                                            align-items-center my-3 px-4">
                                            <h3
                                                data-aos="fade-left"
                                                data-aos-delay="600"
                                                className="font-35 font-600 mb-0
                                                block-title aos-init
                                                aos-animate">
                                                Broadcast
                                            </h3>
                                            <div
                                                className="dashboard innerpage
                                                smart-scroll">
                                                <Link
                                                    to={`/broadcast/createnew`}
                                                    className="btn btn-green w-auto
                                                height-40 d-inline-flex
                                                text-white font-14
                                                text-decoration-none
                                                job_internship_btn aos-init
                                                aos-animate">
                                                    <span> Create New</span>
                                                </Link>
                                            </div>
                                        </div>

                                        <div
                                            className="job_internship_tab
                                            polartabsec px-4 mx-0">
                                            <div className="xscroll p-0">
                                                <ul
                                                    className="nav nav-tabs"
                                                    id="JobInternshipTab"
                                                    role="tablist"
                                                    data-aos="fade-left"
                                                    data-aos-delay="900">
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast" className="nav-link active">
                                                            {' '}
                                                            Drafts{' '}
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/schedule-mail" className="nav-link">
                                                            Scheduled
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/sent-mail" className="nav-link">
                                                            Completed
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div
                                            className="row m-0 pt-4 pb-5 mt-2
                                            JobInternshipListing px-3">
                                            <div
                                                className="flex-1 polarcontsec
                                                tab-content"
                                                id="myTabContent"
                                                data-aos="fade-left"
                                                data-aos-delay="1200">
                                                <div
                                                    className="tab-pane fade show
                                                    active"
                                                    id="draftsbroadcast"
                                                    role="tabpanel"
                                                    aria-labelledby="draftsbroadcast-tab">
                                                    <div className="row EventsBlk">
                                                        <div
                                                            className="col-lg-12
                                                            col-md-12
                                                            broadcast_table">
                                                            <div
                                                                className="table-responsive
                                                                attendee_dtl">
                                                                <table className="table" style={{ minWidth: '767px' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Broadcast Title</th>
                                                                            <th>Created Date</th>
                                                                          
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {templateData.map((template, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    {template.template_ref_id ? (
                                                                                        <Link to={`/broadcast/addcontent?temp_id=${template.template_ref_id}&id=${template.id}`}>
                                                                                            {limitMessage(template.subject, 100)}
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <Link to={`/broadcast/createnew?id=${template.id}`}>
                                                                                            {limitMessage(template.subject, 100)}
                                                                                        </Link>
                                                                                    )}
                                                                                </td>
                                                                                <td>{template.created_date}</td>
                                                                                
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="tab-pane fade"
                                                    id="scheduledbroadcast"
                                                    role="tabpanel"
                                                    aria-labelledby="scheduledbroadcast-tab">
                                                    <div className="row EventsBlk">
                                                        <div
                                                            className="col-lg-12
                                                            col-md-12
                                                            broadcast_search_module">
                                                            <div className="search_module">
                                                                <div className="broadcastSearch_wrap">
                                                                    <form
                                                                        className="broadcastSearch
                                                                        position-relative">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn
                                                                            icon-search"></button>
                                                                        <input
                                                                            className="form-control
                                                                            flex-1
                                                                            font-14
                                                                            font-500
                                                                            w-100"
                                                                            type="search"
                                                                            aria-label="Search"
                                                                            placeholder="Search
                                                                            by
                                                                            broadcast
                                                                            title"
                                                                        />
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className="broadcast_count">
                                                                Showing <span className="current_broadcast">1-3</span>
                                                                of <span className="total_count">3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Draftmail;
