import { useContext, useEffect, useState } from 'react';
import alumniChatWindowImg from '../../styles/images/alumni-chat-window.svg';
import AsyncSelect from 'react-select/async';
import { SmartContext } from '../../library/Core/SmartContext';
import { axiosClient } from '../../library/Service/axiosClient';
import { isEmpty } from '../../library/Core/SmartFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import useMessages from './useMessages';

const MessageHeader = (props: any) => {
    const { uiid1, uiid2, uiid3 } = useParams();
    const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();
    const { loadConversationByUserId } = useMessages();
    // State variable to keep track of active tab
    const [activeTab, setActiveTab] = useState('all');

    const handleAllChatsClick = () => {
        setActiveTab('all'); // Set active tab to 'all'
        navigate('/messages', { state: { tab: 'all' } });
    };

    const handleStarredChatsClick = () => {
        setActiveTab('starred'); // Set active tab to 'starred'
        navigate('/messages', { state: { tab: 'starred' } });
    };

    let defaultOptions = [] as any[];
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [customHeight, setCustomHeight] = useState('45px');
    const [customWidth, setCustomwidtht] = useState('100px');
    const [memberList, setMemberList] = useState([] as any[]);
    // let memberIds = [] as any;
    const loadOptions = async (inputValue: string) => {
        if (isEmpty(inputValue) || inputValue.length <= 2) {
            return [];
        }
        try {
            const response = await axiosClient().get(
                `${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search?term=${inputValue}`
            );
            const userData = response.data?.students || [];
            // Assuming your API response contains an array of options
            return userData.map((option: any) => ({
                value: option.uuid,
                label: option.firstName + ` ` + option.lastName,
                uuid: option.uuid,
                firstName: option.firstName,
                passOutYear: option.passOutYear,
                lastName: option.lastName,
                name: option.lastName,
                profilePictureFilePath: option.profilePictureFilePath,
                mobile: option.mobile,
                registeredEmailId: option.registeredEmailId,
                universityId: option.universityId,
                companyName: option.companyName,
                profileImage: option.profileImage,
                userTypeCode: option.userTypeCode,
                programName: option.programName,
                programId: option.programId,
                designation: option.designation,
                schoolName: option.schoolName,
            }));
        } catch (error) {
            console.error('Error fetching options:', error);
            return [];
        }
    };

    const handleOnChange = (selectedOptionsElement: any) => {
        let memberList = state?.data?.memberIds || [];
        // alert(memberList);
        console.log(memberList);
        setMemberList((prevMemberList) => {
            const updatedMemberList = [
                ...memberList,
                ...selectedOptionsElement.filter((member: any) => !prevMemberList.some((prevMember) => prevMember.uuid === member?.uuid)),
            ];
            console.log('Updated memberList: ', updatedMemberList);
            return updatedMemberList;
        });

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'messageMode', value: 'NEW_MESSAGE' } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversationId', value: '' } });
        setSelectedOptions([]);
    };

    useEffect(() => {
        if (memberList?.length > 0) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'memberIds', value: memberList },
            });
        }
    }, [memberList]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userIds = [uiid1, uiid2, uiid3].filter((id) => id); // Filter out empty values
                if (isEmpty(userIds)) {
                    return;
                }
                const requests = userIds.map((userId) =>
                    axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search?userId=${userId}`)
                );

                const responses = await Promise.all(requests);
                const userData = responses.map((response) => response.data?.students || []).flat();

             

                // const mappedOptions = userData.map((option: any) => ({
                //     value: option.uuid,
                //     label: `${option.firstName} ${option.lastName}`,
                //     uuid: option.uuid,
                //     firstName: option.firstName,
                //     passOutYear: option.passOutYear,
                //     lastName: option.lastName,
                //     name: option.lastName,
                //     profilePictureFilePath: option.profilePictureFilePath,
                //     mobile: option.mobile,
                //     registeredEmailId: option.registeredEmailId,
                //     universityId: option.universityId,
                //     companyName: option.companyName,
                //     profileImage: option.profileImage,
                //     userTypeCode: option.userTypeCode,
                //     programName: option.programName,
                //     programId: option.programId,
                //     designation: option.designation,
                //     schoolName: option.schoolName,
                   
                // }));

                const uniqueUUIDs = new Set();

                //Map userData to a new structure while filtering out duplicates
                const mappedOptions = userData.reduce((accumulator, option) => {
                    // Check if the UUID is already in the Set
                    if (!uniqueUUIDs.has(option.uuid)) {
                        // Add the UUID to the Set
                        uniqueUUIDs.add(option.uuid);

                        // Push the option into the accumulator
                        accumulator.push({
                            value: option.uuid,
                            label: `${option.firstName} ${option.lastName}`,
                            uuid: option.uuid,
                            firstName: option.firstName,
                            passOutYear: option.passOutYear,
                            lastName: option.lastName,
                            name: option.name, // Assuming 'name' refers to the concatenated name, correct it if not
                            profilePictureFilePath: option.profilePictureFilePath,
                            mobile: option.mobile,
                            registeredEmailId: option.registeredEmailId,
                            universityId: option.universityId,
                            companyName: option.companyName,
                            profileImage: option.profileImage,
                            userTypeCode: option.userTypeCode,
                            programName: option.programName,
                            programId: option.programId,
                            designation: option.designation,
                            schoolName: option.schoolName,
                        });
                    }
                    return accumulator;
                }, []);

                handleOnChange(mappedOptions);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [uiid1, uiid2, uiid3]);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            minHeight: customHeight,
            minWidth: customWidth,
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            minHeight: customHeight,
            minWidth: customWidth,
            overflow: 'hidden',
        }),
        input: (provided: any) => ({
            ...provided,
            minHeight: customHeight,
            minWidth: customWidth,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            minHeight: customHeight,
            minWidth: customWidth,
        }),
        multiValue: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
    };
    return (
        <>
            <section className="" id="alumni_directory">
                <div className="bg-red py-3 py-sm-4 container-fluid">
                    <div className="max-1140 mx-auto position-relative">
                        <div className="row alumni-directory my-2">
                            <div className="col alumni-directory-header mb-0">
                                <div className="alumni-directory-header_search flex-column align-items-start z9 overflow-visible">
                                    <h2
                                        className="font-22 font-600 text-white mb-2 me-3 aos-init aos-animate">
                                        Alumni Chat Window
                                    </h2>
                                    <div className="alumni-directorySearch-wrap mt-2 mt-sm-0">
                                        {/* <form className="alumni-directorySearch height-45"> */}
                                        <AsyncSelect
                                            isMulti
                                            isClearable={false}
                                            onChange={handleOnChange}
                                            loadOptions={loadOptions}
                                            components={{
                                                DropdownIndicator: null,
                                                IndicatorSeparator: null,
                                            }}
                                            placeholder={`Search by Alumni Name`}
                                            styles={customStyles}
                                            //defaultValue={memberIds}
                                            value={selectedOptions}
                                        />
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="alumni-chat-img">
                                    <img src={alumniChatWindowImg} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-liteblue-F2F9FF container-fluid">
                    <div className="max-1140 mx-auto py-3">
                        <ul className="nav chat-tab">
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 'all' ? 'active' : ''}`} onClick={handleAllChatsClick}>
                                    All Chats
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 'starred' ? 'active' : ''}`} onClick={handleStarredChatsClick}>
                                    Starred Chats
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MessageHeader;
