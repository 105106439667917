import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const Parent: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { schoolDomain } = useParams<{ schoolDomain: string }>();

    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const [childFirstName, setChildFirstName] = useState<string>('');
    const [childLastName, setChildLastName] = useState<string>('');
    const [contactNumber, setContactNumber] = useState<string>('');
    const [mobileCountryCode, setMobileCountryCode] = useState<string>('91'); // Assuming 91 as default country code
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();
    }, [schoolDomain]);

    const handleSubmit = async (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        const newErrors: { [key: string]: string } = {};

        if (!childFirstName) {
            newErrors.childFirstName = 'Child’s first name is required';
        }
        if (!childLastName) {
            newErrors.childLastName = 'Child’s last name is required';
        }
        if (!contactNumber) {
            newErrors.contactNumber = 'Contact number is required';
        } else {
            const isIndiaCode = mobileCountryCode === '91';
            const contactNumberRegex = isIndiaCode
                ? /^[6-9]\d{9}$/
                : /^[1-9]\d{5,}$/;
            if (!contactNumberRegex.test(contactNumber)) {
                newErrors.contactNumber = isIndiaCode
                    ? 'Indian contact number must be 10 digits and start with 6-9'
                    : 'Contact number must be more than 6 digits and start with 1-9';
            }
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/save-data`, {
                    first_name: childFirstName,
                    last_name: childLastName,
                    phone_number: contactNumber,
                    user_type: 1, // assuming user_type is 2, change if needed
                    country_code: `+${mobileCountryCode}`
                });

                if (response.data.success) {
                    const insertId = response.data.result[0].insertId;
                    console.log('Inserted Record ID:', insertId);
                    // Redirect to the 'parent-child-info' page with insertId as query parameter
                    window.location.href = 'parent-child-info?id='+insertId;
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { id, value } = e.target;
        if (id === 'childFirstName') {
            setChildFirstName(value);
        } else if (id === 'childLastName') {
            setChildLastName(value);
        } else if (id === 'contactNumber') {
            setContactNumber(value);
        } else if (id === 'mobileCountryCode') {
            setMobileCountryCode(value);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#"><img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" /></a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-start align-items-md-center text-white py-5 px-4">
                        <div className="w-1030">
                            <div className="form-title">Make Your Child a Member of this Elite Community at No Cost.</div>
                            <div className="sep blue mx-0 mx-sm-auto"></div>
                            <div className="pb-5">
                                <form className="min-height-250" onSubmit={handleSubmit}>
                                    <div className="row g-4 align-items-end justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="childFirstName" className="form-label">Child’s First Name*</label>
                                            <input
                                                type="text"
                                                className="form-control height-46 border-0"
                                                id="childFirstName"
                                                placeholder="The star who made us proud!"
                                                value={childFirstName}
                                                onChange={handleInputChange}
                                            />
                                            {errors.childFirstName && <div className="text-danger">{errors.childFirstName}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="childLastName" className="form-label">Child’s Last Name*</label>
                                            <input
                                                type="text"
                                                className="form-control height-46 border-0"
                                                id="childLastName"
                                                placeholder="The star who made us proud!"
                                                value={childLastName}
                                                onChange={handleInputChange}
                                            />
                                            {errors.childLastName && <div className="text-danger">{errors.childLastName}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="contactNumber" className="form-label">Contact Number*</label>
                                            <div className="row g-3">
                                                <div className="col-auto">
                                                    <select
                                                        id="mobileCountryCode"
                                                        name="user.mobileCountryCode"
                                                        className="form-select form-control country-code"
                                                        value={mobileCountryCode}
                                                        onChange={handleInputChange}
                                                    >
                                                        <optgroup label="Top Countries">
                                                            <option value="91">+91</option>
                                                            <option value="44">+44</option>
                                                            <option value="1">+1</option>
                                                            <option value="61">+61</option>
                                                            <option value="49">+49</option>
                                                            <option value="7">+7</option>
                                                            <option value="86">+86</option>
                                                            <option value="65">+65</option>
                                                            <option value="971">+971</option>
                                                            <option value="81">+81</option>
                                                        </optgroup>
                                                        {/* Additional countries... */}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control height-46 border-0"
                                                        id="contactNumber"
                                                        placeholder="Limited & Secure Calls/WhatsApps"
                                                        value={contactNumber}
                                                        onChange={handleInputChange}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                    {errors.contactNumber && <div className="text-danger">{errors.contactNumber}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Removed the "Next" button */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-white py-5 d-none d-md-flex">
                        <div className="col-auto">Powered by</div>
                        <div className="col-auto"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-white-logo.svg" width="167" alt="Univariety" /></div>
                    </div>
                </div>
            </section>
            <section className="white-section d-block d-md-none">
                <div className="container py-5">
                    <div className="text-center">Powered by</div>
                    <div className="text-center mb-2"><img src="images/univariety-dark-logo.svg" width="167" alt="Univariety" /></div>
                </div>
            </section>
            <div className="navigation_footer">
                <a href="identfyuser" className="left-link"></a>
                <a href="javascript:;" className="right-link" onClick={handleSubmit}></a>
            </div>

            {/* Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered w-860 mx-auto px-3">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn-close position-absol" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <img src="images/letter.png" width="860" className="img-fluid" alt="letter" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Parent;
