import { isEmpty } from '../../library/Core/SmartFunctions';
import courseAlumniProfile from '../../styles/images/course_alumni_profile.svg';
import dummyFemale from '../../styles/images/dummy_female.svg';
import dummyMale from '../../styles/images/dummy_male.svg';

const getStudentImage = ({ profilePictureFilePath, genderCode }: any) => {
    // if (profilePictureFilePath) return profilePictureFilePath;
    // return genderCode === 'MALE' ? dummyMale : dummyFemale;

    if (isEmpty(genderCode)) {
        genderCode = 'MALE';
    }

    let profileImage = profilePictureFilePath;
    if (profileImage == '' || profileImage == null) {
        profileImage =
            genderCode == 'MALE'
                ? `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`
                : `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student.svg`;
    }

    return profileImage;
};

const getUniversityImage = (logo: any) => {
    let no_img = `${process.env.REACT_APP_IMAGE_BASEPATH}/no_img.png`;
    if (logo == 'no_img.png' || logo == '' || logo == null || logo == undefined) return no_img;

    // TODO: This path need to be verified and corrected 
    //update logo path with s3 in instittue table
    // return `${process.env.REACT_APP_IMAGE_BASEPATH}/${logo}`;
    return `${logo}`;
    // let resource = `${process.env.REACT_APP_IMAGE_BASEPATH}/${logo}`;
    // let isImageValid = validateImage(resource);
    // return isImageValid ? resource : `${process.env.REACT_APP_IMAGE_BASEPATH}/no_university_img.svg`;
};

const validateImage = (resource: string): boolean => {
    new Promise((resolve, reject) => {
        let img = fetchImage(resource);
        if (!img) {
            reject(false);
        } else {
            resolve(true);
        }
    })
        .then((response) => {
            return response;
        })
        .catch((errorResponse) => {
            return false;
        });

    return false;
};

const fetchImage = async (resource: string) => {
    try {
        const response = await fetch(resource);
        const data = await response.blob();
        const imageURL = URL.createObjectURL(data);
        return true;
    } catch (error) {
        return false;
    }
};

const getCompanyImage = (logo: string) => {
    if (isEmpty(logo)) return `${process.env.REACT_APP_IMAGE_BASEPATH}/briefcase.svg`;
    else return logo;
    // TODO: This path need to be verified and corrected
    return `${process.env.REACT_APP_IMAGE_BASEPATH}/${logo}`;
};
export { courseAlumniProfile, dummyFemale, dummyMale, getStudentImage, getUniversityImage, getCompanyImage };
