import { AxiosResponse } from 'axios';
import { getValueFromSessionStore, storeAppConfig } from '../../../library/Core/SessionStore';
import { convertDomainArrayToMap, isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import AppSettingsService from '../../../services/app-settings.service';
import DomainDataService from '../../../services/domain-data.service';
import pageConfig from './PageConfig.json';

import { LocalStorageResponse } from '../../../library/Core/PageLoader';
const API_URL = `${process.env.REACT_APP_STUDENT_SERVER}/memories`;

class ContactService {
    static getData = (
        pageName: string,
        id: string,
        searchBy: string
    ): Promise<any> | Promise<AxiosResponse<any, any>> | Promise<LocalStorageResponse> => {
        let data = [];

        if (id === 'new') {
            return Promise.resolve({});
        }

        return Promise.resolve(axiosClient().get(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/${id}/contacts`))
            .then((data) => {
                return data.data.data;
            })
            .catch((error) => {
                // Handle error if needed
                console.error('Error fetching data:', error);
                throw error; // Re-throw the error to propagate it to the caller
            });
    };

    static pageLoader = (params: any) => {
        console.log('params', params);
        const state: any = {};
        const { id,groupId, pageName, searchBy } = params;

        logger.log(`Loading ${pageName}`);
        console.log(`groupid ${groupId}`);
        return Promise.all([
            this.getPageConfig(),
            ContactService.getData(pageName, id, searchBy),
            DomainDataService.get(),
            AppSettingsService.get(),
        ])
            .then((values) => {
                state.formConfig = values[0];
                state.data = values[1];
                state.domain = values[2].data instanceof Map ? values[2].data : convertDomainArrayToMap(values[2].data);
                state.internal = { gridState: [] };
                state.routeInfo = { id, groupId,pageName };
                // storeDomainData(state.domain);
                storeAppConfig(values[3].data);
                return Promise.resolve(state);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }
}

export default ContactService;
