import { useContext, useEffect, useRef, useState } from 'react';
import darkLogo from '../../../styles/images/dark-logo.svg';
// import './styles.css';
import { useLocation, useParams } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import ForgotPassword from './ForgotPassword';
import { initNextBtnClickOnEnterEvent } from '../../../library/Core/SmartFunctions';
import LoginCarouselControl from '../../auth/login/LoginCarouselControl';
// import './form_css.css';

declare global {
    interface Window {
        YT: any; // Add this declaration for the YT property
    }
}

const SignupSocialLayout = (props: any) => {
    const location = useLocation();
    const schoolSettings = localStorage.getItem('school_settings');
    const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
    const schoolVideoId = parsedSchoolSettings?.youtubeId ?? '';

    const { state, dispatch } = useContext(SmartContext);
    const [forgotPasswordLayout, setForgotPasswordLayout] = useState(false);
    const [showForgotPassEmailWindow, setShowForgotPassEmailWindow] = useState(true);
    let schoolImage = sessionStorage.getItem('school-image') as any;
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    let player: any = null;
    const playerRef = useRef<any>(null);

    const onYouTubeIframeAPIReady = () => {
        if (playerRef.current === null) {
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: `${schoolVideoId}`,
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    };
    const backtoLogin = () => {
        setForgotPasswordLayout(false);
        setShowForgotPassEmailWindow(false);
    };
    const onPlayerReady = (event: any) => {
        event.target.playVideo();
    };

    const openVideoModal = () => {
        setVideoModalOpen(true);
        if (window.YT && typeof window.YT.Player !== 'undefined') {
            if (player === null) {
                onYouTubeIframeAPIReady();
            } else {
                player.playVideo();
            }
        }
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);
        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
            playerRef.current = null;
        }
    };

    useEffect(() => {
        initNextBtnClickOnEnterEvent(); // @AGS-586 @dt 19th.Sep,2K23 @by RG;

        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    const handleForgotPasswordClick = () => {
        setForgotPasswordLayout(true);
        setShowForgotPassEmailWindow(true);
    };

   
    return (
        <div className="container-fluid bg_side_blue landing_login_page d-flex flex-1">
            <div className="container max-1300 flex-1">
                <div className="row h-100 position-relative z1">
                    <div className="col-md-6 d-none d-md-flex flex-column">
                    <div className="custom_logo text-center">
                            <img src={schoolImage} width="200" className="img-fluid" />
                        </div>
                        <div className="leftPaneMain">
                            <LoginCarouselControl />
                            {schoolVideoId != '' && (
                                <button className="btn-View-appeal mt-3 video-btn" onClick={openVideoModal}>
                                    <i></i>
                                    <span>View appeal from the Principal</span>
                                </button>
                            )}
                        </div>                     
                        <div className="leftPaneFooter">
                            <div className="row g-4 align-items-center">
                                <div className="col-md">
                                    <div className="copyRight">
                                        International Educational Gateway Pvt. Ltd. © 2024
                                        <span>All Rights Reserved . Univariety</span>
                                    </div>
                                </div>
                                <div className="col-md-auto">
                                    <div className="powerby">
                                        <span>Powered By</span>
                                        <img src={darkLogo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="signup h-100" aria-autocomplete="none">
                            <div className="custom_logo text-center d-block d-md-none">
                                <img src={schoolImage} width="200" className="img-fluid" />
                            </div>
                            
                                {/* <ul className="col-12 nav nav-tabs px-0 px-sm-3 px-md-5" id="myloginTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link active`}
                                            id="existing-user-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#existing-user"
                                            type="button"
                                            role="tab"
                                            aria-controls="existing-user"
                                            aria-selected="false"
                                            onClick={props.handleExistingUser}>
                                            Existing User
                                        </button>
                                    </li>
                                    
                                </ul> */}
                            

                            <div className="col-12 tab-content px-0 px-sm-3 px-md-5" id="myloginTabContent">
                                {state?.businessValidationErrors?.length && (
                                    <div
                                        className={`bg ${state?.businessValidationErrors[0].success ? 'success-message' : 'fail-message'}`}>
                                        {state?.businessValidationErrors?.map((item: any) => (
                                            <p key={item.message}>{item.message}</p>
                                        ))}
                                    </div>
                                )}
                                {!forgotPasswordLayout ? (
                                    props.children
                                ) : (
                                    <ForgotPassword showForgotPassEmailWindow={showForgotPassEmailWindow} />
                                )}

                                {/* <p>
                                    Don't have an account? <Link to={`/alumni/${schoolDomain}/signup`}>Sign Up</Link>
                                    <button onClick={handleForgotPasswordClick}>Sign Up</button>
                                </p> */}
                                <div className="action_btns">
                                    {forgotPasswordLayout == false && (
                                        <>
                                            <label className="btn-forgotpw">
                                                Forgot Password?{' '}
                                                <a href="#" role="button" onClick={handleForgotPasswordClick}>
                                                    click here
                                                </a>
                                            </label>
                                        </>
                                    )}

                                    {forgotPasswordLayout == true && (
                                        <>
                                            <a className="btn-relogin" href="" onClick={backtoLogin}>
                                                Return to login?
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="video_pop"
                className={`modal fade show ${isVideoModalOpen ? 'show' : ''}`}
                tabIndex={-1}
                role="dialog"
                style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <button type="button" className="btn-close" onClick={closeVideoModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="ratio ratio-16x9">
                                <div id="youtube-player"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SignupSocialLayout;
