import { Dropdown, Modal } from 'bootstrap';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSmartState from '../../../common/hooks/useSmartState';
import { getStudentImage } from '../../../common/styles/Images';
import { SmartContext } from '../../../library/Core/SmartContext';
import { formatFullName, getDomainValueForCode, handleCopyText, isEmpty } from '../../../library/Core/SmartFunctions';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';
import ManageAlumniService from '../../../features/management/alumni-list/ManageAlumniService';
import ShareActionModal from '../../../features/management/alumni-list/ShareActionModal';
import ShareEmailActionModal from '../../../features/management/alumni-list/ShareEmailActionModal';
import { Column } from '../../../features/management/alumni-list/report-config';
import { javascript } from 'webpack';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

const AlumniListGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const [visibleStudents, setVisibleStudents] = useState(state.data.alumniList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.alumniList] ?? [];
    const [userTypeCode, setUserTypeCode] = useState(sessionStorage.getItem('user-type-code'));
    
    console.log(allRows, 'allRows')
    useEffect(() => {
        console.log(state.data.alumniList);
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.alumniList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);

    const handleSelectStudent = (e: any) => {
        if (e.target.checked) {
            setSelectedStudents((prevStudents) => [...prevStudents, e.target.value]);
        } else {
            setSelectedStudents((prevStudents) => prevStudents.filter((id) => id !== e.target.value));
        }
    };

    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const handleColumnSort = (column: string) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    // const getTagline = (row: any) => {
    //     if (state.routeInfo.pageName.includes('student')) return `${row.programName ?? ''}- ${row.passOutYear}`;
    //     if (state.routeInfo.pageName.includes('alumni')) return `${row.programName}- ${row.passOutYear}`;
    // };
    const getTagline = (row: any) => {
        if (!isEmpty(row.passOutYear)) {
            return `${row.programName} - ${row.passOutYear}`;
        } else {
            return `${row.programName}`;
        }
    };

    
        const handlePerformAction = async (action: string, students: any) => {
            const urlParams = new URLSearchParams(window.location.search);
            const templateId = urlParams.get("template_id");
            const temp_id = urlParams.get('temp_ref_id');
            const students_data=selectedStudents;
            console.log('Selected Students:', selectedStudents);
            const lengthOfSelectedStudents = selectedStudents.length;
          //  alert(lengthOfSelectedStudents);
           if(lengthOfSelectedStudents===0){
            alert("Please select atleast 1 user");
           }else{
            
            
            // Serialize the selectedStudents array to a string
     const selectedStudentsString = JSON.stringify(selectedStudents);
    
    // Store it in sessionStorage
    sessionStorage.setItem('student_data_for_email', selectedStudentsString);
    if (temp_id) {
        window.location.href = `/broadcast/templatepreview?template_id=${templateId}&temp_id=${temp_id}`;
    } else {
        window.location.href = `/broadcast/emailpreview?template_id=${templateId}`;
    }
           }
        };
    

    const socialShare = async () => {
        try {
            // Your logic here
            //console.log('updateAction logic');
            const modalElement = document.getElementById('social-share');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const socialEmailShare = async () => {
        try {
            // Your logic here
            //console.log('updateAction logic');
            const modalElement = document.getElementById('social-email');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const handleAction = (uuid: string, action: string) => {
        console.log(`handleAction: ${uuid} / ${action}`);
        handlePerformAction(action, [uuid]);
    };

    const getValidActions = (data: any) => {
        // return actions.filter((item: any) => item !== (userStatusCode === 'ACTIVE' ? 'Activate' : 'Deactivate'));
        return actions.filter((item: any) => {
            let flag = true;
            switch (item) {
                case 'Activate':
                    if (data.userStatusCode == 'ACTIVE') {
                        flag = false;
                    }
                    break;

                case 'Deactivate':
                    if (data.userStatusCode != 'ACTIVE') {
                        flag = false;
                    }
                    break;

                case 'Mark as Notable':
                    if (data.isNotable == 1) {
                        flag = false;
                    }
                    break;

                case 'Unmark as Notable':
                    if (data.isNotable != 1) {
                        flag = false;
                    }
                    break;

                case 'Convert To Alumni':
                    let cYear = new Date().getFullYear();
                    if (userTypeCode != 'SUPER_ADMIN' || data.passOutYear > cYear) {
                        flag = false;
                    }
                    break;

                default:
                    return true;
            }

            return flag;
        });
    };

    const handleSelectAll = (event: any) => {
        const students = event.target.checked ? allRows.map((item: any) => item.uuid) : [];
        setSelectedStudents(students);
    };

    // const showStar = (row: any) => (row.isPrestigiousCollege || row.pgIsPrestigiousCollege || row.hasReceivedScholarship ? true : false);
    const showStar = (row: any) => (row.isNotable && row.isNotable == 1 ? true : false);

    return (
        <>
            <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                    <div className="d-flex justify-content-start align-items-center customselect_parnt">
                    <button
                            className="btn btn-darkblue"
                            type="button"
                            id="BulkClickBtn"
                            onClick={() => handlePerformAction(bulkAction, selectedStudents)}>
                            <span className="line-height-16">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="table-responsive attendee_dtl">
                <div>Found {allRows.length} rows</div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th onClick={() => handleColumnSort('firstName')}>
                                <div className="d-flex flex-wrap">
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={selectedStudents.length === visibleStudents.length}
                                            id="Antony"
                                            className="form-check-input me-2 mt-0"
                                            onChange={handleSelectAll}
                                        />
                                        Name
                                    </div>
                                    {sortColumn === 'firstName' && <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>}
                                </div>
                            </th>
                            {gridColumns.includes(Column.CAMPUS) && (
                                <th className="university-name" onClick={() => handleColumnSort(Column.CAMPUS)}>
                                    Campus
                                </th>
                            )}
                            {/* {gridColumns.includes(Column.CURRICULUM) && (
                                <th onClick={() => handleColumnSort('curriculum')}>
                                    Curriculum {sortColumn === 'curriculum' && <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>}
                                </th>
                            )} */}
                            {gridColumns.includes(Column.PRESENT_STATUS) && (
                                <th onClick={() => handleColumnSort('currentStatus')}>
                                    Present Status{' '}
                                    {sortColumn === 'currentStatus' && <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>}
                                </th>
                            )}
                            {gridColumns.includes(Column.ENGAGEMENTS) && <th>Engagements</th>}
                            {gridColumns.includes(Column.COURSE) && <th>Course</th>}
                            {gridColumns.includes(Column.UNIVERSITY) && <th>University</th>}
                            {gridColumns.includes(Column.LOCATION) && <th>Location</th>}

                            {gridColumns.includes(Column.PREF_CAREER) && <th>Career</th>}
                            {gridColumns.includes(Column.PREF_COUNTRY) && <th>Country</th>}
                            {gridColumns.includes(Column.PREF_COLLEGE) && <th>College</th>}

                            {gridColumns.includes(Column.CREATED_ON) && (
                                <th onClick={() => handleColumnSort('createdAt')}>
                                    Created on {sortColumn === 'createdAt' && <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>}
                                </th>
                            )}
                            {gridColumns.includes(Column.MODIFIED_ON) && (
                                <th onClick={() => handleColumnSort('updatedAt')}>
                                    Modified on {sortColumn === 'updatedAt' && <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>}
                                </th>
                            )}
                            {gridColumns.includes(Column.ACTION) && <th className="text-center">Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {visibleStudents.map((row: any) => (
                            <tr key={row.uuid} className={`${row?.userStatusCode != 'ACTIVE' ? ' bg-warning ' : ''}`}>
                                <td>
                                    <div className="d-flex checkoption">
                                        <input
                                            className="form-check-input me-2 StudentId"
                                            name="uuid"
                                            type="checkbox"
                                            value={row.uuid}
                                            checked={selectedStudents.includes(row.uuid)}
                                            onChange={handleSelectStudent}
                                        />
                                        <div>
                                        <a
                                            className="tablink"
                                            href={row?.userStatusCode === 'ACTIVE' ? `/alumni/${row.uuid}/profile` : 'javascript:void(0);'}
                                            target={row?.userStatusCode === 'ACTIVE' ? `_blank` : ''}
                                            rel="noopener noreferrer">
                                            <i className="me-2 position-relative">
                                                <img src={getStudentImage(row)} className="rounded-circle img-fluid" width="40" />
                                                {showStar(row) && <i className="icon-star circle-img"></i>}
                                            </i>
                                            <span
                                                data-bs-toggle="tooltip"
                                                title={formatFullName(row.firstName, row.lastName)}
                                                className="singlelinename font-16 lineHeight-16">
                                                {formatFullName(row.firstName, row.lastName)}
                                                <span
                                                    className="d-block font-13 fst-italic"
                                                    data-bs-toggle="tooltip"
                                                    title={getTagline(row)}>
                                                    {' '}
                                                    {getTagline(row)}{' '}
                                                </span>
                                            </span>
                                        </a>
                                        <div className="copyId d-flex align-items-center mt-1">
                    <span className="font-13 fst-italic singlelinename me-1"> {row.uuid} </span>
                    <button
                        id={`uuid-${row.uuid}`}
                        className="copy-text border-0 bg-transparent"
                        title="click to copy id"
                        onClick={() => handleCopyText(row.uuid, `uuid-${row.uuid}`)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-copy"
                            viewBox="0 0 16 16">
                            <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                        </svg>
                    </button>
                                        </div>
                                        </div>
                                    </div>
                                </td>

                                {gridColumns.includes(Column.CAMPUS) && (
                                    <td>
                                        <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                            {row.schoolName}
                                        </div>
                                    </td>
                                )}

                                {/* {gridColumns.includes(Column.CURRICULUM) && (
                                    // <td>{getDomainValueForCode(row.curriculumCode, 'CLASS_12_ALL_CURRICULUM_CODE', state) ?? ''}</td>
                                    <td>{row.curriculum ?? ''}</td>
                                )} */}

                                {gridColumns.includes(Column.PRESENT_STATUS) && (
                                    <td>
                                        <span>
                                            Education status:{' '}
                                            {getDomainValueForCode(row.pursuingEducationLevelCode, 'PROGRAM_LEVEL', state) ?? ''}
                                        </span>
                                        <br />
                                        <span>Working status: {getDomainValueForCode(row.workingStatus, 'WORK_STATUS_CODE', state) ?? ''}</span>
                                    </td>
                                )}

                                {gridColumns.includes(Column.ENGAGEMENTS) && (
                                    <td className="text-start">
                                        {row.guidanceVideoCount > 0 && (
                                            <div>
                                                {row.guidanceVideoCount} video (
                                                <Link
                                                    className="btn-conview"
                                                    to={`/alumni/${row.uuid}/profile/contributions`}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    View
                                                </Link>
                                                )
                                            </div>
                                        )}
                                        {row.conversationCount > 0 && (
                                            <div>
                                                {row.conversationCount} Conversation (
                                                <Link
                                                    className="btn-conview"
                                                    to={`/alumni/${row.uuid}/profile/interaction`}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    View
                                                </Link>
                                                )
                                            </div>
                                        )}
                                    </td>
                                )}

                                {gridColumns.includes(Column.COURSE) && (
                                    <td>
                                        {row.fieldOfStudyCode && (
                                            <div className="course-list-row">
                                                <span>
                                                    UG -{' '}
                                                    {getDomainValueForCode(row.fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE', state) ?? ''}
                                                </span>
                                                {row.scholarshipAmountUg > 0 && (
                                                    <i
                                                        className="icon-dollor font-20"
                                                        data-bs-toggle="tooltip"
                                                        title={`Received Scholarship ${row.scholarshipAmountUg}`}></i>
                                                )}
                                            </div>
                                        )}
                                        {row.pgFieldOfStudyCode && (
                                            <div className="course-list-row">
                                                <span>
                                                    PG -{' '}
                                                    {getDomainValueForCode(row.pgFieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE', state) ?? ''}
                                                </span>
                                                {row.pgScholarshipAmount > 0 && (
                                                    <i
                                                        className="icon-dollor font-20"
                                                        data-bs-toggle="tooltip"
                                                        title={`Received Scholarship ${row.pgScholarshipAmount}`}></i>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                )}

                                {gridColumns.includes(Column.UNIVERSITY) && (
                                    <td className="text-start">
                                        {row.universityName && (
                                            <div className="d-flex flex-wrap">
                                                <div className="universityName">
                                                    <span>UG - {row.universityName}</span>
                                                    {row.isPrestigiousCollege ? (
                                                        <i
                                                            className="icon ms-1"
                                                            data-bs-toggle="tooltip"
                                                            title=""
                                                            data-bs-original-title="Prestigious College">
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/subtraction.svg`}
                                                                width="16"
                                                            />
                                                        </i>
                                                    ) : null}
                                                </div>
                                                <div className="universityName"></div>
                                            </div>
                                        )}
                                        {row.pgUniversityName && (
                                            <div className="d-flex flex-wrap">
                                                <div className="universityName">
                                                    <span>PG - {row.pgUniversityName}</span>
                                                    {row.pgIsPrestigiousCollege ? (
                                                        <i
                                                            className="icon ms-1"
                                                            data-bs-toggle="tooltip"
                                                            title=""
                                                            data-bs-original-title="Prestigious College">
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/subtraction.svg`}
                                                                width="16"
                                                            />
                                                        </i>
                                                    ) : null}
                                                </div>
                                                <div className="universityName"></div>
                                            </div>
                                        )}
                                    </td>
                                )}

                                {gridColumns.includes(Column.LOCATION) && (
                                    <td className="text-start w-110">
                                        {row.residingCityCode && (getDomainValueForCode(row.residingCityCode, 'CITY_CODE', state) ?? '')}
                                        <br />
                                        {getDomainValueForCode(row.residingCountryCode, 'COUNTRY_CODE', state) ?? ''}
                                    </td>
                                )}
                                {gridColumns.includes(Column.PREF_CAREER) && (
                                    <td className="text-start w-110">
                                        <div className="college_badges gray_badge">
                                            {row.fieldOfStudyPreferenceList &&
                                                row.fieldOfStudyPreferenceList.split('|').map((career: any, index: number) => (
                                                    <span className="badge" key={index} title={career}>
                                                        {career}
                                                    </span>
                                                ))}
                                        </div>
                                        {!row.fieldOfStudyPreferenceList && (
                                            <div className="career_badges gray_badge">
                                                <span className="badge rounded-pill text-bg-warning">
                                                    {row.isCareerChoiceSkipped === 1 ? 'Not Sure' : 'Not Updated'}
                                                </span>
                                            </div>
                                        )}
                                    </td>
                                )}

                                {gridColumns.includes(Column.PREF_COUNTRY) && (
                                    <td className="text-start w-110">
                                        <div className="college_badges gray_badge">
                                            {row.countryPreferenceList &&
                                                row.countryPreferenceList.split('|').map((country: any, index: number) => (
                                                    <span className="badge" key={country} title={country}>
                                                        {country}
                                                    </span>
                                                ))}
                                        </div>
                                        {!row.countryPreferenceList && (
                                            <div className="career_badges gray_badge">
                                                <span className="badge rounded-pill text-bg-warning">
                                                    {row.isCountryChoiceSkipped === 1 ? 'Not Sure' : 'Not Updated'}
                                                </span>
                                            </div>
                                        )}
                                    </td>
                                )}

                                {gridColumns.includes(Column.PREF_COLLEGE) && (
                                    <td className="text-start w-110">
                                        <div className="college_badges gray_badge">
                                            {row.universityPreferenceList &&
                                                row.universityPreferenceList
                                                    .split('|')
                                                    .map((university: any, index: number, array: any) => (
                                                        <span className="badge" key={university} title={university}>
                                                            {university}{' '}
                                                        </span>
                                                    ))}
                                        </div>

                                        {!row.universityPreferenceList && (
                                            <div className="career_badges gray_badge">
                                                <span className="badge rounded-pill text-bg-warning">
                                                    {row.isCollegeChoiceSkipped === 1 ? 'Not Sure' : 'Not Updated'}
                                                </span>
                                            </div>
                                        )}
                                    </td>
                                )}

                                {gridColumns.includes(Column.CREATED_ON) && (
                                    <td className="text-start w-80">{moment(row.createdAt).format('DD-MM-YYYY')}</td>
                                )}

                                {gridColumns.includes(Column.MODIFIED_ON) && (
                                 <td className="text-start w-80">
                                      {!isEmpty(row.updatedAt) ? moment(row.updatedAt).format('DD-MM-YYYY') : ''}
                                 </td>
                                )}

                                {gridColumns.includes(Column.ACTION) && (
                                    <td className="text-center">
                                        {actions.length > 1 && (
                                            <ActionSettings
                                                id={row.uuid}
                                                // actions={getValidActions(row.userStatusCode)}
                                                actions={getValidActions(row)}
                                                onClick={handleAction}
                                            />
                                        )}
                                        {actions.length === 1 && (
                                            <a
                                                className="btn-setting-msg icon-mail font-20"
                                                role="button"
                                                onClick={() => handleAction(row.uuid, 'SEND_MESSAGE')}></a>
                                        )}
                                    </td>
                                )}
                            </tr>
                        ))}
                        <tr ref={loadMoreRef} />
                    </tbody>
                </table>
            </div>
            <ShareActionModal data={selectedStudents} />
            <ShareEmailActionModal data={selectedStudents} />
            
        </>
    );
};

export default AlumniListGrid;
