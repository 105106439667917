import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../../styles/css_pre_singup/style.css';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const ThankPage: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { schoolDomain } = useParams<{ schoolDomain: string }>();

    const [schoolImage, setSchoolImage] = useState<string | null>(null);

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();
        AOS.init();
    }, [schoolDomain]);

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex position-relative">
                <div className="container d-flex flex-column">
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-start align-items-md-center text-white py-5 px-4">
                        <div className="left-graph" data-aos="fade-down-right" data-aos-duration="1000">
                            <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/left-paper.svg" alt="left-graph" />
                        </div>

                        <div className="right-graph" data-aos="fade-down-left" data-aos-duration="1000">
                            <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/right-paper.svg" alt="right-graph" />
                        </div>

                        <div className="w-1030 position-relative d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="top-bottom">
                            <div className="thumb"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/like-thumb.svg" width="315" className="img-fluid" alt="thumb" /></div>
                            <div className="thanksTxt">Thank you</div>
                            <div className="thanksTxt-sub">Your child will be invited to join the school’s official</div>
                            <div className="thanksTxt-sub-2">ALUMNI COMMUNITY.</div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-white py-5 d-none d-md-flex">
                        <div className="col-auto">Powered by</div>
                        <div className="col-auto">
                            <img src={schoolImage || 'images/univariety-white-logo.svg'} width="167" alt="Univariety" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="white-section d-block d-md-none">
                <div className="container py-5">
                    <div className="text-center">Powered by</div>
                    <div className="text-center mb-2">
                        <img src={schoolImage || 'images/univariety-dark-logo.svg'} width="167" alt="Univariety" />
                    </div>
                </div>
            </section>
            <div className="navigation_footer">
                <a href="signup" className="left-link"></a>
            </div>

            {/* Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered w-860 mx-auto px-3">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn-close position-absol" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/letter.png" width="860" className="img-fluid" alt="letter" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default ThankPage;
