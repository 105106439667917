import { Outlet } from 'react-router-dom';
import PageFooterControl from '../../library/SimpleControls/PageFooterControl';

function BlankLayout() {
    return (
        <>
            <Outlet />
            <PageFooterControl />
        </>
    );
}

export default BlankLayout;
