import { useContext, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { debounce, isEmpty } from '../../../library/Core/SmartFunctions';
import AlumniDirectoryService from './AlumniDirectoryService';

import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';
import imgPursuingHigherStudies from '../../../styles/images/group-29476.svg';
import { default as imgActiveAlumni, default as imgTotalJoinedAlumni } from '../../../styles/images/group-40733.svg';
import imgWorkingAlumni from '../../../styles/images/group-41775.svg';
import { default as imgFromYourBatch, default as imgPrestigiousCollege } from '../../../styles/images/mask-group-44.svg';
import { default as imgSameNextLevelInterests, default as imgStudyingAbroad } from '../../../styles/images/mask-group-45.svg';
import CardBottom from './CardBottom';
import { getStudentImage } from '../../../common/styles/Images';
import { Tooltip } from 'bootstrap';

const DirectorySummaryCardControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const { userTypeCode, instituteGroupId: schoolGroupId } = sessionState as SessionStateType;
    const summaryCardsData = state.data.summary;
    const directoryLevel = state.data.searchLevel;
    // const schoolId = sessionStorage.getItem('user-school-id');
    // const userTypeCode = sessionStorage.getItem('user-type-code') as string;
    // const schoolGroupId = sessionStorage.getItem('user-school-group-id');
    const [searchName, setSearchName] = useState('');

    const cardsConfig = [
        { id: 'totalJoinedAlumni', label: 'Total Alumni', image: imgTotalJoinedAlumni, applicableFor: ['INSTITUTE_ADMIN'] },
        {
            id: 'activeAlumni',
            label: 'Total Accessible Alumni',
            image: imgActiveAlumni,
            applicableFor: ['INSTITUTE_ADMIN', 'STUDENT', 'ALUMNI'],
        },
        {
            id: 'pursuingHigherStudies',
            label: 'Pursuing Higher Studies',
            image: imgPursuingHigherStudies,
            applicableFor: ['INSTITUTE_ADMIN', 'STUDENT', 'ALUMNI'],
        },
        { id: 'workingAlumni', label: 'Working Alumni', image: imgWorkingAlumni, applicableFor: ['INSTITUTE_ADMIN', 'STUDENT', 'ALUMNI'] },
        { id: 'fromYourBatch', label: 'From Your Batch', image: imgFromYourBatch, applicableFor: ['ALUMNI'] },
        {
            id: 'fromPrestigiousCollege',
            label: 'From Prestigious College',
            image: imgPrestigiousCollege,
            applicableFor: ['INSTITUTE_ADMIN', 'STUDENT'],
        },
        { id: 'sameNextLevelInterests', label: 'Same Next Level Interests', image: imgSameNextLevelInterests, applicableFor: ['ALUMNI'] },
        { id: 'studyingAbroad', label: 'Studied Abroad', image: imgStudyingAbroad, applicableFor: ['INSTITUTE_ADMIN', 'STUDENT'] },
    ];

    const summaryCardsControl = cardsConfig.filter((card) => card.applicableFor.includes(userTypeCode));

    const handleSummaryCardClick = (id: string) => {
        if (summaryCardsData[id] <= 0) {
            dispatch({ type: 'REFRESH_DATA', payload: { data: { ...state.data, students: [] } } });
            return;
        }

        const searchCriteria = {
            ...AlumniDirectoryService.getCriteriaForSelectedCard(id, state),
            context: { searchBy: '', level: directoryLevel, selectedCard: id },
        };

        AlumniDirectoryService.performSearchWithUserSelectedCriteria(searchCriteria, state, dispatch);
    };

    const debouncedHandleNameSearch = debounce((value: string) => {
        console.log("selectedCard",state.data.selectedCard)
        const searchCriteria: any = { name: value };
        if (state.data.selectedCard !== "totalJoinedAlumni") {
            searchCriteria.userStatusCode = 'ACTIVE';
        }
        // AlumniDirectoryService.performSearchWithUserSelectedCriteria({ name: value }, state, dispatch);
      AlumniDirectoryService.performSearchWithUserSelectedCriteria(searchCriteria, state, dispatch);

    }, 200); // Adjust the debounce time as needed (in milliseconds)

    const handleNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchName(event.target.value);
        debouncedHandleNameSearch(event.target.value);
    };

    const handleNameSearchClear = () => {
        setSearchName('');
        debouncedHandleNameSearch('');
    };

    const getCardBorder = (currentCard: string) => (currentCard === state.data.selectedCard ? 'active' : '');

    const [isShowSearch, setShowSearch] = useState(false);

    const handleSearchToggle = () => {
        setShowSearch(!isShowSearch);
    };

    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/${id}`);
    };

    const handleSendMessageMultiple = (event: any, uiid: any) => {
        var uiid = uiid.join('/');
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/${uiid}`);
    };

    const topAlumni = state.internal.getTopAlumni['students'][0];
    const multipleUiid = state.internal.getTopAlumni['multipleUiid'];

    //   console.log(' TEST TEST     TEST');
    // console.log(userTypeCode);

    return (
        <>
            {userTypeCode === 'STUDENT' && !isEmpty(topAlumni) && (
                <div className="container-fluid bg-white">
                    <div className="max-1140 mx-auto py-4">
                        <div className="row g-3 header-title">
                            <div className="col-sm">
                                <div className="d-flex flex-wrap">
                                    <i className="icon_message me-2"></i>
                                    <div className="d-flex flex-column flex-1">
                                        <h3 className="mb-0">Question & Answer</h3>
                                        <p className="mb-0">
                                            Chat With The Alumni Who Matches Your Profile And Get Your Doubts Clarified For Your Career
                                            Ahead.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex flex-wrap align-items-center justify-content-end">
                                    <button className="btn btn-green me-2" type="button">
                                        <span onClick={(event) => handleSendMessageMultiple(event, multipleUiid)}>Send to All</span>
                                        <i className="icon-alumni-send position-relative ms-2 me-0"></i>
                                    </button>
                                    <i
                                        className="btn-md-filter icon-right-arrow cursor-pointer"
                                        onClick={handleFilterToggle}
                                        aria-expanded={isOpen ? 'true' : 'false'}></i>
                                </div>
                            </div>
                        </div>
                        <div className={`collapse mt-4 ${isOpen ? 'show' : ''}`} id="collapseExample">
                            <div className="row g-2 g-md-4 alumni-showcase">
                                {topAlumni.map((alum: any) => (
                                    // console.log('alum');
                                    // console.log(alum);

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="alumni-bucket">
                                            <div className="alumni_class_link">
                                                <div className="alumni_name_detail">
                                                    <div className="alumni_img">
                                                        <a className="alumni_img_link" href="javascript:;">
                                                            <img src={getStudentImage(alum)} alt="Sreeharsha Shankar" />
                                                        </a>
                                                    </div>
                                                    <div className="alumni_name_brife">
                                                        <h3>
                                                            <a className="alumni_name_link" href="javascript:;">
                                                                {alum.firstName} {alum.lastName}
                                                            </a>
                                                        </h3>
                                                        <span className="alumni_campus">
                                                            {alum.programName}, {alum.passOutYear}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="send_link">
                                                    <a
                                                        role="button"
                                                        onClick={(event) => handleSendMessage(event, alum.uuid)}
                                                        className="btn-send">
                                                        <i className="icon-alumni-send"></i>
                                                        <span>Send</span>
                                                    </a>
                                                    {/* <a href="javascript:;" className="btn-send"> <i className="icon-alumni-send mx-1"></i></a> */}
                                                </div>
                                            </div>
                                            <CardBottom alumni={alum} state={state} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <section className="bg-red pt-3 pt-sm-5 pb-3 container-fluid" id="alumni_directory" data-aos="fade-down">
                <div className="max-1140 mx-auto">
                    <div className="row alumni-directory">
                        <div className="col-lg-12 alumni-directory-header">
                            <div className="alumni-directory-header_search">
                                <h2
                                    data-aos="fade-left"
                                    data-aos-delay="300"
                                    className="font-22 font-600 text-white mb-3 mb-sm-0 me-3 aos-init aos-animate">
                                    Alumni <span className="d-block d-sm-inline-block">Directory</span>
                                </h2>
                                {/* {userTypeCode != 'STUDENT' && ( */}
                                <div className={`alumni-directorySearch-wrap ${isShowSearch ? 'show' : ''}`}>
                                    <form className="alumni-directorySearch" onSubmit={(event) => event.preventDefault()}>
                                        <button type="button" className="btn icon-search"></button>
                                        <input
                                            className=" form-control "
                                            type="search"
                                            placeholder="Search by Alumni"
                                            id="SearchStudent"
                                            aria-label="Search"
                                            value={searchName}
                                            onChange={handleNameSearch}
                                        />
                                        <a className="btn-cross" id="SearchStudentBtnCross" onClick={handleNameSearchClear}></a>
                                    </form>
                                    {/* <a className="btn-alumniSearchToggle icon-search d-flex d-sm-none"></a> */}
                                    <a
                                        className="btn-alumniSearchToggle cursor-pointer icon-search d-flex d-sm-none"
                                        onClick={handleSearchToggle}></a>
                                    <div id="Studentsuggestionbox" style={{ display: 'none' }}></div>
                                </div>
                                {/* )} */}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="aldir_scroll active">
                                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
                                    {summaryCardsControl.map((control: any) => (
                                        <div key={control.id} className="col mb-4 aldir_box">
                                            <div
                                                className={`bg-white rounded alumni-directory-block h-100 aos-init aos-animate ${getCardBorder(
                                                    control.id
                                                )}`}
                                                data-aos="fade-up"
                                                role="button"
                                                onClick={() => handleSummaryCardClick(control.id)}
                                                data-aos-delay="600">
                                                <div className="number font-28 font-600 d-flex justify-content-between align-items-center">
                                                    {summaryCardsData[control.id] ?? 0}
                                                    <a className="icon-right-arrow font-14 font-600 text-decoration-none text-dark-blue"></a>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="font-16 font-500 flex-1">{control.label}</div>
                                                    <div className="alumni-directory-img">
                                                        <img src={control.image} className="img-fluid" alt="Total Joined Alumni" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DirectorySummaryCardControl;
