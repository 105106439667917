import moment from 'moment';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';

const useInternalAdminReports = () => {
    const getFormattedDate = (inDate: string) => {
        if (isEmpty(inDate)) return '';
        return moment(inDate).format('DD-MMM-YYYY');
    };

    const activateAlumni = (students: any) =>
        axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/actions/activate`, { students });

    const deactivateAlumni = async (students: any) =>
        axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/actions/deactivate`, { students });

    // const validateAlumni = async (students: any) =>
    //     axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/actions/validate`, { students});

    const validateAlumni = async (students: any) => {
        return axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/actions/validate`, { students })
            .then((response) => {
                return axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/emailsentAftervalidate`, { students });
            })
            .catch((error) => {
                console.error('Error:', error);
                throw error; // rethrow error to maintain error propagation
            });
    };

    return { getFormattedDate, activateAlumni, deactivateAlumni, validateAlumni };
};

export default useInternalAdminReports;
