import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { axiosClient } from '../../../library/Service/axiosClient';
import { Modal } from 'bootstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { dummyFemale, dummyMale } from '../../../common/styles/Images';
import SendEmailModal from './SendEmailModal';
import SelectUserModal from './SelectUserModal';
import { formatFullName, getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import EmailPreviewModal from './EmailPreviewModal';
import WtappPreviewModal from './WtappPreviewModal';
import ViewSentHistoryModal from './ViewSentHistoryModal';
import ValuableQAModal from './ValuableQAModal';
import SessionContext from '../../../library/Core/SessionContext';

const AlumniInteractionGrid = () => {
    const { sessionState } = useContext(SessionContext);
    const { state, dispatch } = useContext(SmartContext);
    const params = useParams();
    const [popupVisible, setPopupVisible] = useState(false);
    const [studentImage, setStudentImage] = useState('');
    const [studentSchoolName, setStudentSchoolName] = useState('');
    const [studentprogramName, setStudentprogramName] = useState('');
    const [StudentpassoutYear, setStudentpassoutYear] = useState('');
    const [studentName, setStudentName] = useState('');
    const [conversationData, setConversationData] = useState([]);
    const [alumni, setAlumni] = useState('');
    const [adminUsers, setAdminUsers] = useState('');
    const [sendingReason, setSendingReason] = useState('');
    const [comment, setComment] = useState('');
    const [communicationChannel, setCommunicationChannel] = useState('');
    const [errors, setErrors] = useState<{ sendingReason: string; comment: string; communicationChannel: string }>({
        sendingReason: '',
        comment: '',
        communicationChannel: '',
    });
    const [EmailSubject, setEmailSubject] = useState('');
    const [EmailBody, setEmailBody] = useState('');
    const [WtappBody, setWtappBody] = useState('');
    const [WtappHeader, setWtappHeader] = useState('');
    const [modalId, setModalId] = useState<string>('');
    const [viewSentHistory, setViewSentHistory] = useState('');
    const [valuableQAData, setValuableQAData] = useState('');
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [selectedMessageIds, setSelectedMessageIds] = useState<MessageItem[]>([]);
    type MessageItem = {
        id: any;
    };
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);
    const [valuablePage, setValuablePage] = useState(true);

    var [alumniData, setAlumniData] = useState({
        AlumniName: '',
        AlumniMobile: '',
        AlumniUserId: '',
        AlumniEmail: '',
        AlumniDesignation: '',
        AlumniAutoLoginUrl: '',
        AlumniPassoutYear: '',
        AlumnischoolName: '',
        universityName: '',
        prestigious: '',
        AlumniAchievments: '',
        AlumniCity: '',
        ProfileSendUsers: '',
        LastSentDate: '',
        seniorityLevelCode: '',
        profilePictureFilePath: '',
        schoolId: '',
        AlumniprogramName:'',
        companyId:'',
        otherCompany:'',
    });

    useEffect(() => {
        fetchAlumniData();
    }, []);

    const fetchAlumniData = async () => {
        try {
            console.log('In call');
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-alumnidetails-by-uuid`, {
                alumniId: params.id,
                instituteId: params.instituteId
            });
            const data = await response.data; // Extract the data from the response
            console.log(data);
            if (data.data) {
                console.log(data.data);
                // setAlumni(data.data);
                const alumni = data.data;
                setAlumniData({
                    ...alumniData,
                    AlumniName: formatFullName(alumni[0]?.firstName,alumni[0]?.lastName), //(alumni[0]?.firstName ?? '') + ' ' + (alumni[0]?.lastName ?? ''),
                    AlumniMobile: maskMobile(alumni[0]?.mobileCountryCode, alumni[0]?.mobileNumber),
                    AlumniUserId: alumni[0]?.UUID,
                    AlumniEmail: maskEmail(alumni[0]?.registeredEmailId),
                    ProfileSendUsers: alumni[0]?.sentUsers ? JSON.parse(alumni[0]?.sentUsers) : '',
                    LastSentDate: alumni[0]?.lastSentDate,
                    AlumniPassoutYear: alumni[0]?.passOutYear,
                    AlumnischoolName: alumni[0]?.schoolName,
                    seniorityLevelCode: alumni[0]?.seniorityLevelCode,
                    profilePictureFilePath: alumni[0]?.profilePictureFilePath,
                    schoolId: alumni[0]?.schoolId,
                    AlumniprogramName:alumni[0]?.programName,
                    companyId:alumni[0]?.companyId,
                    otherCompany:alumni[0]?.otherCompany
                });
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const displayConversationWithstudent = async (
        conversationId: string,
        fullName: string,
        programName: string,
        imagePath: string,
        studentId: string,
        schoolName: string,
        passoutYear:string
    ) => {
        const response = await axiosClient().get(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/view-message-by-id/${conversationId}`
        );
        if (response.status === 200) {
            setConversationData(response.data);
        }
        setSelectedMessageIds([]);
        setPopupVisible(true);
        setStudentImage(imagePath);
        setStudentprogramName(programName);
        setStudentpassoutYear(passoutYear);
        setStudentName(fullName);
        setStudentSchoolName(schoolName);
        const modalElement = document.getElementById('conversationList');
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }
    };

    const [selectedMessageType, setSelectedMessageType] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [conversations, setConversations] = useState([]);

    useEffect(() => {
        fetchConversations(selectedMessageType, startDate, endDate);
    }, [selectedMessageType, startDate, endDate]);

    const fetchConversations = async (messageType: any, startDate: any, endDate: any) => {
        try {
            const response = await axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-message/${params.id}`, {
                params: {
                    messageType: messageType,
                    startDate: startDate,
                    endDate: endDate,
                },
            });

            if (response.status === 200) {
                setConversations(response.data);
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
            setConversations([]);
        }
    };

    const maskEmail = (email: string) => {
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
            return email[0] + email.substring(1, atIndex).replace(/./g, 'X') + email.substring(atIndex);
        }
        return email; // Handle the case where '@' is not found, e.g., for invalid email addresses
    };

    const maskMobile = (country_code: number, mobile: string) => {
        if (mobile !== null) {
            return `+${country_code} ${'X'.repeat(mobile.length - 4)}${mobile.slice(-4)}`;
        }
        return mobile;
    };

    const displayfirstpopup = async () => {
        setCommunicationChannel('');
        setComment('');
        setErrors({ ...errors, sendingReason: '', communicationChannel: '', comment: '' });

        if (selectedMessageIds.length >= 2) {
            var closeButton = document.getElementById('conversationList')?.getElementsByClassName('btn-close')[0];
            if (closeButton instanceof HTMLButtonElement) {
                closeButton?.click();
            }

            getadminUsers(alumniData?.schoolId);
            // const modalElement = document.getElementById('send-email');
            // if (modalElement) {
            //     const modal = new Modal(modalElement);
            //     modal.show();
            // }
            dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showEmailModal' } });
        } else {
            alert('Select at least 2 messages from the conversation.');
        }
    };
    const options_main = state?.domain?.get('PROFILE_SENDING_REASONS_CATEGORY');
    const options = options_main?.filter((item) => item.code === '3');

    const handleNextPreviewAdminSelection = () => {
        const newErrors = {
            sendingReason: '',
            comment: '',
            communicationChannel: '',
        };
        // if (!sendingReason) {
        //     newErrors.sendingReason = "Please fill Sending Reason field.";
        // }
        if (!comment && sendingReason == '6') {
            newErrors.comment = 'Please fill Comment field.';
        }
        if (!communicationChannel) {
            newErrors.communicationChannel = 'Please select a Communication Channel.';
        }
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            return;
        }
        console.log('handleNextPreviewAdminSelection');
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showUserModal' } });

        // var closeButton = document.getElementById('send-email')?.getElementsByClassName('btn-close')[0];
        // if (closeButton instanceof HTMLButtonElement) {
        //     closeButton?.click();
        // }

        // const modalElement = document.getElementById('select-user-popup');
        // if (modalElement) {
        //     const modal = new Modal(modalElement);
        //     modal.show();
        // }
    };
    useEffect(() => {
        handleSendingReasonChange('3');
    }, []);

    const handleSendingReasonChange = (value: any) => {
        setSendingReason('3');
        setParametersWiseSendingReason(value, state);
    };
    const setParametersWiseSendingReason = (reasonId: any, state: any) => {
        console.log(alumniData?.AlumniCity);
        const reasonData = state.domain.get('PROFILE_SENDING_REASONS_CATEGORY');
        const extrainfo = reasonData.find((item: any) => item.code == reasonId);
        const flexData = JSON.parse(extrainfo?.flexCol as string);
        const replacements = {
            company_name: (alumniData?.otherCompany==null) ?  getDomainValueForCode(alumniData.companyId, 'COMPANY_CODE', state) : alumniData?.otherCompany,
            designation: alumniData?.AlumniDesignation ?? 'NA',
            alumni_name: alumniData?.AlumniName ?? 'NA',
            passout_year: alumniData?.AlumniPassoutYear ?? 'NA',
            school_name: alumniData?.AlumnischoolName ?? 'NA',
            college_name: alumniData?.universityName ?? 'NA',
            prestigious: alumniData?.prestigious ?? 'NA',
            achievments: alumniData?.AlumniAchievments ?? 'NA',
            location: alumniData?.AlumniCity ?? 'NA',
            autologin_url: alumniData?.AlumniAutoLoginUrl ?? 'NA',
        };
        var mail_subject = flexData ? flexData?.mail_subject : '';
        mail_subject = replaceVariables(mail_subject, replacements);
        var mail_body = flexData ? flexData?.mail_body : '';
        mail_body = replaceVariables(mail_body, replacements);
        var wtapp_body = flexData ? flexData?.wtapp_body : '';

        wtapp_body = replaceVariables(wtapp_body, replacements);

        setEmailSubject(mail_subject);
        setEmailBody(mail_body);
        setWtappBody(wtapp_body);
        setWtappHeader(flexData ? flexData?.wtapp_header : '');
    };
    function replaceVariables(subject: string, replacements: any) {
        let result = subject;
        for (const variable in replacements) {
            result = result.replace(new RegExp(`{{\\$${variable}}}`, 'g'), replacements[variable]);
        }
        return result;
    }
    const handleCommunicationChannelChange = (value: any) => {
        setCommunicationChannel(value);
        let newModalId = ``;
        if (value == 'Only_Email' || value == 'Both_Email_WA') {
            // newModalId = `select-user-popup-review`;
            setModalId('showEmailPreviewModal');
        }
        if (value == 'Only_WhatsApp') {
            // newModalId = `select-user-popup-whatsapp-review`;
            setModalId('showWhatsAppPreviwModal');
        }
        // setModalId(newModalId);
        setErrors({ ...errors, communicationChannel: '' });
    };

    const getadminUsers = async (school_id: any) => {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-adminuser-by-schoolid`, {
                schoolId: school_id,
            });
            const data = await response.data;
            console.log(data);
            setAdminUsers(data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const handleCommentChange = (value: any) => {
        setComment(value);
        setErrors({ ...errors, comment: '' });
    };
    const getSentHistory = async (alumni: any) => {
        console.log(alumni);
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-sent-profile-data`, {
                uuid: params.id,
                instituteId: params?.instituteId
            });
            const data = await response.data;
            if (data.success) {
                setViewSentHistory(data.data);

                var closeButton = document.getElementById('view_sent_qa_pop')?.getElementsByClassName('btn-close')[0];
                if (closeButton instanceof HTMLButtonElement) {
                    closeButton?.click();
                }

                const modalElement = document.getElementById('view-sent-history');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            }
            // setAdminUsers(data.data)
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const selectedSendingReasonValue = getDomainValueForCode(sendingReason, 'PROFILE_SENDING_REASONS_CATEGORY', state);

    const handlePreviewEmailPopup = (selectedItems: any) => {
        setSelectedItems(selectedItems);

        //dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showUserModal' } });
        // var closeButton = document.getElementById('select-user-popup')?.getElementsByClassName('btn-close')[0];
        // if (closeButton instanceof HTMLButtonElement) {
        //     closeButton?.click();
        // }
        console.log(modalId);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: modalId } });
        // const modalElement = document.getElementById(modalId);
        // if (modalElement) {
        //     const modal = new Modal(modalElement);
        //     modal.show();
        // }
    };
    
    const handleRemoveEmail = (index: any) => {
        const updatedSelectedItems = [...selectedItems];
        updatedSelectedItems.splice(index, 1);
        setSelectedItems(updatedSelectedItems); // Update the state with the modified array
    };

    const SubmitEmailPreview = () => {
        if (EmailSubject.trim() === '' || EmailBody.trim() === '') {
            alert('Both Email Subject and Email Body are required.');
            return false;
        }
        const validEmails = Array.from(document.querySelectorAll('.selectedEmailIds li')).map((li) => li.getAttribute('data-withoutmask'));
        console.log(validEmails);

        const uniqueEmails = [...new Set(validEmails)];
        if (uniqueEmails.length !== validEmails.length) {
            alert('Duplicate emails are not allowed');
            return false;
        }

        if (communicationChannel == 'Both_Email_WA') {
            dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: "showWhatsAppPreviwModal" } });
        } else {
            if (validEmails.length > 0) {
                insertSendProfileData(validEmails, null);
            } else {
                alert('Select or Enter atleast one user');
            }
        }
    };

    const insertSendProfileData = async (validEmails: any, validMobiles: any) => {
        //const conversationLink=process.env.REACT_APP_APP_BASE_URL+'/conversionslist'
        try {
            const ConversationLink = process.env.REACT_APP_APP_BASE_URL + '/valuable-conversation/';
            const response: any = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/email/send-alumni-profile`, {
                Emails: validEmails,
                Mobiles: validMobiles,
                sentProfileId: alumniData?.AlumniUserId,
                comment: comment,
                communicationChannel: communicationChannel,
                EmailSubject: EmailSubject,
                EmailBody: EmailBody,
                sendingReason: getDomainValueForCode(sendingReason, 'PROFILE_SENDING_REASONS_CATEGORY', state),
                added_by: sessionState?.id,
                WtappBody: WtappBody,
                WtappHeader: WtappHeader,
                MessageIds: selectedMessageIds,
                ConversationLink: ConversationLink,
                instituteId:params.instituteId
            });
            const data = await response.data;
            console.log(data);
            if (data.success) {
                alert(data.message);
                let newModalId = ``;
                // if (communicationChannel == 'Only_Email') {
                //     newModalId = `select-user-popup-review`;
                // }
                // if (communicationChannel == 'Only_WhatsApp' || communicationChannel == 'Both_Email_WA') {
                //     newModalId = `select-user-popup-whatsapp-review`;
                // }
                // const modalElement = document.getElementById(newModalId);
                // var closeButton = modalElement?.getElementsByClassName('btn-close')[0];
                // if (closeButton instanceof HTMLButtonElement) {
                //     closeButton?.click();
                // }
                if (communicationChannel == 'Only_Email') {
                    // newModalId = `select-user-popup-review`;
                    dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: "showEmailPreviewModal" } });

                }
                if (communicationChannel == 'Only_WhatsApp' || communicationChannel == 'Both_Email_WA') {
                    // newModalId = `select-user-popup-whatsapp-review`;
                    dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: "showWhatsAppPreviwModal" } });
                }
              
                fetchAlumniData();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };
    const SubmitWtappPreview = () => {
        const validMobiles = Array.from(document.querySelectorAll('.selectedWtappIds li')).map((li) => li.getAttribute('data-withoutmask'));
        const validEmails = Array.from(document.querySelectorAll('.selectedEmailIds li')).map((li) => li.getAttribute('data-withoutmask'));

        const uniqueMobiles = [
            ...new Set(
                validMobiles.map((mobile) => {
                    if (mobile) {
                        const processedMobile = mobile.replace(/\s+/g, '').replace(/^(?:\+91-?|91-?)?/, '');
                        return processedMobile.match(/^[0-9]{10}$/) ? processedMobile : null;
                    }
                    return null;
                })
            ),
        ].filter((mobile) => mobile !== '');

        if (uniqueMobiles.length !== validMobiles.length) {
            alert('Duplicate Mobile number are not allowed');
            return false;
        }

        if (validMobiles.length > 0) {
            insertSendProfileData(validEmails, validMobiles);
        } else {
            alert('Select or Enter atleast one user');
        }
    };

    const handleMessgaeChange = (e: React.ChangeEvent<HTMLInputElement>, messageId: any) => {
        const updatedselectedMessageIds = [...selectedMessageIds];
        const index = updatedselectedMessageIds.findIndex((selectedMessageIds) => selectedMessageIds === messageId);

        if (e.target.checked) {
            if (index === -1) {
                updatedselectedMessageIds.push(messageId);
            }
        } else {
            if (index !== -1) {
                updatedselectedMessageIds.splice(index, 1);
            }
        }
        setSelectedMessageIds(updatedselectedMessageIds);
    };

    const getValuableQaModal = async (logid: any) => {
        try {
            console.log('alumniId', logid);
            const response = await axiosClient().get(
                `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-conversation-by-logid/${logid}`
            );
            if (response.status === 200) {
                setValuableQAData(response.data.conversations);
                var closeButton = document.getElementById('view-sent-history')?.getElementsByClassName('btn-close')[0];
                if (closeButton instanceof HTMLButtonElement) {
                    closeButton?.click();
                }
                const modalElement = document.getElementById('view_sent_qa_pop');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };
    const handleToggleSearchCriteria = () => {
        setShowSearchCriteria(!showSearchCriteria);
    };

    return (
        <>
            <div className="main flex-1">
                <section className="updateadditionalinformation">
                    <div className="container max-1140 px-lg-0 overflow-hidden">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="white-block white-block-notopborderradius p-0 h-100">
                                    <div className="p-4 p-md-5">
                                        <div className="alumni_statistics">
                                            <h2 className="font-26 font-600 mb-0 pb-4">Alumni Conversations with Students</h2>

                                            <div className="ActiveListings_present">
                                                <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                                                    <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                                                        <button
                                                            className="btn-grpfiter me-3"
                                                            type="button"
                                                            onClick={handleToggleSearchCriteria}>
                                                            <i className="icon-filter font-13 me-2"></i>
                                                            Filters
                                                        </button>
                                                    </div>
                                                </div>
                                                {showSearchCriteria && (
                                                    <div className="fitergroup mb-3">
                                                        <div
                                                            id="collapseOne"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="headingOne">
                                                            <div className="accordion-body p-4">
                                                                <form className="row g-3">
                                                                    <div className="col-md-3 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="alumni_name"
                                                                                placeholder=""
                                                                                value={alumniData?.AlumniName}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Mobile</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control flex-1"
                                                                                id="alumni_mobile"
                                                                                value={alumniData?.AlumniMobile}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">User ID</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control flex-1"
                                                                                id="alumni_user_id"
                                                                                value={alumniData?.AlumniUserId}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Email</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control flex-1"
                                                                                id="alumni_email"
                                                                                value={alumniData?.AlumniEmail}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Last Sent</label>
                                                                            <div className="form-last-sent">
                                                                                {alumniData?.LastSentDate ? (
                                                                                    <>
                                                                                        <a
                                                                                            href="javascript:;"
                                                                                            onClick={() => getSentHistory(alumni)}>
                                                                                            {alumniData?.ProfileSendUsers.length} users
                                                                                        </a>{' '}
                                                                                        on{' '}
                                                                                        {moment(alumniData?.LastSentDate).format(
                                                                                            'DD-MMM-YYYY'
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <span>NA</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6 d-flex align-items-end">
                                                                        <select
                                                                            className="form-select"
                                                                            onChange={(e) => setSelectedMessageType(e.target.value)}
                                                                            name="message_type"
                                                                            aria-label="Default select example">
                                                                            <option value="">Status</option>
                                                                            <option value="received">Received message from student</option>
                                                                            <option value="replied">Replied to student</option>
                                                                        </select>
                                                                    </div>

                                                                    <div className="col-md-6 col-sm-6 d-flex align-items-end">
                                                                        <div className="datewrap">
                                                                            <label id="config-demo" className="daterangePickerList"></label>
                                                                            <div className="from">
                                                                                <input
                                                                                    onChange={(e) => setStartDate(e.target.value)}
                                                                                    value={startDate}
                                                                                    type="date"
                                                                                    data-name="from"
                                                                                    placeholder="Filter messages from"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            <div className="arrow">
                                                                                <span className="icon-right"></span>
                                                                            </div>
                                                                            <div className="to">
                                                                                <input
                                                                                    onChange={(e) => setEndDate(e.target.value)}
                                                                                    type="date"
                                                                                    data-name="to"
                                                                                    placeholder="Filter messages to"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div>Found {conversations.length} rows</div>
                                                <div className="table-responsive attendee_dtl">
                                                    <table className="table" style={{ minWidth: '958px' }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '250' }}>
                                                                    <div className="d-flex flex-wrap">Name &amp; Program</div>
                                                                </th>
                                                                <th style={{ width: '180' }}>Campus</th>
                                                                <th style={{ width: '400' }}>
                                                                    <a className="labelItem" href="javascript:;">
                                                                        Conversation
                                                                    </a>
                                                                </th>
                                                                <th style={{ width: '150px' }}>
                                                                    <a className="labelItem" href="javascript:;">
                                                                        Date
                                                                    </a>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="StudentData">
                                                            {conversations?.map((conversation: any, index: number) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="d-flex align-items-center checkoption">
                                                                            <a className="tablink" href="javascript:;">
                                                                                <span
                                                                                    data-bs-toggle="tooltip"
                                                                                    title=""
                                                                                    data-bs-original-title={conversation.fullName}
                                                                                    className="singlelinename font-16 lineHeight-16">
                                                                                    {formatFullName(conversation.fullName,'')}
                                                                                    <span className="d-block font-13 fst-italic">
                                                                                         {conversation.programName} - {conversation.passoutYear}
                                                                                    </span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                                                            {conversation.schoolName}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex flex-wrap align-items-center">
                                                                            {' '}
                                                                            {conversation.messageStatus}
                                                                        </div>
                                                                        <a
                                                                            href="javascript:void(0)"
                                                                            className="link"
                                                                            onClick={(e) =>
                                                                                displayConversationWithstudent(
                                                                                    conversation.conversationId,
                                                                                    conversation.fullName,
                                                                                    conversation.programName,
                                                                                    conversation.profilePictureFilePath,
                                                                                    conversation.studentId,
                                                                                    conversation.schoolName,
                                                                                    conversation.passoutYear
                                                                                )
                                                                            }>
                                                                            View entire conversation
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        {moment(conversation.lastMessageCreatedAt).format('DD-MMM-YYYY')}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <SendEmailModal
                data={{
                    alumniData,
                    options,
                    handleNextPreviewAdminSelection,
                    errors,
                    sendingReason,
                    handleSendingReasonChange,
                    comment,
                    handleCommentChange,
                    communicationChannel,
                    handleCommunicationChannelChange,
                    alumni,
                    getSentHistory,
                    valuablePage,
                }}
            />
            <SelectUserModal
                data={{ alumniData, selectedSendingReasonValue, adminUsers, handlePreviewEmailPopup, alumni, getSentHistory }}
            />
            <EmailPreviewModal
                data={{
                    selectedSendingReasonValue,
                    EmailSubject,
                    EmailBody,
                    SubmitEmailPreview,
                    communicationChannel,
                    selectedItems,
                    handleRemoveEmail,
                    setEmailSubject,
                    setEmailBody,
                }}
            />
            <WtappPreviewModal
                data={{ selectedSendingReasonValue, communicationChannel, WtappBody, SubmitWtappPreview, selectedItems, handleRemoveEmail }}
            />
            <ViewSentHistoryModal data={{ alumniData, viewSentHistory, getValuableQaModal }} />
            <ValuableQAModal data={{ alumniData, valuableQAData, getSentHistory, alumni }} />
            {/* modal */}
            <div
                className="modal fade show"
                id="conversationList"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-modal="true"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                    <div className="modal-content half-gray">
                        <div className="modal-header">
                            <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-sm-6 mb-2 mb-sm-0">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <i className="me-2">
                                                <img src={studentImage} className="rounded-circle img-fluid" width="47" />
                                            </i>
                                            <span className="font-16 lineHeight-16 flex-1 d-flex flex-column align-items-start align-items-sm-start">
                                                <span className="font-16 font-500 text-dark-blue">
                                                    {formatFullName(studentName,'')}, {' '}<br/>
                                                    <span className={`fst-italic font-15`}>
                                                        {studentprogramName}  - {StudentpassoutYear}
                                                    </span>
                                                </span>
                                                {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                    {studentSchoolName}{' '}
                                                </span> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="d-flex flex-wrap align-items-center flex-row-reverse flex-lg-row pe-md-4">
                                            <i className="ms-2 ms-lg-0 me-0 me-lg-2">
                                                <img
                                                    src={alumniData?.profilePictureFilePath}
                                                    className="rounded-circle img-fluid"
                                                    width="47"
                                                />
                                            </i>
                                            <span className="lineHeight-16 flex-1 d-flex flex-column align-items-end align-items-lg-start">
                                                <span className="font-16 font-500 text-dark-blue">
                                                    {alumniData?.AlumniName},{' '}<br/>
                                                    <span className="fst-italic font-15">{alumniData?.AlumniprogramName}-{alumniData?.AlumniPassoutYear}</span>
                                                </span>
                                                {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                    <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                        {alumniData?.AlumnischoolName}
                                                    </span>
                                                </span> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body px-md-4 pb-4">
                            <div className="conversation gap">
                                {conversationData.map((message: any, index: number) => (
                                    <div
                                        key={index}
                                        className={
                                            message?.senderId == params.id ? 'row justify-content-end' : 'row justify-content-start'
                                        }>
                                        <div className="col-sm-6">
                                            <div className="d-flex flex-wrap">
                                                <div
                                                    className={message?.senderId == params.id ? 'messages leftUser' : 'messages rightUser'}>
                                                    <p>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`Replied_${message.messageId}`}
                                                            value={message.messageId}
                                                            onChange={(e) => handleMessgaeChange(e, message.messageId)}
                                                            checked={selectedMessageIds.some(
                                                                (selectedItem) => selectedItem === message.messageId
                                                            )}
                                                        />
                                                        {message.messageContent}
                                                    </p>
                                                    <span className="date">{moment(message.createdAt).format('DD-MMM-YYYY h:mm A')}</span>
                                                </div>
                                                {/* <div className="userimg ms-2">
                                                    <img src="images/ravi_verma_icon.png" className="rounded-circle img-fluid" width="47" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-good-qa btn-send-email-interaction" onClick={() => displayfirstpopup()}>
                                Send as Good Q&A
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AlumniInteractionGrid;
