import { useContext, useEffect, useRef } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, getParentData, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const SelectControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey, handleChangeEvent } = { ...args };
    const [domainCategoryCode, alterativeDomainCategoryCode] = control.props.domainCategoryCode.split('#');

    // const readOnly = evaluateExpression(
    //     control.readOnlyExpression,
    //     state?.data,
    //     getControlValueFromState(parentDataKey as string, state as State)
    // );
    const readOnly =
        control.readOnly ||
        evaluateExpression(control.readOnlyExpression, state?.data, getControlValueFromState(parentDataKey as string, state as State));

    // logger.log(`SELECT CONTROL - Constructing the control ${control.id} with dataKey ${dataKey}`);

    let data = getControlValueFromState(dataKey, state as State);

    //data = data === null || data === undefined ? control.props.defaultValue : data;
    // if (Array.isArray(data)) data = undefined;

    useEffect(() => {
        if (data === undefined && control.props.defaultValue !== undefined) {
            data = control.props.defaultValue;
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey, name: control.id, value: data, errorMessages: [] },
            });
        }
    });

    const customSelectText = control.props?.customProperties?.customSelectText ?? 'Select';

    // const parentData = control.props.parentId && getControlValueFromState(parentDataKey + '.' + control.props.parentId, state as State);
    const parentData = control.props.parentId && getParentData(parentDataKey + '.' + control.props.parentId, state as State);

    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl

    // logger.log(`Select - loading the domain ${control.props.domainCategoryCode}`);

    let controlDomain =
        (state?.domain?.get(domainCategoryCode) as DomainElement[])?.filter((domain: DomainElement) => {
            if (
                parentData == null ||
                control.props.parentId === null ||
                control.props.parentId === undefined ||
                control.props.parentId.length === 0
            )
                return true;
            else return domain.parentCode == parentData;
        }) ?? [];

    if (alterativeDomainCategoryCode != null && controlDomain.length === 0)
        controlDomain = state?.domain?.get(alterativeDomainCategoryCode) as DomainElement[];

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    const getSelectOptions = () => {
        const getAllOptions = () => {
            return controlDomain.map((domain, index) => (
                // <option key={`${index}.${domain.code}.${domain.parentCode}`} value={domain.code} defaultValue={data}>
                <option key={`${index}.${domain.value}.${domain.parentCode}`} value={domain.code} defaultValue={data}>
                    {domain.value}
                </option>
            ));
        };

        if (isEmpty(control.props.topOptionsGroupLabelInSelect)) {
            return getAllOptions();
        }

        return (
            <>
                <optgroup key={`${control.id}-${control.props.topOptionsGroupLabelInSelect}`} label={control.props.topOptionsGroupLabelInSelect}>
                    {controlDomain
                        .filter((item: DomainElement) => item.displayOrder > 0)
                        .sort((curr: DomainElement, next: DomainElement) => curr.displayOrder - next.displayOrder)
                        .map((domain, idx) => (
                            // <option key={domain.code} value={domain.code} defaultValue={data}>
                            <option key={`${control.id}-${domain.value}-${idx}-top`} value={domain.code} defaultValue={data}>
                                {domain.value}
                            </option>
                        ))}
                </optgroup>
                <optgroup key={`${control.id}----------------------`} label="----------------------">{getAllOptions()}</optgroup>
            </>
        );
    };

    return (
        <>
            {control.props.label && (
                <label htmlFor={control.id} className="form-label w-100">
                    {`${control.props.label} `}
                </label>
            )}
            <select
                id={control.id}
                name={dataKey}
                className={`form-select`} 
                value={data ?? control.props.defaultValue}
                required={control.props.required}
                disabled={readOnly ?? false}
                onChange={(event) =>
                    handleControlValueChange({ control, value: event.target.value, dataKey, parentDataKey, state, dispatch })
                }
                ref={formControlRef}>
                {!controlDomain?.some((domain) => domain.code === '') && <option value={''}>{customSelectText}</option>}
                {getSelectOptions()}
            </select>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectControl;
