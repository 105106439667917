import imgcloudcomputing from '../../../styles/images/cloud-computing.svg';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import AlumniGuidingSearch from '../../alumni-search/AlumniGuidingSearch';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManageAlumni from '../../broadcast/student/useManageAlumni';
// import ManageLeadService from './ManageLeadService';
// import SearchCriteria from './SearchCriteria';
// import { useNavigate } from 'react-router';

interface Alumni {
    id: string;
    name: string;
    email: string;
}
const AddSpeakProfile = () => {
    
    const { id, code } = useParams();
    const [fuzzySearchText, setFuzzySearchText] = useState('');
    const [alumniList, setAlumniList] = useState<Alumni[]>([]);
    const [filteredAlumniList, setFilteredAlumniList] = useState<Alumni[]>([]);
    useEffect(() => {
        // Mock data, replace with actual data fetching logic
        const fetchAlumniList = async () => {
            const data = [
                { id: '1', name: 'John Doe', email: 'john@example.com' },
                { id: '2', name: 'Jane Smith', email: 'jane@example.com' },
                // Add more alumni here
            ];
            setAlumniList(data);
            setFilteredAlumniList(data);
        };

        fetchAlumniList();
    }, []);

    useEffect(() => {
        const filteredList = alumniList.filter(alumni => 
            alumni.name.toLowerCase().includes(fuzzySearchText.toLowerCase()) ||
            alumni.email.toLowerCase().includes(fuzzySearchText.toLowerCase())
        );
        setFilteredAlumniList(filteredList);
    }, [fuzzySearchText, alumniList]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFuzzySearchText(e.target.value);
    };

    
    return (
        <>
            <div className='bg-gray-F2F4F7 border-radius-6 p-4'>
                            
            <div className="row g-4">
                    <div className="col-md-5">
                    <div className="w-100 h-100 d-flex justify-content-center align-items-start">                  
                    <div className="testimonial_block">
                      <div className="testimonial_img_brife">
                        <div className="testimonial_img_wrap">
                          <div className="testimonial_img bg-white">
                            <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/dummy_male.svg" width="80" alt=""/>
                          </div>
                         
                        </div>
                        <div className="testimonial_brife">
                          <h3><span>Biraaj Dodiya</span></h3>
                          <p>The Class of 2011 | Visual Artist Solo exhibition with Experimenter Gallery (2020) Featured in Forbes 30 under 30 in 2021 - ART Category </p>
                        </div>
                      </div>
                      <div className="testimonial_detail">
                        <p>Today as I look back on my eight years at DAIS that taught me many academic, social, and cultural lessons, what I value the most is how we were taught to think independently, critically, and creatively. We have learnt how a historical event, a work of literature, or a scientific problem could be approached in more than just one way. The academic rigour and introduction to contemporary methods of research during my DAIS years helped me immensely when later I went to the US for Art School. It also instilled in me a deep love for sports and libraries! I am truly grateful to all the inspiring teachers and coaches who have motivated all of us to do our best and excel. </p>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-md-7">                  
                    <div className="bg-white border-radius-6 p-4 h-100">
                        <form className="p-2">
                          <div className="row g-3">
          
                            <div className="col-md-12">
                              <div className="row g-2 align-items-center mb-2">
                                <div className="col"><label className="form-label m-0 mb-1 font-16 font-600 ">ADD ALUMNI TESTIMONIAL</label></div>
                              
                              </div>

                             
                            </div>
                            
                            <div className="col-12"><input id="alumni-name" type="text" className="form-control" placeholder="Enter Alumni Name"/> </div>

                            <div className="col-12"><input id="alumni-program-batch-year" type="text" className="form-control " placeholder="Enter Alumni Program and Batch Year"/> </div>

                            <div className="col-12"><input id="alumni-headline" type="text" className="form-control " placeholder="Enter Alumni Headline"/> </div>

                            <div className="col-12"><textarea id="testimonial-text" className="form-control h-auto" placeholder="Enter the Testimonial text" rows={3}></textarea></div>

                            <div className="col-md-12">
                              <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="cloud-computing">Upload Display Image</label>                   
                              <div className="upload_section">
                                <input type="file" id="cloud-computing" className=""/>
                                  <div className="row g-2 align-items-center justify-content-center">
                                    <div className="col-sm d-flex align-items-center justify-content-center">
                                      <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                        <div className="cloud-img"><img src="../images/cloud-computing.svg" alt=""/></div>
                                        <div className="cloud-text">Drag and drop file here or <a href="javascript:;" className="text-sky-blue">Browse</a> </div>
                                      </div>                          
                                    </div>                         
                                  </div>
                              </div>
                              <span className="text-end font-14 d-block text-dark-blue opacity-7">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                            </div>                       
                          </div>
                        </form>
                        </div>

                    
                    </div>
                    
                </div>

                
                
                {/* <div className="row mt-4 justify-content-end">
                    <div className="col-md-7">
                        <div className="row g-3 align-items-center justify-content-around">
                            <div className="col-auto"><button className="btn-border-gray height-40 w-100 max-190"><i className="icon-left-arrow font-12 position-relative me-1"></i><span>Previous Profile</span></button></div>
                            <div className="col-auto"><button className="btn-border-gray height-40 w-100 max-190"><span>Next Profile</span> <i className="icon-right-arrow font-12 position-relative ms-1"></i></button></div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="action_btns mt-4 max-100per">
                        {/* <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" data-bs-toggle="modal" data-bs-target="#exampleModal">Preview</button>               */}
                        <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5"><span className="px-3">Save </span></button>
                    </div>
        </>
        
    );
};

export default AddSpeakProfile;