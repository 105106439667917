import imgcloudcomputing from '../../../styles/images/cloud-computing.svg';
import { useEffect, useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { OptionsOrGroups, GroupBase } from 'react-select';
import { components } from 'react-select';
import { getStudentImage } from '../../../common/styles/Images';
import { axiosClient } from '../../../library/Service/axiosClient';
import { isEmpty } from '../../../library/Core/SmartFunctions';

interface CustomOption {
    value: string;
    label: string;
    name: string;
    firstName: string;
    lastName: string;
    profilePictureFilePath?: string;
    schoolName?: string;
    passOutYear?: number;
    programName?: string;
    nation: string;
    selectugCollege: number;
    ugCollege: string;
    ugDepartment: string;
    selectpgCollege: number;
    pgCollege: string;
    pgDepartment: string;
    selectjobCompany: number;
    jobCompany: string;
    jobDesignation: string; 
    registeredEmailId: string;
}

const AddAlumniProfile = () => {
    const { id, code, eid } = useParams();
    const [selectedUser, setSelectedUser] = useState<CustomOption | null>(null);
    const [selectedDataSet, setSelectedDataSet] = useState<string>(''); // State to track the selected dataset
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState<string | null>(null); // State to hold the uploaded image URL
    const [editData, setEditData] = useState<any>({}); // State to hold the edit data if in edit mode
    const [errors, setErrors] = useState({
        user: '',
        dataSet: '',
        image: ''
    });
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDataSet(event.target.value);
    };
    let editdata: any = {};
    const isEditing = !!eid;
    useEffect(() => {
        if (isEditing && eid) {  // Ensure eid exists and editing mode is active
            const fetchEditData = async () => {
                try {
                    setIsLoading(true);
                    const widgetResp = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetalumni/${eid}`);
                    const users = widgetResp?.data?.widgetprofileData || [];
    
                    if (users.length > 0) {
                        const user = users[0];  // Assuming only one user is fetched, otherwise loop through
    
                        console.log(user, 'editData')
                        // Set the data into editData state
                        const formattedUser: CustomOption = {
                            value: user.user_id, // Ensure you are correctly using the UUID here
                            label: `${user.user_name}`,
                            name: `${user.user_name}`,
                            firstName: user.user_name.split(' ')[0], // Assuming the first part is the first name
                            lastName: user.user_name.split(' ').slice(1).join(' ') || '', // Assuming the rest is the last name
                            profilePictureFilePath: user.profile_pic || '', // Handle undefined profilePictureFilePath
                            schoolName: user.school_name || '', // Handle undefined schoolName
                            passOutYear: user.user_year || null, // Handle undefined passOutYear
                            programName: user.user_program || '', // Handle undefined program name
                            nation: user.nationality || '', // Handle undefined nation
                            ugCollege: user.joined_ug_college || '', // Handle undefined ugCollege
                            ugDepartment: user.joined_ug_course || '', // Handle undefined UG department
                            pgCollege: user.joined_pg_college || '', // Handle undefined PG college
                            pgDepartment: user.joined_pg_course || '', // Handle undefined PG department
                            jobCompany: user.current_company || '', // Handle undefined jobCompany
                            jobDesignation: user.current_designation || '', // Handle undefined jobDesignation
                            registeredEmailId: '', // Handle undefined registeredEmailId
                            selectugCollege: user.select_ug_college || '', // Add default or appropriate value
                            selectpgCollege: user.select_pg_college || '', // Add default or appropriate value
                            selectjobCompany: user.select_pg_company || '', // Add default or appropriate value
                        };
    
                        setEditData(formattedUser);
                        setSelectedUser(formattedUser); // Optionally set this if you want to pre-select the user
                        setUploadedImage(user.profile_pic || null);
                        
                        if (user.select_ug_college === 1) {
                            setSelectedDataSet('JoinedUGCollege');
                        } else if (user.select_pg_college === 1) {
                            setSelectedDataSet('JoinedPGCollege');
                        } else if (user.select_pg_company === 1) {
                            setSelectedDataSet('CurrentCompany');
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch widget data", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchEditData();
        }
    }, [eid, isEditing]);

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;
        const formData = new FormData();
    
        // Make sure there's at least one file
        if (event.target.files && event.target.files.length > 0) {
            formData.append('image', event.target.files[0]); // Append the first file
    
            try {
                const response = await axiosClient().post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                // Assuming the response returns the new image URL
                const newImageUrl = response.data.link;
    
                // Update the uploaded image state and the selected user's profile picture
                setUploadedImage(newImageUrl);
                setSelectedUser((prevUser) => prevUser ? { ...prevUser, profilePictureFilePath: newImageUrl } : prevUser);
    
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        } else {
            console.log('No file selected');
        }
    };
    

    

// Ensure the function returns a promise with the correct type
const loadOptions = async (
    inputValue: string,
    callback: (options: OptionsOrGroups<CustomOption, GroupBase<CustomOption>>) => void
): Promise<OptionsOrGroups<CustomOption, GroupBase<CustomOption>>> => {
    setIsLoading(true);
    try {
        if (isEmpty(inputValue) || inputValue.length < 3) {
            setIsLoading(false);
            callback([]);
            return [];
        }
        const res = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/alumniInfo/get-alumni-guiding?term=${inputValue}`);
        const users = res.data[0] || [];

        console.log(users, 'users')

        const formattedOptions: OptionsOrGroups<CustomOption, GroupBase<CustomOption>> = users.map((user: any) => ({
            value: user.uuid, // Ensure you are correctly using the UUID here
            label: `${user.firstName} ${user.lastName}`,
            name: `${user.firstName} ${user.lastName}`,
            firstName: user.firstName,
            lastName: user.lastName,
            profilePictureFilePath: user.profilePictureFilePath || '', // Handle undefined profilePictureFilePath
            schoolName: user.schoolName || '', // Handle undefined schoolName
            passOutYear: user.passOutYear || null, // Handle undefined passOutYear
            programName: user.programName || '', // Handle undefined program name
            nation: user.nation || '', // Handle undefined nation
            ugCollege: user.ugCollege || '', // Handle undefined ugCollege
            ugDepartment: user.ugDepartment || '', // Handle undefined UG department
            pgCollege: user.pgCollege || '', // Handle undefined PG college
            pgDepartment: user.pgDepartment || '', // Handle undefined PG department
            jobCompany: user.jobCompany || '', // Handle undefined jobCompany
            jobDesignation: user.jobDesignation || '', // Handle undefined jobDesignation
            registeredEmailId: user.registeredEmailId || '', // Handle undefined registeredEmailId
            selectugCollege: user.selectugCollege || '0', // Assuming you get this from the user object
            selectpgCollege: user.selectpgCollege || '0', // Assuming you get this from the user object
            selectjobCompany: user.selectjobCompany || '0', // Assuming you get this from the user object
        }));
        
        
        setIsLoading(false);
        callback(formattedOptions);
        return formattedOptions;
    } catch (error) {
        console.error('Error loading user names:', error);
        setIsLoading(false);
        const emptyOptions: OptionsOrGroups<CustomOption, GroupBase<CustomOption>> = [];
        callback(emptyOptions);
        return emptyOptions;
    }
};

    const handleOnChange = (user: CustomOption | null) => {
        if (!user) {
            setSelectedUser(null);
            setUploadedImage(null);
            return;
        }
        
        setSelectedUser(user);
        setUploadedImage(user.profilePictureFilePath || null);
        
        // Perform any further actions with the selected user
    };

    const handleSave = async () => {
        // if (!selectedUser) {
        //     console.error('No user selected');
        //     return;
        // }

        // Example validation checks
        let validationErrors: any = {};
        // Example validation checks
        if (!selectedUser) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                user: 'Please select a user.'
            }));
            return;
        }

        if (!selectedDataSet) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                dataSet: 'Please select a data set to display.'
            }));
            return;
        }

        if (!uploadedImage) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: 'Please upload a display image.'
            }));
            return;
        }

        const dataToSave = {
            widgetCode: code,
            widgetInstituteListId: id,
            userId: selectedUser.value, // Use `value` as it contains the UUID
            userName: `${selectedUser.firstName} ${selectedUser.lastName}`,
            email: selectedUser.registeredEmailId,
            passoutYear: selectedUser.passOutYear,
            programName: selectedUser.programName,
            schoolName: selectedUser.schoolName,
            profilePicture: uploadedImage || selectedUser.profilePictureFilePath,
            nation: selectedUser.nation,
            achievement: '',
            ugCollege: selectedUser.ugCollege,
            ugDepartment: selectedUser.ugDepartment,
            pgCollege: selectedUser.pgCollege,
            pgDepartment: selectedUser.pgDepartment,
            jobCompany: selectedUser.jobCompany,
            jobDesignation: selectedUser.jobDesignation,
            selectedDataSet: selectedDataSet,
            selectedLocation: '',
            selectedAchievement: '',
            eid: eid || '',
        };


        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/action/savewidgetlist`, dataToSave);
            if (response.status === 200) {
                console.log('Data saved successfully');

                // After successful save/update
                alert('Data has been saved/updated successfully.');

                // Redirect to the desired URL
                window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/widget/configurelist/guiding_alumni_mentors/${id}`;
            } else {
                console.error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const CustomOption = (props: any) => {
        const { data } = props;
        
        console.log('Data:', data.profilePictureFilePath)
        return (
            <components.Option {...props}>
                <p
                    key={data.value}
                    className="d-flex flex-wrap align-items-center font-16 line-height-18 font-500 text-dark-blue mb-0 pb-3 pb-sm-2">
                    <span className="me-3">
                        <img
                            src={getStudentImage({ profilePictureFilePath: data.profilePictureFilePath, genderCode: '' })}
                            alt=""
                            className="rounded-circle"
                            style={{ height: '50px', borderRadius: '50%', width: '50px' }}
                        />
                    </span>
                    <span className="flex-1">
                        {`${data.firstName} ${data.lastName}`},{' '}
                        <span className="fst-italic">
                            {data.schoolName}
                            {data.passOutYear ? `, ${data.passOutYear}` : ''}
                        </span>
                    </span>
                </p>
            </components.Option>
        );
    };

    return (
        <>
            <div className='bg-gray-F2F4F7 border-radius-6 p-4'>
                <div className="row g-4">
                    <div className="col-md-5">
                        <div className="w-100 h-100 d-flex justify-content-center align-items-start">                  
                            <div className="guiding_alumni_block w-100 max-100per">
                                <div className="guiding_alumni_top">
                                    <div className="guiding_alumni_profile_img_wrap">
                                        <div className="guiding_alumni_profile_img">
                                            <img src={uploadedImage || selectedUser?.profilePictureFilePath || 'https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/dummy_male.svg'} width="80" alt=""/>
                                        </div>
                                        {/* <label htmlFor="cloud-computing"><i className="pencil-edit"></i></label> */}
                                    </div>
                                    <div className="guiding_alumni_profile_name">{selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : 'Alumni Name'}</div>
                                </div>
                                <div className="guiding_alumni_college_cource">
                                    <ul>
                                        <li><i className="college"></i> <span>{selectedUser?.schoolName || 'School info'} {selectedUser?.programName ? `, ${selectedUser.programName}` : ''} {selectedUser?.passOutYear ? `, ${selectedUser.passOutYear}` : ''}</span></li>
                                        {/* <li><i className="cap"></i> <span>{selectedUser?.ugCollege || 'UG info'}</span></li> */}
                                        <li>
                                            <i className="cap"></i> 
                                            <span>
                                                {selectedDataSet === 'JoinedUGCollege' || selectedUser?.selectugCollege === 1
                                                    ? `${selectedUser?.ugCollege || 'N/A'}, ${selectedUser?.ugDepartment || 'N/A'}` 
                                                    : selectedDataSet === 'JoinedPGCollege' || selectedUser?.selectpgCollege === 1
                                                    ? `${selectedUser?.pgCollege || 'N/A'}, ${selectedUser?.pgDepartment || 'N/A'}` 
                                                    : selectedDataSet === 'CurrentCompany' || selectedUser?.selectjobCompany === 1
                                                    ? `${selectedUser?.jobCompany || 'N/A'}, ${selectedUser?.jobDesignation || 'N/A'}` 
                                                    : 'Select a data set'}
                                            </span>
                                        </li>                                       
                                        <li><i className="brifecase"></i> <span>{selectedUser?.nation || 'Nation'}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">                  
                        <div className="row g-4">
                            <div className="col-12">
                                <div className="bg-white border-radius-6 p-4">
                                    <form className="p-2">
                                        <div className="row g-4">
                                            <div className="main flex-1 bg-white">
                                                <div className="row g-2 align-items-center mb-2">
                                                    <div className="col">
                                                        <label className="form-label m-0 mb-1 font-16 font-600">ADD ALUMNI PROFILE</label>
                                                    </div>
                                                </div>
                                                <div className='containerinnercover singleOption'>
                                                <AsyncSelect
                                                    loadOptions={loadOptions}
                                                    onChange={handleOnChange}
                                                    isSearchable={true}
                                                    isClearable
                                                    components={{
                                                        DropdownIndicator: null,
                                                        IndicatorSeparator: null,
                                                        Option: CustomOption,
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    value={selectedUser}
                                                    placeholder="Search by name"  // Add this line for the placeholder
                                                />
                                                {errors.user && <div style={{ color: 'red', marginTop: '5px' }}>{errors.user}</div>}
                                                </div>                                                
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label m-0 mb-2 font-16 font-500 w-100" htmlFor="DataSet">Choose Data Set to Display</label>
                                                <div className="row g-3">
                                                {selectedUser?.ugCollege !== '' && (
                                                    <div className="col-auto col-md-auto col-lg-6">
                                                        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center pe-3">
                                                            <input
                                                                type="radio"
                                                                name="DataSet"
                                                                id="JoinedUGCollege"
                                                                value="JoinedUGCollege"
                                                                onChange={handleRadioChange}
                                                                className="form-check-input custom-border-84-20 m-0"
                                                                checked={selectedDataSet === 'JoinedUGCollege'} // Automatically selects if selectugCollege is 1
                                                            />
                                                            <label htmlFor="JoinedUGCollege" className="form-check-label font-16 font-500 ms-2">Joined UG College</label>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedUser?.pgCollege !== '' && (
                                                    <div className="col-auto col-md-auto col-lg-6">
                                                        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                            <input
                                                                type="radio"
                                                                name="DataSet"
                                                                id="JoinedPGCollege"
                                                                value="JoinedPGCollege"
                                                                onChange={handleRadioChange}
                                                                className="form-check-input custom-border-84-20 m-0"
                                                                checked={selectedDataSet === 'JoinedPGCollege'}  // Automatically selects if selectpgCollege is 1
                                                            />
                                                            <label htmlFor="JoinedPGCollege" className="form-check-label font-16 font-500 ms-2">Joined PG College</label>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedUser?.jobCompany !== '' && (
                                                    <div className="col-auto col-md-auto col-lg-6">
                                                        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                            <input
                                                                type="radio"
                                                                name="DataSet"
                                                                id="CurrentCompany"
                                                                value="CurrentCompany"
                                                                onChange={handleRadioChange}
                                                                className="form-check-input custom-border-84-20 m-0"
                                                                checked={selectedDataSet === 'CurrentCompany'}  // Automatically selects if selectjobCompany is 1
                                                            />
                                                            <label htmlFor="CurrentCompany" className="form-check-label font-16 font-500 ms-2">Current Company</label>
                                                        </div>
                                                    </div>
                                                )}
                                                </div>
                                                {errors.dataSet && <div style={{ color: 'red', marginTop: '5px' }}>{errors.dataSet}</div>}
                                            </div>
                                            <div className="col-md-12"><hr className="m-0"/></div>
                                            <div className="col-md-12">
                                                <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="cloud-computing">Upload Display Image</label>                   
                                                <div className="upload_section">
                                                    <input type="file" id="cloud-computing" onChange={handleImageUpload} className=""/>
                                                    <div className="row g-2 align-items-center justify-content-center">
                                                        <div className="col-sm d-flex align-items-center justify-content-center">
                                                            <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                                <div className="cloud-img"><img src={imgcloudcomputing} alt=""/></div>
                                                                <div className="cloud-text">Drag and drop file here or <a href="#" className="text-sky-blue">Browse</a> </div>
                                                            </div>                          
                                                        </div>                         
                                                    </div>
                                                </div>
                                                <span className="text-end font-14 d-block text-dark-blue opacity-7 mt-1">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                                {errors.image && <div style={{ color: 'red', marginTop: '5px' }}>{errors.image}</div>}
                                            </div>                       
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>
            </div>
            <div className="action_btns mt-4 max-100per">
                {/* <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" data-bs-toggle="modal" data-bs-target="#exampleModal">Preview</button>               */}
                <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5" onClick={handleSave}><span className="px-3">Save</span></button>
            </div>
        </>
    );
};

export default AddAlumniProfile;
