import { useContext, useRef, useState } from "react";
import { SmartContext } from "../../library/Core/SmartContext";
import { arrayColumn, isEmpty, setError, triggerGridDataOnChange } from "../../library/Core/SmartFunctions";
import { axiosClient } from "../../library/Service/axiosClient";
import ErrorControl from "../../library/SimpleControls/ErrorControl";
import { DispatchEvent, State } from "../../library/Core/SmartTypes";
import { v4 as uuidv4 } from 'uuid';
import { Modal } from "bootstrap";
import Swal from "sweetalert2";

interface AddPopupProps {
    id: string;
}

const AddPopup: React.FC<AddPopupProps> = (args) => {
    const { id } = { ...args };
    const { state, dispatch } = useContext(SmartContext);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const saveData = async () => {
        let entityKey: any = '';
        let displayEntity: any = '';

        switch (id) {
            case 'add_company':
                entityKey = 'industryCode';
                displayEntity = 'Companies'
                break;
        }

        let link = '';
        const metricEntityId = state?.internal?.selectedEntityId;
        let companyData: any = {};
        const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    
        if ( ( ! customValidationPassed ) ) {
            dispatch({ type: 'SHOW_ERRORS' });
            console.log(state.formValidationErrors);
            return;
        }

        if ( ! isEmpty(state?.internal?.addNew?.file) ) {
            const apiUrl    = `${process.env.REACT_APP_COMMON_API}/upload/image`;
            const formData  = new FormData();
            formData.append('image', state?.internal?.addNew?.file);

            const response  = await axiosClient().post(apiUrl, formData);
            link = response.data.link;
        }
        // Reset the file input after successful upload
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        companyData = {
            'id': uuidv4(),
            'name': state?.internal?.addNew?.name?.trim(),
            'logo': link
        };

        if ( ! isEmpty (metricEntityId) && metricEntityId > 0 ) {
            companyData[entityKey] = metricEntityId
            let popupData = JSON.parse(JSON.stringify(state?.data?.popupData)) || {};

            if (!popupData[metricEntityId]) {
                popupData[metricEntityId] = [];
            }
            popupData[metricEntityId].push(companyData);
            
            dispatch({
                type: 'REFRESH_DATA',
                payload: {
                    data: {...state.data, popupData: popupData}
                },
            });
                
            setTimeout(() => {
                triggerGridDataOnChange(state.data.metricData, "metricData", "metricEntity2");
            }, 1000);
        } else {
            companyData[entityKey] = 0;
            let otherList = JSON.parse(JSON.stringify(state?.data?.widgetData?.otherList)) || {};
            otherList.push(companyData);

            let otherCount = otherList.length ?? 0;
            let otherDisplay = `${otherCount} ${displayEntity}`
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `widgetData.otherDisp`,
                    value:  otherDisplay
                },
            });

            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `widgetData.other`,
                    value:  otherCount
                },
            });

            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `widgetData.otherList`,
                    value:  otherList
                },
            });
        }

        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {} } });

        Swal.fire({
            title: "Success!",
            text: "Company added!",
            icon: "success"
        });
        
        handleClosePopup();        
    }

    const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const errorMessages = [] as any[];
        let isFormInvalid = false;
        let entityName;
        switch ( state?.internal?.widget_code ) {
            case 'top_companies':
                entityName = 'Company';
                break;
            
            case 'top_colleges':
                entityName = 'College'
                break;
        }
        if (isEmpty(state?.internal?.addNew?.name?.trim())) {
            setError(`addNew.name`, [`${entityName} name is required!`], dispatch);
            isFormInvalid = true;
        } else {
            setError(`addNew.name`, [], dispatch);
        }

        const popupData = ! isEmpty(state?.internal?.selectedEntityId) && state?.internal?.selectedEntityId > 0 
                            ? state?.data?.popupData[state.internal.selectedEntityId] 
                            : state?.data?.widgetData?.otherList;

        if (!isEmpty(popupData)) {
            const addedList = arrayColumn(popupData, 'name');
        
            if ( addedList.includes(state?.internal?.addNew?.name?.trim()) ) {
                setError(`addNew.name`, [`${entityName} name already added!`], dispatch);
                isFormInvalid = true;
            }
        }
        const file = state?.internal?.addNew?.file;
        
        if (state?.data?.widgetData?.logoFlag != 'dont_show' && isEmpty(file)) {
            setError(`addNew.file`, [`Logo is required!`], dispatch);
            isFormInvalid = true;
        } else {
            const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];

            if (file && !allowedFileTypes.includes(file.type)) {
                // setError(`addNew.file`, [`Only PNG, JPG, and JPEG files are allowed!`], dispatch);
                setError(`addNew.file`, [`Invalid file extension!`], dispatch);
                isFormInvalid = true;
            } else {
                setError(`addNew.file`, [], dispatch);
            }
        }

        // Dispatch the validation errors to the state
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'addNewCnt', errorMessages },
        });

        return !isFormInvalid;
    }

    const handleControlValueChange = (args: any) => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {...state?.internal?.addNew, name: args.value} } });
    }
    
    const handleImageUpload = async (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {...state?.internal?.addNew, file} } });
        }
    };

    const handleClosePopup = () => {
        setImagePreview(null);
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {} } });

        if (modalRef.current) {
            const modal = Modal.getInstance(modalRef.current);
            if (modal) {
                dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isModalOpen', value: false } });
                modal.hide();
            }
        }
    }

    return (
        <div 
            className="modal fade px-3" 
            id={id}
            data-bs-backdrop="static" 
            data-bs-keyboard="false" 
            tabIndex={-1} 
            aria-labelledby="staticBackdropLabel" 
            style={{ display: 'none' }}
            aria-modal="true" 
            role="dialog"
            ref={modalRef}>

            <div className="modal-dialog modal-dialog-centered max-600 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-lite-blue d-flex align-items-center justify-content-between">
                        <h5 className="modal-title d-flex align-items-center" id="staticBackdropLabel">{state?.internal?.widget_title}</h5>
                        <button type="button" onClick={handleClosePopup} className="btn-close btn-close-small m-0 position-static" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="company-name">Company Name</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    onChange={(event) =>
                                        handleControlValueChange({ value: event.target.value, key: 'name'})
                                    }
                                    value={state?.internal?.addNew?.name || ""}
                                    placeholder="Enter Company Name" />
                                    <ErrorControl errorMessages={state?.formValidationErrors['addNew.name']} />
                                </div>

                                <div className="col-md-12">
                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="upload-display-image">Upload Display Image</label>
                                    <div className="upload_section">
                                        <input 
                                            type="file" 
                                            ref={fileInputRef}
                                            id="upload-display-image" 
                                            onChange={handleImageUpload}
                                        />
                                        {!imagePreview && (
                                            <div className="row g-2 align-items-center justify-content-center">
                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                        <div className="cloud-img"><img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/upload-cloud.svg`} alt="" /></div>
                                                        <div className="cloud-text">Drag and drop file here or <a href="#" className="text-sky-blue">Browse</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {imagePreview && (
                                            <img src={imagePreview} className="img-fluid rounded" alt="" />
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <ErrorControl errorMessages={state?.formValidationErrors['addNew.file']} />        
                                        </div>
                                        <div className="col-md-8">
                                            <span className="text-end font-14 d-block text-dark-blue opacity-7">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-center">
                                    <button 
                                        type="button" 
                                        onClick={saveData}
                                        className="btn btn-green px-5">
                                        <span> Save</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPopup;
