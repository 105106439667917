import { Modal } from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../../../library/Core/SessionContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { axiosClient } from '../../../../library/Service/axiosClient';
import AddCallLogModal from './AddCallLogModal';
import ViewCallLogHistoryModal from './ViewCallLogHistoryModal';

const OfficeUsage = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const model = state.internal.model;
    const navigate = useNavigate();
    const [viewLogHistory, setViewLogHistory] = useState('');
    const [lastLogHistory, setlastLogHistory] = useState({ comment: '', user_comments: null, talked_to: '' });
    const [isModalOpen, setisModalOpen] = useState(false);

    const [isPersonate, setIsPersonate] = useState(sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false);
    const modalAddLogElement = document.getElementById('add-call-log');

    const getlastLogHistory = async () => {
        try {
            const response = await axiosClient().get(
                `${process.env.REACT_APP_COMMON_API}/admin-call-log/get-last-call-log-data/${state.internal.model.id}`
            );
            const data = await response.data;
            if (data.success === true && data.data.length > 0) {
                setlastLogHistory(data.data[0]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    useEffect(() => {
        getlastLogHistory();
        // const modalElement = document.getElementById('add-call-log');
        if (modalAddLogElement) {
            if (isModalOpen) {
                const modal = new Modal(modalAddLogElement);
                modal.show();
            } else {
                const modal = Modal.getInstance(modalAddLogElement);
                if (modal) {
                    modal.hide();
                }
            }
        }
    }, [isModalOpen]);

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;

    const userType = sessionState?.userTypeCode;

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        alert('hii');
        //window.location.href = `/alumni/welcome`;
        return;
    }

    const handleViewCallLogHistory = async () => {
        try {
            const response = await axiosClient().get(
                `${process.env.REACT_APP_COMMON_API}/admin-call-log/get-all-call-logs-data/${model.id}`
            );
            const data = await response.data;
            if (data.success) {
                setViewLogHistory(data.data);

                const modalElement = document.getElementById('view-call-history');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            }
            // setAdminUsers(data.data)
        } catch (error) {
            console.error('Error fetching data:', error);
            //console(error);
        }
    };

    const handleAddNewEntry = () => {
        setisModalOpen(true);
    };

    return (
        <>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-officeUsage" role="tabpanel" aria-labelledby="pills-officeUsage-tab">
                    <div className="underGraduate-detail">
                        <div className="blockHeader">
                            <div className="row g-2  align-items-center">
                                <div className="col">
                                    <div className="title">
                                        <i className="icon icon-world"></i> <span>Office Usage</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <a href="javascript:;" className="btn-add-section" onClick={handleAddNewEntry}>
                                        <b className="icon icon-edit me-2"></b> <span>Add</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="graduateRow edit">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <label className="form-label"> Disposition</label>
                                    <div className="form-group">
                                        <select className="form-select  moveFunction" aria-label="Class" disabled={true}>
                                            <option value="" selected={lastLogHistory?.comment ? true : false}>
                                                TBA
                                            </option>
                                            <option
                                                value="Call Back"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'CALL BACK' ? true : false}>
                                                Call Back
                                            </option>
                                            <option
                                                value="Information Collected"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'INFORMATION COLLECTED' ? true : false}>
                                                Information Collected
                                            </option>
                                            <option value="Busy" selected={lastLogHistory?.comment.toUpperCase() === 'BUSY' ? true : false}>
                                                {' '}
                                                Busy{' '}
                                            </option>
                                            <option
                                                value="Ringing No Response"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'RINGING NO RESPONSE' ? true : false}>
                                                {' '}
                                                Ringing No Response{' '}
                                            </option>
                                            <option
                                                value="Invalid Number"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'INVALID NUMBER' ? true : false}>
                                                Invalid Number{' '}
                                            </option>
                                            <option
                                                value="Information Collected Partly"
                                                selected={
                                                    lastLogHistory?.comment.toUpperCase() === 'INFORMATION COLLECTED PARTLY' ? true : false
                                                }>
                                                {' '}
                                                Information Collected Partly{' '}
                                            </option>
                                            <option
                                                value="Unable To Reach"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'UNABLE TO REACH' ? true : false}>
                                                Unable To Reach{' '}
                                            </option>
                                            <option
                                                value="Dropped A Year"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'DROPPED A YEAR' ? true : false}>
                                                Dropped A Year{' '}
                                            </option>
                                            <option
                                                value="Not Interested"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'NOT INTERESTED' ? true : false}>
                                                Not Interested{' '}
                                            </option>
                                            <option
                                                value="Hung Up"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'HUNG UP' ? true : false}>
                                                Hung Up{' '}
                                            </option>
                                            <option
                                                value="Wrong Number"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'WRONG NUMBER' ? true : false}>
                                                Wrong Number{' '}
                                            </option>
                                            <option
                                                value="Information Collected Whatsapp"
                                                selected={
                                                    lastLogHistory?.comment.toUpperCase() === 'INFORMATION COLLECTED WHATSAPP'
                                                        ? true
                                                        : false
                                                }>
                                                {' '}
                                                Information Collected Whatsapp{' '}
                                            </option>
                                            <option
                                                value="Switched Off"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'SWITCHED OFF' ? true : false}>
                                                Switched Off{' '}
                                            </option>
                                            <option
                                                value="Test Call"
                                                selected={lastLogHistory?.comment.toUpperCase() === 'TEST CALL' ? true : false}>
                                                Test Call{' '}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Talked to</label>
                                    <div className="form-group">
                                        <div className="row py-2">
                                            <div className="col-auto">
                                                <div className="form-check d-flex flex-wrap">
                                                    <input
                                                        className="form-check-input me-2"
                                                        name="Talked"
                                                        type="radio"
                                                        readOnly={true}
                                                        checked={lastLogHistory?.talked_to?.toUpperCase() === 'ALUMNI' ? true : false}
                                                        id="Alumni"
                                                    />
                                                    <label className="form-check-label font-16 font-500 flex-1 pt-1">Alumni</label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check d-flex flex-wrap">
                                                    <input
                                                        className="form-check-input me-2"
                                                        name="Talked"
                                                        type="radio"
                                                        readOnly={true}
                                                        checked={lastLogHistory?.talked_to?.toUpperCase() === 'PARENT' ? true : false}
                                                        id="Parent"
                                                    />
                                                    <label className="form-check-label font-16 font-500 flex-1 pt-1">Parent</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12">
                                    <label className="form-label">Comment</label>
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            id="floatingTextarea2"
                                            value={lastLogHistory?.user_comments != null ? lastLogHistory?.user_comments : 'TBA'}
                                            disabled={true}></textarea>

                                        <div className="invalid-feedback">Please Enter vaild Email Address</div>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="d-flex flex-wrap">
                                        <button
                                            type="button"
                                            onClick={handleViewCallLogHistory}
                                            className="btn btn-dark-blue text-white height-45 mb-2 mb-sm-0 me-2">
                                            <span>View Log</span>
                                        </button>
                                        {/* <button type="button" className="btn btn-green height-45 px-5"><span>SAVE</span></button>                                         */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewCallLogHistoryModal data={{ viewLogHistory }} />
            <AddCallLogModal data={{ model, setisModalOpen, modalAddLogElement, getlastLogHistory }} />
        </>
    );
};

export default OfficeUsage;
