import React, { useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useLoaderData } from 'react-router-dom';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';

const ManageWidgetsList = () => {
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { data } = useLoaderData() as { data: State['actions'] };

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { data },
        });
    }, []);

    /**
     * This function determines the configuration module slug based on the provided widget code.
     * It is used to generate the correct URL for configuring specific widgets.
     *
     * @param widgetCode - The unique identifier of the widget.
     * @returns The configuration module slug corresponding to the given widget code.
     *          If the widget code matches any of the specified cases, the function returns the corresponding configuration module slug.
     *          Otherwise, it defaults to 'configuration'.
     */
    const getConfigurationModuleSlug = (widgetCode: any) => {
        let configurationModule = 'configuration';

        switch (widgetCode) {
            case 'top_careers':
            case 'top_study_country':
            case 'top_companies':
            case 'top_colleges':
                configurationModule = 'configure';
                break;

            case 'guiding_alumni_mentors':
            case 'notable_alumni_mentors':
            case 'alumni-speak':
                configurationModule = 'configurelist';
                break;
        }

        return configurationModule;
    }

    return (
        <>
            <div className="main flex-1 bg-white">
                <div className="max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto">
                    <div className="d-flex justify-content-between align-items-center mb-3 pb-3 custom-bottom-liteblue">
                        <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title position-relative">
                            Manage Widgets For Alumni
                        </h3>
                    </div>
                    {state.flags.isDataLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <div className="manage-widgets-wrap">
                            <ul>
                                {state.data?.wedgitList.map((widget: any) => (
                                    <li key={widget.id}>
                                        <div className="row g-2 g-md-3">
                                            <div className="col-md">
                                                <div className="form-check form-check-tick">
                                                    <input className="form-check-input" type="checkbox" value="" id="Hero" />
                                                    <label className="form-check-label font-18 font-500 flex-1" htmlFor="Hero">
                                                        {widget.name}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-auto">
                                                <div className="d-flex align-items-center">
                                                    {widget.widgetInstId ? (
                                                        <>
                                                            <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}/${widget.widgetInstId}`} className="btn btn-widgets-edit me-2">
                                                                <span>Edit</span>
                                                            </a>
                                                            <a href={`${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${widget.widgetInstId}`} target='_blank' className="btn btn-priview">
                                                                <span>Preview</span>
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}`} className="btn btn-configure">
                                                                <span>CONFIGURE</span>
                                                            </a>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ManageWidgetsList;