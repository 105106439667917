import React, { useContext, useState, useEffect, ChangeEvent, FormEvent , useCallback } from 'react';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import Select, { MultiValue } from 'react-select';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import QnaApiService from './QnaApiService';
import { GlobalContext } from '../../library/Core/GlobalContext';

type TagOption = { value: string; label: string };

const AnswerQuestion: React.FC = () => {
    const [searchParams] = useSearchParams();
    const questionId = searchParams.get('question_id');
    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const userId = sessionState?.studentId ?? sessionState?.userId;
    const userType = sessionState?.userTypeCode;

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: { messagesFromCode: null, messageTypeCode: null, filteredMessages: [], searchText: null },
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const [formData, setFormData] = useState({
        description: '',
        tags: [] as string[] 
    });

    const [tagsOptions, setTagsOptions] = useState<{ value: string; label: string; }[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [tagError, setTagError] = useState<string | null>(null);


    useEffect(() => {
        fetchTags();
        getStudentProfile();
    }, []);

    const fetchTags = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/fetchtags`);

            if (!response.ok) {
                throw new Error('Failed to fetch tags');
            }

            const responseData = await response.json();

            if (responseData && Array.isArray(responseData.data)) {
                const options = responseData.data.map((tag: { code: string; value: string }) => ({
                    value: tag.value,
                    label: tag.value
                }));
                setTagsOptions(options);
            } else {
                throw new Error('Unexpected data format: data array not found');
            }
        } catch (error) {
            console.error('Error fetching tags:', error);
            setError('Failed to load tags.');
        }
    }, []);

    const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }, []);

    const handleTagsChange = useCallback((selectedOptions: MultiValue<TagOption>) => {
        if (selectedOptions.length > 5) {
            setTagError('You can select up to 5 tags only.');
            // Return early to prevent state update
            return;
        }
    
        setTagError(null);
        const tagsArray = selectedOptions.map(option => option.value);
    
        // Update formData with valid number of tags
        setFormData(prevFormData => ({
            ...prevFormData,
            tags: tagsArray
        }));
    }, []);    
    

    const getStudentProfile = async () => {
        const params = `?question_id=${questionId}`;

        try {
            const response = await QnaApiService.getStudentProfile(params);
            const questions = response?.data.data ?? [];
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { ...data, alumniStudentProfile: questions },
                    domain: globalState.domain,
                    internal,
                    flags: { isDataLoading: false }
                },
            });
        } catch (error) {
            console.error('Error fetching student profile:', error);
            setError('Failed to fetch student profile');
        }
    };

    

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
    
        // Validate fields
        if (!formData.description.trim()) {
            setError('Description is required.');
            return;
        }
    
        if (formData.tags.length === 0) {
            setTagError('At least one tag is required.');
            return;
        }
    
        const data = {
            user_type: userType, 
            user_id: userId, 
            question_id: questionId, 
            answer: formData.description,
            is_help_full: 0,
            tags: formData.tags
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/answer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
    
            if (response.ok) {
                navigate('/qna-dashboard');
            } else {
                const result = await response.json();
                setError(`Failed to submit question: ${result.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again later.');
        }
    };
    

    const formatDate = (isoDateString: string): string => {
        const createdDate = new Date(isoDateString);

        return `${createdDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })} | ${createdDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })}`;
    };

    const alumniStudentProfile = state.data?.alumniStudentProfile || [];

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div className="main flex-1 bg-white">
                <section className="updateadditionalinformation">
                    <div className="max-1140 px-lg-0 py-4 my-2 mx-auto">
                        <div className="alumni_statistics">
                            <div className="d-flex mb-1">
                                <button className="btn btn-back text-dark-blue p-0" onClick={() => navigate('/qna-dashboard')}>
                                    <i className="icon-left-arrow me-1"></i>
                                    <span>QnA Board</span>
                                </button>
                            </div>
                            <h2 className="font-35 font-600 mb-0 pb-3">Help the student with providing the guidance.</h2>
                            <div className="ActiveListings_present">
                                <div className="post-wrap mt-2">
                                    <div className="post-block">
                                        <div className="row g-3">
                                            <div className="col-sm-auto">
                                                <div className="post-img">
                                                    <img src={alumniStudentProfile[0]?.profile_picture_file_path || 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/yellow-student-boy.svg'} width="60" alt="" />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="row g-2">
                                                    <div className="col-12">
                                                        <div className="row g-2">
                                                            <div className="col-sm">
                                                                <div className="post-head">POSTED BY</div>
                                                                <div className="post-alumni-name">{alumniStudentProfile[0]?.first_name} {alumniStudentProfile[0]?.last_name}</div>
                                                                <div className="post-alumni-batch">{alumniStudentProfile[0]?.program_name}, {alumniStudentProfile[0]?.pass_out_year}</div>
                                                            </div>  
                                                            <div className="col-sm-auto">
                                                                <span className="font-14">{alumniStudentProfile[0] ? formatDate(alumniStudentProfile[0].created) : ''}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row g-2">
                                                            <div className="col-sm">
                                                                <p className="brife">{alumniStudentProfile[0]?.description}</p>
                                                            </div>  
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="row g-2">
                                                                <div className="col-12">
                                                                    <label htmlFor="description" className="form-label font-35 font-600 pt-3">Your Answer</label>
                                                                    <textarea
                                                                        className="form-control h-auto"
                                                                        id="description"
                                                                        name="description"
                                                                        rows={6}
                                                                        value={formData.description}
                                                                        onChange={handleChange}
                                                                    ></textarea>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="row g-3 align-items-end">
                                                                        <div className="col-lg-6 col-md-6 col-sm-5">
                                                                            <label htmlFor="tags" className="form-label font-16 font-500 pt-3">Add Tags</label>
                                                                            <Select
                                                                                isMulti
                                                                                name="tags"
                                                                                options={tagsOptions}
                                                                                className="basic-multi-select custom-multi-select"
                                                                                classNamePrefix="select"
                                                                                onChange={handleTagsChange}
                                                                                value={tagsOptions.filter(option => formData.tags.includes(option.value))}
                                                                            />

                                                                        </div>
                                                                        <div className="col-sm py-0 py-sm-2 mb-0 mb-sm-1">
                                                                            <span>Maximum Five tags</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 text-center d-flex justify-content-center pt-4">
                                                                    <button type="submit" className="btn btn-green">
                                                                        <span>Submit Answer</span>
                                                                    </button>
                                                                </div>
                                                                {error && <div className="error-message">{error}</div>}
                                                                {tagError && <div className="error-message">{tagError}</div>}
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </section>
            </div>
            <DebugBarControl />
        </SmartContext.Provider>
    );
};

export default AnswerQuestion;
