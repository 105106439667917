import React, { useContext, useState, useEffect, useRef } from 'react';
import { SimpleFormControlArguments, State } from '../../../library/Core/SmartTypes';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { axiosClient } from '../../../library/Service/axiosClient';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../../../library/Core/SmartFunctions';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import axios from 'axios';

const editorStyle = {
    // Adjust the height as needed
};

const Sendemail = ({ pageTitle, ...args }: SimpleFormControlArguments & { pageTitle: any }) => {
    document.title = pageTitle;

    const [SchoolLogo, setSchoolLogo] = useState('');
    const [imageLink, setImageLink] = useState('');
    const [subject, setSubject] = useState<string>('');
    const [sender, setSender] = useState<string>('');
    const [formattedMessage, setFormattedMessage] = useState<string>('');
    const [attached_file_path, setAttachedFilePath] = useState<string>(''); // Define attached_file_path state
    const [attachments, setAttachments] = useState<File[]>([]);
    const [attached_file_path1, setAttachedFilePath1] = useState<string>(''); // Define attached_file_path state
    const [message, setMessage] = useState<string>(''); // State to hold rich text content
    const [attach_file, setAttach_file] = useState<string>(''); // State to hold rich text content
    const [error, setError] = useState<string | null>(null); // Add error state
    const [successMessage, setSuccessMessage] = useState<string | null>(null); // Add success message state
    const [selectedCategory, setSelectedCategory] = useState('1');
    const [tempRefId, setTempRefId] = useState<string | null>(null); // Define tempRefId state

    // Handler to update the message when the Quill editor content changes
    const handleMessageChange = (value: string) => {
        setMessage(value);
    };

    let sesinstituteId = 0;
    const userDataString = sessionStorage.getItem('user');
    console.log(userDataString, 'session');
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.primaryInstitute;
    }

    const selectedCategoryRef = useRef<string | null>(null); // Default value '1' or any other default value

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCategory(event.target.value);
        selectedCategoryRef.current = event.target.value;
    };

    // Onsubmit category based

    const location = useLocation(); // Get the current location, including state
    const submittedData = location.state; // Retrieve the passed data

    const fetchSharedData = async () => {
        try {
            if (!submittedData) {
                console.log('No data received');
            } else {
                console.log(submittedData['category'], 'category');
                console.log(submittedData['studentID'], 'studentID');

                let response = await axiosClient().get(
                    `${process.env.REACT_APP_LEAD_SERVER}/userinfo/getuserinfo/${submittedData['studentID']}`
                );

                console.log(response?.data, 'response');
                console.log(response?.data.userinfo, 'userinfo');
                console.log(response?.data.pginfo, 'pginfo');
                console.log(response?.data.uginfo, 'uginfo');
                console.log(response?.data.achievement, 'achievement');

                const alumniName = response?.data.userinfo.first_name ? response?.data.userinfo.first_name : '';
                const alumnipic = response?.data.userinfo.profile_picture_file_path ? response?.data.userinfo.profile_picture_file_path : '';
                const programName = response?.data.userinfo.display_name ? response?.data.userinfo.display_name : '';
                const batch = response?.data.userinfo.passOutYear ? response?.data.userinfo.passOutYear : '';
                let AchiDetail = '';

                // Check if response.data.achievement is undefined, null, or an empty string
                if (!response?.data.achievement || response?.data.achievement === '') {
                    AchiDetail = ''; // If there's no achievement, set to empty
                } else {
                    // If achievement exists, get the title or set to empty if undefined
                    AchiDetail = response?.data.achievement.title || '';
                }

                const instituteName = response?.data.userinfo.institutename ? response?.data.userinfo.institutename : '';
                let universityName = '';
                if (response?.data.pginfo === 'undefined' || response?.data.pginfo === '') {
                    universityName = response?.data.pginfo.display_name ? response?.data.pginfo.display_name : '';
                } else {
                    universityName = response?.data.uginfo.display_name ? response?.data.uginfo.display_name : '';
                }

                const currentCompanyName = '';

                if (submittedData['category'] === '1') {
                    setSubject(`Check out the achievement of our alumni ${alumniName}!`);
                    setMessage(`We're thrilled to highlight the accomplishments of ${alumniName} from the ${programName} Batch of ${batch}. In their recent achievement of ${AchiDetail}, they made the institute proud. ${alumniName}, we miss your time at the institute! <p> It's moments like these that truly make the legacy of ${instituteName} shine with pride.`);
                    setAttach_file(alumnipic); // Extract attached_file_path
                    setAttachedFilePath1(alumnipic);
                    setImageLink(alumnipic);
                } else if (submittedData['category'] === '2') {
                    setSubject(`Check out the career journey of our alumni ${alumniName}!`);
                    setMessage(`Introducing ${alumniName}, our exceptional alumni from the ${programName} Batch of ${batch}! Started the journey from our institute's halls to excelling at the ${universityName} and has now reached remarkable heights at ${currentCompanyName}. Their success story inspires us all. <p> ${alumniName}, we take immense pride in having such remarkable alumni. All the best for your future Endeavors!`);
                    setAttach_file(alumnipic); // Extract attached_file_path
                    setAttachedFilePath1(alumnipic);
                    setImageLink(alumnipic);
                } else if (submittedData['category'] === '3') {
                    setSubject(`Check out our alumni ${alumniName} from the prestigious ${universityName}!`);
                    setMessage(`Meet ${alumniName} from the ${programName} Batch of ${batch} who is now a student of ${universityName}. We are always proud to see our alumni succeed in their personal and professional lives. We remember ${alumniName}'s time at institute and couldn't be more proud! <p> ${alumniName}, we can't wait to see what you'll do next! All the best for your future endeavors.`);
                    setAttach_file(alumnipic); // Extract attached_file_path
                    setAttachedFilePath1(alumnipic);
                    setImageLink(alumnipic);
                } else {
                    // Handle other categories if needed
                }
            }
        } catch (error) {
            console.error('Error fetching template data:', error);
        }
    };

    useEffect(() => {
        // Fetch template data when the component mounts
        if (submittedData !== null) {
            fetchSharedData();
        }
    }, []); // The empty dependency array ensures this effect runs only once

    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const [image, setImage] = useState(data);
    const urlParams = new URLSearchParams(window.location.search);
    const templateId: any = urlParams.get('id');

    const fetchTemplateData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/get-template`, {
                id: templateId,
            });

            const templates = response.data;

            setSubject(response.data['subject']);
            setSender(response.data['sender']);
            setMessage(response.data['message']);
            setAttach_file(response.data['attached_file_path']); // Extract attached_file_path
            const attachedFilePathFromAPI = response.data['attached_file_path'];
            setAttachedFilePath1(attachedFilePathFromAPI);
            setSelectedCategory(response.data['receiver_type'].toString());
            setTempRefId(response.data['template_ref_id']); // Set tempRefId

            console.log("Set Selected Category to:", response.data['receiver_type'].toString());
        } catch (error) {
            console.error('Error fetching template data:', error);
        }
    };

    useEffect(() => {
        // Fetch template data when the component mounts
        if (templateId !== null) {
            fetchTemplateData();
        }
    }, []); // The empty dependency array ensures this effect runs only once

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null) {
            const apiUrl = `${process.env.REACT_APP_BROADCAST_SERVER}/upload/get-attached`;
            const formData = new FormData();
            formData.append('pdf_doc', e.target.files[0]);

            const response = await axiosClient().post(apiUrl, formData);
            const link = response.data.link;
            const prefix = 'https://s3.ap-south-1.amazonaws.com/user.image1/';
            const modifiedUrl = link.slice(prefix.length);
            setImageLink(link);

            const imageLinkElement = document.getElementById('imagelink');
            if (imageLinkElement) {
                imageLinkElement.textContent = link; // Display the link in the label
            }
        }
    };

    const handleSave = async (type: any) => {
        let insertID;
        try {
            if (!subject.trim() || !message.trim() || !sender.trim()) {
                setError('Please check sender name, Title or message is missing!');
                return;
            }

            setError(null);
            setSuccessMessage(null);

            const quillEditor = document.querySelector('.ql-editor');
            const htmlContent = quillEditor ? quillEditor.innerHTML : '';
            const schoolId = sesinstituteId;
            const currentDate = new Date();
            const fileToUse = imageLink || attached_file_path1;

            const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);

            const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
            const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);

            const date = `${formattedDate} ${formattedTime}`;
            const jwtToken = localStorage.getItem('user-jwt-token');
            let SchoolLogo = sessionStorage.getItem('school-image') as any;

            if (templateId) {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-template`, {
                    subject: subject,
                    sender_mail: sender,
                    message: htmlContent,
                    created_date: date,
                    attached_file_path: fileToUse,
                    school_ref_id: sesinstituteId,
                    teplateid: templateId,
                    receiver_type: selectedCategory,
                }, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                });
                console.log(response.data);
            } else {
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/save-template`,
                    {
                        subject: subject,
                        message: htmlContent,
                        attached_file_path: imageLink,
                        school_ref_id: sesinstituteId,
                        status: 0,
                        created_date: date,
                        sender_mail: sender,
                        university_logo: SchoolLogo,
                        receiver_type: selectedCategory
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    });

                insertID = response.data['insertedId'];
            }

            let paramvalue;
            if (templateId) {
                paramvalue = templateId;
            } else {
                paramvalue = insertID;
            }

            if (type === 1) {
                window.location.href = `/broadcast`;
            } else {
                if (selectedCategory === '1') {
                    await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-receiver-type`, {
                        receiver_type: 1,
                        teplateid: paramvalue,
                    });
                    window.location.href = `/broadcast/student-profiles?template_id=${paramvalue}`;
                } else if (selectedCategory === '2') {
                    await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-receiver-type`, {
                        receiver_type: 2,
                        teplateid: paramvalue,
                    });
                    window.location.href = `/broadcast/alumni-profiles?template_id=${paramvalue}`;
                } else {
                    await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-receiver-type`, {
                        receiver_type: 3,
                        teplateid: paramvalue,
                    });
                    window.location.href = `/broadcast/lead-profiles?template_id=${paramvalue}&temp_ref_id=${tempRefId}`;
                }
            }

            setSubject('');
            setMessage('');

            setSuccessMessage('Message saved successfully.');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSenderChange = (value: string) => {
        setSender(value);
    };

    const handleSubjectChange = (value: string) => {
        setSubject(value);
    };

    useEffect(() => {
        const formatted = document.createElement('div');
        formatted.innerHTML = message;
        const formattedText = formatted.innerText;
        setFormattedMessage(formattedText);
    }, [message]);

    return (
        <div className="main flex-1 overflow-hidden">
            <div className="max-1140 mx-auto d-flex h-100">
                <div className="flex-1 d-flex flex-column mb-5">
                    <div className="pageContent flex-1 ps-0">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="white-block white-block-notopborderradius h-100 p-0">
                                    <div className="border-bottom border-2 custom-border-gray p-4 pb-3">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                                            <h3
                                                data-aos="fade-left"
                                                data-aos-delay="600"
                                                className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                                                Create New Broadcast
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="row sd_formsec">
                                        <div
                                            className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pb-4 px-0"
                                            data-aos="fade-up"
                                            data-aos-delay="900"
                                            id="no-notification">
                                            <form className="lstCustForm w-100 formrowpx mt-2 px-4 px-md-0">
                                                <div className="row mb-4">
                                                    <div className="col-md-12">
                                                        <label
                                                            className="form-label m-0 mb-1 font-16 font-500 w-100"
                                                            htmlFor="BroadcastTitle">
                                                            Name of Sender
                                                        </label>
                                                        <input
                                                            className="form-control flex-1"
                                                            type="text"
                                                            placeholder="Sender Name"
                                                            value={sender}
                                                            onChange={(e) => handleSenderChange(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label
                                                            className="form-label m-0 mb-1 font-16 font-500 w-100"
                                                            htmlFor="BroadcastTitle">
                                                            Title of the Broadcast
                                                        </label>
                                                        <input
                                                            className="form-control flex-1"
                                                            type="text"
                                                            placeholder="Subject"
                                                            value={subject}
                                                            onChange={(e) => handleSubjectChange(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col-md-12">
                                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="Message">
                                                            Message
                                                        </label>
                                                        <ReactQuill
                                                            style={editorStyle}
                                                            value={message}
                                                            onChange={handleMessageChange}
                                                            placeholder="Compose your message..."
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-1">
                                                    <div className="col-md-12">
                                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="Attachments">
                                                            Attachments (only for Email) <span>Only doc, docx, pdf files</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col-md-12 inputfile_parent m-0">
                                                        <div className="row mb-4">
                                                            <div className="col-md-12 inputfile_parent m-0">
                                                                <label htmlFor="choose_image" className="height-80">
                                                                    <span className="uploadlabel">Browse your files</span>
                                                                </label>

                                                                <input
                                                                    type="file"
                                                                    name="post_image"
                                                                    className="d-none"
                                                                    id="choose_image"
                                                                    onChange={handleImageUpload}
                                                                    accept="img/*"
                                                                    style={{ opacity: '0.3' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <input type="text" hidden value={attach_file} />
                                                        <div className="docselect">
                                                            <span className="me-1">
                                                                <img src="images/doc_icon.svg" alt="" />
                                                            </span>
                                                            <input
                                                                type="file"
                                                                name="fileUpload"
                                                                id="fileUpload"
                                                                className="inputfile"
                                                                data-multiple-caption="{count} files selected"
                                                                multiple
                                                                required
                                                            />
                                                            <span className="fileclose">
                                                                <span className="icon-cross"></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                        <div className="">
                                                            <label id="imagelink"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12" id="attached">
                                                    {attached_file_path1 && (
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-md-auto">
                                                                <label
                                                                    htmlFor="AudienceCategory"
                                                                    className="form-label m-0 mb-1 font-16 font-500 w-100">
                                                                    Attachments
                                                                </label>
                                                            </div>
                                                            <div className="col-md">
                                                                <a
                                                                    target="_blank"
                                                                    href={attached_file_path1}
                                                                    className="btn btn-border-gray position-relative">
                                                                    <i className="icon-pdf me-1"></i>
                                                                    <span>Preview File</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label
                                                            htmlFor="AudienceCategory"
                                                            className="form-label m-0 mb-1 font-16 font-500 w-100">
                                                            Audience Category
                                                        </label>

                                                        <div className="row g-3 g-sm-5">
                                                            <div className="col-auto">
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        value="1"
                                                                        id="Students"
                                                                        checked={selectedCategory === '1'}
                                                                        onChange={handleRadioChange}
                                                                        required
                                                                    />
                                                                    <label
                                                                        className="form-check-label font-16 font-500 flex-1"
                                                                        htmlFor="Students">
                                                                        Students
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        value="2"
                                                                        id="Alumni"
                                                                        checked={selectedCategory === '2'}
                                                                        onChange={handleRadioChange}
                                                                        required
                                                                    />
                                                                    <label
                                                                        className="form-check-label font-16 font-500 flex-1"
                                                                        htmlFor="Alumni">
                                                                        Alumni
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <input
                                                                        type="radio"
                                                                        value="3"
                                                                        id="Lead"
                                                                        checked={selectedCategory === '3'}
                                                                        onChange={handleRadioChange}
                                                                        required
                                                                    />
                                                                    <label
                                                                        className="form-check-label font-16 font-500 flex-1"
                                                                        htmlFor="Lead">
                                                                        Lead
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}

                    <div className="row justify-content-end align-items-center mt-4 broadcast_submit">
                        <div className="my-0 d-flex flex-wrap justify-content-center">
                            <button type="button" className="btn btn-draft" onClick={() => handleSave(1)}>
                                <span>Save as Draft</span>
                            </button>
                            <a href="/broadcast">
                                <button type="button" className="btn btn-draft">
                                    <span>Discard</span>
                                </button>
                            </a>
                            <button type="button" className="btn btn-publish" onClick={() => handleSave(2)}>
                                <span>Save and Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sendemail;
