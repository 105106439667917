import { isEmpty, toSentenceCase } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';
import PageConfigService from '../../../services/page-config.service';
import StudentService from '../../../services/student.service';
import { reportConfig } from './report-config';

class ManageAlumniService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ManageAlumniService.getDefaultSearchCriteria(params.pageName);

        return Promise.all([
            PageConfigService.get(params.pageName),
            ManageAlumniService.search(searchCriteria, params.pageName),
            ManageAlumniService.getSchoolSearchFilterDomains(params.pageName),
            StudentService.getProgramDomainData(params.pageName),
        ]).then((values) => {
            state.formConfig = values[0].data;
            state.data = {
                alumniList: values[1].data ?? [],
                // searchCriteria: { ...searchCriteria, schoolId: sessionStorage.getItem('user-school-id') },
                searchCriteria: { ...searchCriteria },
                filterDomains: values[2].data,
                reportConfig: reportConfig[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {}, programDomainData: values[3].data };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, reportType: string): any {
        const payload = ManageAlumniService.sanitizeSearchCriteria(searchCriteria, reportType);
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/management/manage-alumni`, payload);
    }

    // Notes: This is key function in forming the search criteria for the report. Handle with care.
    static sanitizeSearchCriteria(searchCriteria: any, reportType: string) {
        const reportTypesToRemoveDates = [
            'track-student-career-preferences',
            'track-alumni-education-preferences',
            'track-alumni-engagement-with-students',
            'track-alumni-highlights-with-students-engagement',
        ];
        
        if (reportTypesToRemoveDates.includes(reportType)) {
            delete searchCriteria.createdFrom;
            delete searchCriteria.createdTo;
        }

        if (reportType != 'track-alumni-engagement-with-students') {
            delete searchCriteria.conversationFromDate;
            delete searchCriteria.conversationToDate;
        }

        const schoolIdFromSession = sessionStorage.getItem('user-school-id');

        if (reportType.includes('student')) {
            searchCriteria = { ...searchCriteria, schoolId: schoolIdFromSession, userTypeCode: 'STUDENT' };
            if(reportType == 'track-student-profiles') delete searchCriteria.schoolId;
        }

        if (reportType.includes('alumni')) {
            searchCriteria = { ...searchCriteria, schoolId: schoolIdFromSession, userTypeCode: 'ALUMNI' };
        }

        if (['YES', 'NO'].includes(searchCriteria['workingStatusCode'])) {
            searchCriteria['workingStatusCode'] = searchCriteria['workingStatusCode'] === 'YES' ? 1 : 0;
        }

        if (!reportType.includes('profile')) {
            searchCriteria = { ...searchCriteria, userStatusCode: 'ACTIVE' };
        }

        if (searchCriteria.hasOwnProperty('notUpdated') && searchCriteria?.notUpdated) {
            searchCriteria = { ...searchCriteria, [`notUpdated${toSentenceCase(searchCriteria?.notUpdated)}`]: 1 };
        }

        if (searchCriteria.hasOwnProperty('notSure') && searchCriteria?.notSure) {
            searchCriteria = { ...searchCriteria, [`notSure${toSentenceCase(searchCriteria?.notSure)}`]: 1 };
        }

        if (reportType == 'track-alumni-engagement-with-students') {
            searchCriteria = { ...searchCriteria, hasStudentEngagement: 'TRUE' };
        }

        if (!isEmpty(searchCriteria.campusCode)) {
            delete searchCriteria.schoolId;
        }
        return searchCriteria;
    }

    static getSchoolSearchFilterDomains(reportName: string = '') {
        switch (reportName) {
            case 'track-alumni-engagement-with-students':
                return axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/directory/institute-filters`);
            case 'track-alumni-profiles':
                return axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/directory/institute-filters`);
            case 'track-alumni-highlights-with-students-engagement':
                return axiosClient().get(
                    `${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/school-engagement-search-filter-alumni-student`
                );
            case 'track-student-career-preferences':
                return axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/directory/institute-student-filters`);
            case 'track-student-profiles':
                return axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/directory/institute-student-filters`);
            default:
                return axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/directory/institute-filters`);
        }
    }

    static deactivateStudent = async (students: any) => {
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/deactivate`, { students });
    };

    static activateStudent = (students: any) => {
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/activate`, { students });
    };

    static markAsNotable = (students: any) => {
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/mark-notable`, { students });
    };

    static unMarkAsNotable = (students: any) => {
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/unmark-notable`, { students });
    };

    static convertToAlumni = (students: any) => {
        console.log(students, ' students 119');
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/convertToAlumni`, { students });
    };

    static getDefaultSearchCriteria(pageName: string = ''): any {
        let additionalCriteria = {};
        const criteria = {
            // return {
            passOutYear: [],
            // classCode: [],
            // curriculumCode: [],
            campusCode: [],
            programId: [],
            educationOrEmploymentCurrentStatusCode: [],
            countryCode: [],
            cityCode: [],
            residingCityCode: [],
            residingCountryCode: [],
            collegeCountryCode: [],

            preferenceCountryCode: [],
            countryPreferenceOrder: null,
            preferenceFieldOfStudy: [],
            careerPreferenceOrder: null,

            collegeCityCode: [],
            fieldOfStudyCode: [],
            notableCode: [],
            instituteId: [],
            pursuingEducationLevelCode: [],
            workingStatusCode:[]

            // createdFrom: moment().subtract(3, 'months').format('YYYY-MM-DD'),
            // createdTo: moment().format('YYYY-MM-DD'),

            // conversationFromDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
            // conversationToDate: moment().format('YYYY-MM-DD'),
        };

        if (['track-student-profiles', 'track-alumni-profiles'].includes(pageName)) {
            additionalCriteria = { userStatusCode: 'ACTIVE' };
        }

        return { ...criteria, ...additionalCriteria };
    }
}

export default ManageAlumniService;
