import { useContext } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { axiosClient } from '../../library/Service/axiosClient';
import { debounce, getDomainValueForCode } from '../../library/Core/SmartFunctions';

const useMessages = () => {
    const { state, dispatch } = useContext(SmartContext);

    function resetNewMessageMode() {
        dispatch({ type: 'ROUTE_INFO', payload: { ...state.routeInfo, newMessageMode: null, id: null } });
        const currentUrl = window.location.pathname;
        if (currentUrl.startsWith('/messages/send-message/')) {
            window.history.replaceState(null, currentUrl, '/messages');
        }
    }

    const removeReceiver = (receiverUser: any) => {
        console.log(state?.data?.memberIds);
        console.log('receiverUser');
        console.log(receiverUser.value);
        const newMemberId = state?.data?.memberIds.filter((member: any) => member?.value != receiverUser?.value);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'memberIds', value: newMemberId as any } });
    };

    const getUserInfo = (userId: string) => state.data.memberProfiles.find((member: any) => member?.id === userId);

    const openNewMessage = () => {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'newMessageMode', value: true } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversationId', value: 'NEW' } });
    };

    const sendMessage = async (message: string) => {
        const selectedConversationId = state.data.selectedConversationId;
        try {
            const result = await axiosClient().post(
                `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/${selectedConversationId}/message`,
                { message }
            );
            await getById(selectedConversationId);
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'selectedConversationId', value: selectedConversationId },
            });
            return result;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    };

    const startFreshConversion = async (message: string) => {
        const memberIds = state.data.memberIds as [];
        let result = await createNewConversation(
            message,
            memberIds.map((member: any) => member.value),
            [...new Set(memberIds.map((member: any) => member.userTypeCode))]
        );
    };

    const createNewConversation = async (newMessage: string, memberIds: string[], userTypeCode: string[]) => {
        const codes = ['STUDENT', 'SCHOOL', 'ALUMNI'];
        let uTypeCode = codes.find((code) => userTypeCode.includes(code)) || 'ALUMNI';

        const result: any = await axiosClient()
            .post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/`, {
                memberIds,
                message: newMessage,
                userTypeCode: uTypeCode,
            })
            .then(() => {
                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: { dataKey: 'messageMode', value: 'LIST_VIEW' },
                });
                dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'memberIds', value: [] as any } });
                // debounce(debouncedHandleChangeDescriptionConversation, 1000);
                setTimeout(() => {
                    refreshConversations();
                }, 1000); // You can adjust the delay as needed
            });

        return result;
    };

    /*const handleSendMessage = async () => {
        if (memberIds.length === 0) {
            setErrorMessage('Please select at least one user to send message');
            return;
        }

        if (newMessage.trim() === '') return;

        setErrorMessage('');

        let result;
        if (conversationId === 'NEW') {
            result = await createNewConversation(
                newMessage,
                memberIds.map((member: any) => member.value),
                [...new Set(memberIds.map((member: any) => member.userTypeCode))]
            );
        } else {
            result = await sendMessage(newMessage);
        }

        if (result) {
            setNewMessage('');
            closeNewMessageWindow();
            if (state?.routeInfo?.ids?.length > 0) dispatch({ type: 'ROUTE_INFO', payload: { ...state?.routeInfo, ids: [] } });
            setTimeout(() => refreshConversations(), 1000);
        }
    };*/

    const refreshConversations = async () => {
        const response = await axiosClient().get(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations?userTypeCode=${state.data.searchBy}`
        );

        console.log(response, ' response 252 ');

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'originalConversations', value: response.data?.conversations } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'conversations', value: response.data?.conversations } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'memberProfiles', value: response.data?.memberProfiles } });
        // applyFilter();
        return response;
    };

    //const debouncedHandleChangeDescriptionConversation = debounce(refreshConversations, 1000);

    const getById = async (conversationId: string) => {
        try {
            const result = await axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/${conversationId}`);
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversation', value: result.data } });
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversationId', value: conversationId } });
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'messageMode', value: 'SHOW_CONVERSATION_WITH_USER' } });
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'conversationWithUser', value: result?.data },
            });
            return result;
        } catch (error) {
            console.error('Error fetching conversation:', error);
            throw error;
        }
    };

    const loadConversationByUserId = (userUuid: string | undefined) => {
        //if by userUuid if conversation id found then load the conversation by calling getById function lese set the Mode is NEW_MESSAGE
    };

    async function performAction(action: string, conversationIds: any) {
        const result = await axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/action/${action}`, conversationIds);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'originalConversations', value: result.data.conversations } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: `actions.selectAll`, value: false } });
    }

    return {
        resetNewMessageMode,
        getUserInfo,
        openNewMessage,
        sendMessage,
        getById,
        loadConversationByUserId,
        startFreshConversion,
        performAction,
        removeReceiver,
    };
};

export default useMessages;
