// import { useContext } from 'react';
import { getValueFromSessionStore } from '../library/Core/SessionStore';
import { isEmpty } from '../library/Core/SmartFunctions';
import { axiosClient } from '../library/Service/axiosClient';
// import SessionContext from '../library/Core/SessionContext';

class AppSettingsService {
    static async get() {
        const storedData = JSON.parse(getValueFromSessionStore(`app-setting-XXXXXXXXXXXXXXXX`) as string);
        if (storedData) return Promise.resolve({ data: storedData }) as any;
        const appConfigDataUrl = `${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig`;
        return axiosClient().get(appConfigDataUrl) as any;
    }

    // static getLoginUrlFromStorage = () => {
    //     const userObject = JSON.parse(sessionStorage.getItem('user') as any);
    //     const domain = userObject?.instituteDomain as string;
    //     const userTypeCode = userObject?.userTypeCode;
    //     const userTypeSlug = AppSettingsService.getUserTypeSlug(userTypeCode as string);

    //     if (userTypeCode == 'SUPER_ADMIN') {
    //         return `/${userTypeSlug}/login`;
    //     }
    //     if (domain && userTypeCode) {
    //         return `/alumni/${domain}/login`;
    //     }
    // };
    
    static getLoginUrl = (sessionState:any) => {
        let domain          = sessionState?.instituteDomain;
        let userTypeCode  = sessionState?.userTypeCode;
        
        if(isEmpty(userTypeCode)) {
            const localUserStr = localStorage.getItem('user');
            if (localUserStr !== null) {
                const localUser = JSON.parse(localUserStr);
                userTypeCode = localUser?.role;
            }
        }
        userTypeCode = userTypeCode ?? 'ALUMNI';
        const userTypeSlug  = AppSettingsService.getUserTypeSlug(userTypeCode as string);

        if (userTypeCode == 'SUPER_ADMIN') {
            return `${userTypeSlug}/login`;
        }

        if (isEmpty(domain)) {
            let schoolSettingsStr: string | null = localStorage.getItem('school_settings');
            console.log(schoolSettingsStr,'schoolSettingsStr')
            if (schoolSettingsStr !== null) {
                let schoolSettings = JSON.parse(schoolSettingsStr);
                if ( schoolSettings.hasOwnProperty('institute')) {
                    domain = schoolSettings?.institute?.domain;
                } else {
                    domain = schoolSettings?.domain;
                }
            }
        }

        domain = domain ?? 'miles';

        if (domain && userTypeSlug) {
            return `${userTypeSlug}/${domain}/login`;
        }

    };
    
    static getUserTypeSlug = (userTypeCode: string) => {
        console.log("userTypeCode",userTypeCode)
        const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];
        if (schoolAdminRoles.includes(userTypeCode)) {
            return 'management';
        }
        switch (userTypeCode) {
            case 'SUPER_ADMIN':
                return 'admin';
            case 'STUDENT':
                return 'user';
            case 'ALUMNI':
                return 'user';
            default:
                return 'user';
        }
    };
}

export default AppSettingsService;
