import { useEffect } from 'react';
import addIcon from '../../../styles/images/add_plus.svg';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { axiosClient } from '../../../library/Service/axiosClient';


interface State {
    formConfig: any; // Replace with actual type if available
    data: any; // Replace with actual type if available
    internal: any; // Replace with actual type if available
    routeInfo: any; // Replace with actual type if available
}
const AlumniProfileList = () => {
    
    const { id, code } = useParams();
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    const handleDelete = async (profileId: any) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this profile?");
        if (!confirmDelete) return;
      
        try {
          // API call to inactivate the profile
          //const response = await axiosClient().post(`/api/v1/profiles/inactivate`, { id: profileId });
          const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/deleteWidgetalumni`, { id: profileId });
                
      
          if (response.status === 200) {
            alert("Profile has been delete successfully.");
            window.location.reload();  // Refresh the page
      
          } else {
            alert("Failed to delete the profile.");
            window.location.reload();  // Refresh the page
          }
        } catch (error) {
          console.error("Error deleting profile:", error);
          alert("An error occurred. Please try again.");
        }
      };
    // useEffect{(

    // )}
    return (
        <>
        <div className="col-auto"><div className="font-18 fst-italic">Add Minimum 4</div></div>
        <div className="table-responsive attendee_dtl">
            <table className="table" style={{minWidth:'1045px'}}>
                <thead>
                    <tr>
                        <th style={{width:'300px'}}>
                            <div className="d-flex flex-wrap">
                                Alumni Name                
                            </div>
                        </th>
                        <th style={{width:'500px'}}>Campus</th>
                        <th style={{width:'150px'}}>
                            <a className="labelItem" href="javascript:;">
                                Action
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                {data?.countData?.widgetWiseListData?.map((item:any, index:any) => (
                    <tr key={index}>
                    <td>
                        <div className="d-flex align-items-center checkoption">
                            <a className="tablink" href="javascript:;">
                                <i className="me-2 position-relative">
                                    <img src={item.profile_pic || "https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/dummy_male.svg"} 
                                            className="rounded-circle img-fluid" 
                                            width="40" 
                                            alt={`${item.user_name || ""}`} />
                                </i>
                                <span data-bs-toggle="tooltip" title={item.name || ""} className="singlelinename font-16 lineHeight-16">
                                    {item.user_name || ""}
                                    <span className="d-block font-13 fst-italic">
                                        Batch - {item.user_year || ""}
                                    </span>
                                </span>
                            </a>
                        </div>
                    </td>
                    <td>
                        <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                            {item.school_name || ""}
                        </div>
                    </td>

                    <td className="text-center">
                        <div className="d-flex align-items-center">                                                        
                            <a href={`/widget/editguidingalumni/${code}/${id}/${item.id}`} className="btn-border-gray height-35 d-flex align-items-center px-4 me-2">
                                <i className="icon-edit font-16 w-auto h-auto position-relative me-2 mb-1"></i><span>Edit</span>
                            </a>
                            <a className="btn btn-danger height-35 d-flex align-items-center px-4 radius-25" href="javascript:;" onClick={() => handleDelete(item.id)}>
                            
                                <i className="icon-cross font-12 w-auto h-auto position-relative me-2"></i><span>Delete</span>
                            </a>
                        </div>
                    </td>
                    </tr>
                    ))}

{/*                 
                <tr>
                    <td>
                    <div className="d-flex align-items-center checkoption">
                        <a className="tablink" href="javascript:;">
                        <i className="me-2 position-relative">
                            <img src="http://www.univariety.xyz/prototype/school_dashboard/images/ravi_verma_icon.png" className="rounded-circle img-fluid" width="40"/>
                        </i>
                        <span data-bs-toggle="tooltip" title="" data-bs-original-title="Anuradha Gautam" className="singlelinename font-16 lineHeight-16">
                        Vaibhav Bangia
                            <span className="d-block font-13 fst-italic">
                            Batch - 2017
                            </span>
                        </span>
                        </a>
                    </div>
                    </td>
                    <td>
                    <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                    School Of Scholars, Wanadongri
                    </div>
                    </td>
            
                    <td className="text-center">
                        <div className="d-flex align-items-center">                                                        
                            <a className="btn-border-gray height-35 d-flex align-items-center px-4 me-2" href="javascript:;"><i className="icon-edit font-16 w-auto h-auto position-relative me-2 mb-1"></i><span>Edit</span></a>
                            <a className="btn btn-danger height-35 d-flex align-items-center px-4 radius-25" href="javascript:;"><i className="icon-cross font-12 w-auto h-auto position-relative me-2"></i><span>Delete</span></a>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className="d-flex align-items-center checkoption">
                        <a className="tablink" href="javascript:;">
                        <i className="me-2 position-relative">
                            <img src="http://www.univariety.xyz/prototype/school_dashboard/images/ravi_verma_icon.png" className="rounded-circle img-fluid" width="40"/>
                        </i>
                        <span data-bs-toggle="tooltip" title="" data-bs-original-title="Anuradha Gautam" className="singlelinename font-16 lineHeight-16">
                        Vaibhav Bangia
                            <span className="d-block font-13 fst-italic">
                            Batch - 2017
                            </span>
                        </span>
                        </a>
                    </div>
                    </td>
                    <td>
                    <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                    School Of Scholars, Wanadongri
                    </div>
                    </td>
            
                    <td className="text-center">
                        <div className="d-flex align-items-center">                                                        
                            <a className="btn-border-gray height-35 d-flex align-items-center px-4 me-2" href="javascript:;"><i className="icon-edit font-16 w-auto h-auto position-relative me-2 mb-1"></i><span>Edit</span></a>
                            <a className="btn btn-danger height-35 d-flex align-items-center px-4 radius-25" href="javascript:;"><i className="icon-cross font-12 w-auto h-auto position-relative me-2"></i><span>Delete</span></a>
                        </div>
                    </td>
                </tr> */}
            
                </tbody>
            </table>
        </div>

        <div className="mt-2 d-flex justify-content-center">
            
            <a 
                className="btn btn-green height-40 d-flex align-items-center px-4 radius-25" 
                href={`/widget/addguidingalumni/${code}/${id}`}
            >
                <i className="me-2">
                    <img src={addIcon} alt="Add Icon" />
                </i>
                <span>Add More</span>
            </a>
                {/* <i className="me-2"><img src={addIcon}/></i> <span>Add More</span>*/} 
        </div>
        </>
        
    );
};

export default AlumniProfileList;