import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSmartState from '../../../common/hooks/useSmartState';
import { getStudentImage } from '../../../common/styles/Images';
import { SmartContext } from '../../../library/Core/SmartContext';
import { formatFullName, getDomainValueForCode, isEmpty, handleCopyText, getFlexColDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { FormControl, FormSection } from '../../../library/Core/SmartTypes';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';
import ExportToExcel from '../../../library/SimpleControls/ExportToExcel';
import InternalAdminReportService from './InternalAdminReportService';
import RowColumnBuilder from './RowColumnBuilder';
import { REPORT_CONFIG } from './report-config';
import useInternalAdminReports from './useInternalAdminReports';
import { axiosClient } from '../../../library/Service/axiosClient';
import SessionContext from '../../../library/Core/SessionContext';

const BATCH_SIZE = 10;

const Results = () => {
    const { state, dispatch } = useContext(SmartContext);
    const allRowsData = state.data.alumniList ?? [];

    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const { setStateValue } = useSmartState();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const { getFormattedDate, activateAlumni, deactivateAlumni, validateAlumni } = useInternalAdminReports();
    const resultsFormConfig = state.formConfig?.sectionRepository[1] as FormSection;
    const config = REPORT_CONFIG[state.routeInfo.pageName];
    const loadMoreRef = useRef(null);
    const [pageData, setPageData] = useState([] as any[]);
    const getActionLabel = (code: string) => code.toLowerCase().replace(/_/g, ' ');
    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);

    const handleSelectRow = (event: any) => {
        if (event.target.checked) setSelectedRows((prev) => [...prev, event.target.value]);
        else setSelectedRows((prev) => prev.filter((id) => id !== event.target.value));
    };

    const handleSelectAll = (event: any) => {
        const students = event.target.checked ? allRowsData.map((item: any) => item.uuid) : [];
        setSelectedRows(students);
    };

    const getTagline = (row: any) => {
        if (!isEmpty(row.passOutYear)) {
            return `${row.programName} - ${row.passOutYear}`;
        } else if (!isEmpty(row.programName)) {
            return `${row.programName}`;
        } else {
            return '';
        }
    };

    useEffect(() => {
        setPageData([...allRowsData.slice(0, BATCH_SIZE)]);
    }, [state.data.alumniList]);

    useEffect(() => {
        const loadMoreObserver = new IntersectionObserver((entries) => {
            if (allRowsData.length <= pageData.length) {
                if (loadMoreRef.current) loadMoreObserver.unobserve(loadMoreRef.current);
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = allRowsData.slice(0, pageData.length + BATCH_SIZE);
                setPageData([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, pageData]);

    const getAlumniNameField = (row: any) => {
        return (
            // <div className="d-flex align-items-center checkoption">
            //     {config['SHOW_SELECT_ALL'] && (
            //         <input
            //             className="form-check-input me-2 StudentId"
            //             name="uuid"
            //             type="checkbox"
            //             value={row.uuid}
            //             checked={selectedRows.includes(row.uuid)}
            //             onChange={handleSelectRow}
            //         />
            //     )}
            //     <a
            //         className="tablink"
            // href={`/alumni/${row.uuid}/profile`}
            // // href={`/${row?.userTypeCode?.toLowerCase()}/${row.uuid}/profile`}
            // // href={`/sso/${sessionState?.userHash}/cHJvZmlsZQ==/${row.uuid}`}
            //         target="_blank"
            //         rel="noopener noreferrer">
            //         <i className="me-2 position-relative">
            //             <img src={getStudentImage(row)} className="rounded-circle img-fluid" width="30" />
            //             {/* {showStar(row) && <i className="icon-star circle-img"></i>} */}
            //         </i>
            //         <span
            //             data-bs-toggle="tooltip"
            //             title={`${formatFullName(row.firstName, row.lastName)}`}
            //             className="singlelinename font-16 lineHeight-16">
            //             {formatFullName(row.firstName, row.lastName)}
            //             <span className="d-block font-13 fst-italic"> {getTagline(row)} </span>
            //         </span>
            //     </a>

            //     <div className='copyId d-flex align-items-center mt-1'>
            //         <span className="font-13 fst-italic singlelinename me-1"> {row.uuid} </span>
            //         <button id={`uuid-${row.uuid}`} className='copy-text border-0 bg-transparent' title='click to copy id'   onClick={() => handleCopyText(row.uuid, `uuid-${row.uuid}`)}>
            //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
            //         <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
            //         </svg>
            //         </button>
            //     </div>
            // </div>

            <div className="d-flex flex-column checkoption">
                {config['SHOW_SELECT_ALL'] && (
                    <input
                        className="form-check-input me-2 StudentId"
                        name="uuid"
                        type="checkbox"
                        value={row.uuid}
                        checked={selectedRows.includes(row.uuid)}
                        onChange={handleSelectRow}
                    />
                )}
                <a
                    className="tablink"
                    href={`/alumni/${row.uuid}/profile`}
                    // href={`/${row?.userTypeCode?.toLowerCase()}/${row.uuid}/profile`}
                    // href={`/sso/${sessionStorage.getItem('user-hash')}/cHJvZmlsZQ==/${row.uuid}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="me-2 position-relative">
                        <img src={getStudentImage(row)} className="rounded-circle img-fluid" width="30" />
                        {showStar(row) && <i className="icon-star circle-img"></i>}
                    </i>
                    <span
                        data-bs-toggle="tooltip"
                        title={`${formatFullName(row.firstName, row.lastName)}`}
                        className="singlelinename font-16 lineHeight-16">
                        {formatFullName(row.firstName, row.lastName)}
                        <span className="d-block font-13 fst-italic"> {getTagline(row)} </span>
                    </span>
                </a>
                <div className="copyId d-flex align-items-center mt-1">
                    <span className="font-13 fst-italic singlelinename me-1"> {row.uuid} </span>
                    <button
                        id={`uuid-${row.uuid}`}
                        className="copy-text border-0 bg-transparent"
                        title="click to copy id"
                        onClick={() => handleCopyText(row.uuid, `uuid-${row.uuid}`)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-copy"
                            viewBox="0 0 16 16">
                            <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        );
    };

    const getShortenedSchoolName = (row: any): any => {
        const parts = row?.schoolName.split(',');
        const schoolNameContent =
            parts.length > 1 ? (
                <>
                    <span>
                        {parts[0]},<br />
                        {parts.slice(1).join(',').trim()}
                    </span>
                </>
            ) : (
                <>{row.schoolName}</>
            );

        if (state.routeInfo.pageName === 'internal-admin-all-school-summary') {
            return (
                <a className="tablink" href={`/support/all-alumni-report/${row.schoolId}`} target="_blank" rel="noopener noreferrer">
                    {schoolNameContent}
                </a>
            );
        }

        // If condition is not satisfied, display just the school name text
        return schoolNameContent;
    };

    const getUserEnteredInfo = (nonStandardType: string, nonStandardInfoJson: string) => {
        const nonStandardInfo = JSON.parse(nonStandardInfoJson);
        const content = nonStandardInfo
            ?.filter((item: any) => item?.nonStandardType === nonStandardType)
            ?.map((item: any, index: number) => (
                <span key={`${nonStandardType}-${index}`}>
                    {item?.type}: {item?.freeFormText}
                    <br />
                </span>
            ));
        return content;
    };

    const getActionNode = (control: FormControl, row: any) => {
        const actions = config['ROW_ACTION'].filter((action: any) => {
            if (row.isPeerReviewed == 1 && action === 'VALIDATE') return false;
            // if (row.userStatusCode === 'ACTIVE' && action === 'ACTIVATE') return false;
            if (row.userStatusCode !== 'INACTIVE' && action === 'ACTIVATE') return false;
            if (row.userStatusCode === 'INACTIVE' && action === 'DEACTIVATE') return false;
            return true;
        });

        return actions.length > 1 ? (
            <ActionSettings id={row} actions={actions} onClick={handleAction} />
        ) : (
            <a className="btn-update" href={config['UPDATE_ACTION_LINK'](row.uuid)} target="_blank" rel="noopener noreferrer">
                Update
            </a>
        );
    };

    const handleAction = (uuid: string, action: string) => {
        console.log(uuid);
        handlePerformAction(action, [uuid]);
    };

    const getSchoolCountsWithLink = (columnName: string, row: any) => (
        <a
            className="tablink"
            href={`/support/all-alumni-report/${columnName === 'total' ? row.schoolId : row.schoolId + '/' + columnName}`}
            target="_blank"
            rel="noopener noreferrer">
            {row[columnName]}
        </a>
    );

    const getFieldNode = (control: FormControl, row: any) => {
        let content;

        const getPresentStatusPart = (row: any) => (
            <div key={row.uuid+''+row?.schoolId} className="d-flex flex-column">
                <span>
                Education status - <b>{getDomainValueForCode(row.presentEducationStatusCode, 'PRESENT_EDUCATION_STATUS_CODE', state) ?? ''}</b>
                </span>
                <span>
                    Working status - <b>{getDomainValueForCode(row.workingStatusCode, 'WORK_STATUS_CODE', state) ?? ''}</b>
                </span>
            </div>
        );

        switch (control.id) {
            case 'alumniName':
                content = getAlumniNameField(row);
                break;
            case 'schoolName':
                content = getShortenedSchoolName(row);
                break;
            case 'presentStatusNew':
                content = getPresentStatusPart(row);
                break;
            case 'buttons':
                content = getActionNode(control, row);
                break;
            case 'createdAt':
            case 'updatedAt':
                content = getFormattedDate(row[control.id]);
                break;
            case 'alumniCreatedAndUpdated':
                content = (
                    <>
                        <span>Created on : {getFormattedDate(row['createdAt'])}</span>
                        <br />
                        <span>Updated on : {getFormattedDate(row['updatedAt'])}</span>
                    </>
                );
                break;
            case 'networkAndPairingOne':
            case 'networkAndPairingTwo':
            case 'eventsAndCareerSupportOne':
            case 'eventsAndCareerSupportTwo':
                content = RowColumnBuilder(control.id, row);
                break;
            case 'userEnteredCollegeName':
                content = getUserEnteredInfo('COLLEGE', row['nonStandardInfoJson']);
                break;
            case 'userEnteredExamName':
                content = getUserEnteredInfo('EXAM', row['nonStandardInfoJson']);
                break;
            case 'total':
            case 'pendingValidation':
            case 'upToDate':
            case 'l1Due':
            case 'l2Due':
            case 'l3Due':
                content = getSchoolCountsWithLink(control.id, row);
                break;
            default:
                if (control.props?.domainCategoryCode?.length > 0)
                    content = getDomainValueForCode(row[control.id], control.props.domainCategoryCode, state);
                else content = row[control.id];
        }

        return (
            <td key={control.id} className={control.className}>
                {content}
            </td>
        );
    };

    const handlePerformAction = async (action: string, students: any) => {
        switch (action) {
            case 'DEACTIVATE':
                await deactivateAlumni(students);
                setSelectedRows([]);
                InternalAdminReportService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
                    setStateValue('alumniList', response.data);
                    fetchAlumniCount(state);
                    toast.success('De-activated successfully');
                });
                break;
            case 'ACTIVATE':
                await activateAlumni(students);
                setSelectedRows([]);
                InternalAdminReportService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
                    setStateValue('alumniList', response.data);
                    fetchAlumniCount(state);
                    toast.success('Activated successfully');
                });
                break;
            case 'VALIDATE':
                await validateAlumni(students);
                setSelectedRows([]);
                InternalAdminReportService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
                    setStateValue('alumniList', response.data);
                    fetchAlumniCount(state);
                    toast.success('Validated successfully');
                });
                break;

            case 'AUTO_LOGIN':
                axiosClient()
                    .get(`${process.env.REACT_APP_COMMON_API}/users/get-user-hash/${students[0]?.uuid}/${students[0]?.schoolId}`)
                    .then((res: any) => {
                        const responseUserData = res.data as any;
                        if (responseUserData.success === true) {
                            // window.open(
                            //     `${process.env.REACT_APP_APP_BASE_URL}/sso/${responseUserData.data[0].user_hash}/cHJvZmlsZQ==`,
                            //     '_blank'
                            // );

                            const flexCol = getFlexColDomainValueForCode(students[0]?.schoolId, 'INSTITUTE_DOMAIN', state);
                            const whitelabel_url = isEmpty(flexCol) ? '' : JSON.parse(flexCol).whitelabel_url;
                            const baseUrl = !isEmpty(whitelabel_url) ? whitelabel_url : `${process.env.REACT_APP_APP_BASE_URL}/`;

                            const finalUrl = `${baseUrl}sso/${responseUserData.data[0].user_hash}/cHJvZmlsZQ==`;
                            window.open(finalUrl, '_blank');
                        }
                    })
                    .catch((err: any) => {
                        // Handle other errors
                        console.error(err);
                        alert('An error occurred while fetching details. Please try again later.');
                    });
                break;
        }
    };

    const fetchAlumniCount = async (state: any) => {
        try {
            const alumniCountData = await InternalAdminReportService.searchCount(state.data.searchCriteria, state.routeInfo.pageName);

            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key: 'alumniCount', value: alumniCountData?.data },
            });
        } catch (error) {
            console.error('Error fetching alumni count:', error);
        }
    };

    // const tableWidth = [250,180,180];

    const showStar = (row: any) => (row.isNotable && row.isNotable == 1 ? true : false);

    return (
        <div>
            <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                    <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                        {config['BULK_ACTION'].length > 0 && (
                            <div className="d-flex justify-content-start align-items-center customselect_parnt">
                                <select
                                    name="complete Class 12"
                                    className="form-select w-120"
                                    onChange={(e) => setBulkAction(e.target.value)}>
                                    <option value={''}>Select</option>
                                    {config['BULK_ACTION'].map((action: string) => (
                                        <option key={action} value={action}>
                                            {getActionLabel(action)}
                                        </option>
                                    ))}
                                </select>
                                <button className="btn btn-darkblue" onClick={() => handlePerformAction(bulkAction, selectedRows)}>
                                    <span className="line-height-16">Apply</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <ExportToExcel
                        rawData={allRowsData}
                        columns={InternalAdminReportService.getExportToExcelColumns(state.routeInfo.pageName)}
                        pageName={state.routeInfo.pageName}
                    />
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                        {/* <h3 className="font-14 font-500 mb-0 me-2">Total {allRowsData.length} records</h3> */}
                        {/* <h3 className="font-14 font-500 mb-0 me-2">Total {alumniCount && alumniCount[0]?.totalAlumni} records</h3> */}
                        <h3 className="font-14 font-500 mb-0 me-2">
                            Total {state?.internal?.alumniCount && state?.internal?.alumniCount[0]?.totalAlumni} records
                        </h3>
                    </div>
                </div>
                <div className="table-responsive attendee_dtl">
                    <table className={`table ${config.TABLE_CLASS}`} style={config.TABLE_STYLE}>
                        <thead>
                            <tr>
                                {resultsFormConfig.controlGroup.map((field: FormControl, index: number) => (
                                    // <th key={field.props.label} style={[0,1,2].includes(index) ? {'width': config['TABLE_WIDTH'][index]} : {}}>
                                    <th
                                        key={field.props.label}
                                        style={config?.TABLE_WIDTH?.length > index ? { width: config?.TABLE_WIDTH[index] } : {}}>
                                        {/* {!index && ( */}
                                        {!index && config['SHOW_SELECT_ALL'] && (
                                            <input
                                                type="checkbox"
                                                checked={selectedRows.length === allRowsData.length}
                                                id="Antony"
                                                className="form-check-input me-2 mt-0"
                                                onChange={handleSelectAll}
                                            />
                                        )}
                                        {field.props.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {pageData &&
                                pageData.map((row: any) => (
                                    <tr key={row?.uuid+''+row?.schoolId} className="">
                                        {resultsFormConfig.controlGroup.map((field: FormControl) => getFieldNode(field, row))}
                                    </tr>
                                ))}
                            <tr key="loadMoreRef" ref={loadMoreRef} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Results;
