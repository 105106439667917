import { useContext, useRef, useState } from "react";
import { SmartContext } from "../../library/Core/SmartContext";
import { isEmpty } from "../../library/Core/SmartFunctions";
import { DispatchEvent, State } from "../../library/Core/SmartTypes";
import { Modal } from "bootstrap";


interface ListPopupProps {
    id: string;
}

const ListPopup: React.FC<ListPopupProps> = (args) => {
    const { id } = { ...args };
    const { state, dispatch } = useContext(SmartContext);
    const modalRef = useRef<HTMLDivElement>(null);
    
    let data: any = [];
    if ( ! isEmpty(state?.internal?.selectedEntityId) && state?.internal?.selectedEntityId > 0 ) {
        data = state?.data?.popupData[state?.internal?.selectedEntityId];
    } else {
        data = state?.data?.widgetData?.otherList;
    }
    const count = data?.length ?? 0;

    
    const handleClosePopup = () => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {} } });

        if (modalRef.current) {
            const modal = Modal.getInstance(modalRef.current);
            if (modal) {
                dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isModalOpen', value: false } });
                modal.hide();
            }
        }
    }
    return (
        <div 
            className="modal fade px-3" 
            id={id}
            data-bs-backdrop="static" 
            data-bs-keyboard="false" 
            tabIndex={-1} 
            aria-labelledby="staticBackdropLabel" 
            style={{ display: 'none' }}
            aria-modal="true"
            ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered max-900 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-lite-blue d-flex align-items-center justify-content-between">
                        <h5 className="modal-title d-flex align-items-center" id="staticBackdropLabel">
                            <i className="me-2 d-flex align-items-center">
                                <img src={state.internal.widget_icon} width="30" alt="college icon"/>
                            </i>
                            {/* Top Colleges with Alumni - India (42) */}
                            {state?.internal?.widget_title} ({count})
                        </h5>
                        <button type="button" onClick={handleClosePopup} className="btn-close btn-close-small m-0 position-static" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="colleges-list">
                            <ul>
                                {data?.map((item: any, index: any) => (
                                    <li key={index}>
                                        <div className={`logoImg cls_${state?.internal?.widget_code}`}>
                                            <img src={!isEmpty(item.logo) ? item.logo : `${process.env.REACT_APP_IMAGE_BASEPATH}/building.svg`} width={90} alt={item.name} className="img-fluid rounded h-auto" />
                                        </div>
                                        <div className="logoName">
                                            <p>{item.name}</p>
                                            {item.location && (
                                                <span><i className="icon icon-location-pin"></i> {item.location}</span>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListPopup;
