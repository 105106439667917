import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { canEditProfile, getDomainValueForCode, isEmpty, isSchoolAdmin } from '../../../../library/Core/SmartFunctions';
import SessionContext from '../../../../library/Core/SessionContext';
import { Modal } from 'bootstrap';
import EditSubjectWiseMarksModal from '../../edit/education/EditSubjectWiseMarksModal';
import SubjectWiseMarksFormPageConfig from './SubjectWiseMarksFormPageConfig.json';

const EducationDetails = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { id, pageName } = useParams();
    const model = state.internal.model;
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const profile = state.data.profile;

    const [dispatchevent, setDispatchEvent] = useState(false);
    const [addNewRowEvent, setAddNewRowEvent] = useState(0);

    useEffect(() => {
        Promise.resolve(SubjectWiseMarksFormPageConfig)
            .then((config) => {
                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig: config,
                        data: { ...state.data, subjectWiseMarks: [], curriculum: '',subjectWiseMarksUpdateId:'' },
                        domain: new Map([...state.domain]),
                        internal: state.internal,
                        routeInfo: state.routeInfo,
                    },
                });
                setisConfigAvailable(true);
            })
            .catch((error) => {
                console.error('Error fetching page config:', error);
            });
    }, []);

    useEffect(() => {
        let setGradeArray = false;
        if (state?.data?.subjectWiseMarks) {
            const updatedSubjectWiseMarks = state.data.subjectWiseMarks.map((subject: any) => {
                 console.log('subject.cieGrade', subject.cieGrade);
                if (subject?.cieGrade === undefined) {
                    setGradeArray = true;
                    return {
                        ...subject,
                        subjectCode: '',
                        marks: '',
                        cieGrade: {},
                    };
                } else {
                    return subject;
                }
            });

            if (setGradeArray) {
                dispatch({
                    type: 'REFRESH_DATA',
                    payload: { data: { ...state.data, subjectWiseMarks: updatedSubjectWiseMarks } },
                });
            }
        }
    }, [state?.data?.subjectWiseMarks]);

    const pursuingEducationLevels = ['UG', 'PG', 'Doctoral'];

    const pursuingEducationLevelCode =
    profile?.pursuingEducationLevelCode && !pursuingEducationLevels.includes(profile?.pursuingEducationLevelCode)
            ? profile?.pursuingEducationLevelCode
            : 13;

    console.log("pursuingEducationLevelCode",pursuingEducationLevelCode)

    const programLevel = sessionState.programLevel;

    if (sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE') {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    const calculateCIEGrade = (cieMarks: any) => {
        const grades = [
            cieMarks?.AStar && `${cieMarks?.AStar}A*`,
            cieMarks?.A && `${cieMarks?.A}A`,
            cieMarks?.B && `${cieMarks?.B}B`,
            cieMarks?.C && `${cieMarks?.C}C`,
            cieMarks?.D && `${cieMarks?.D}D`,
            cieMarks?.E && `${cieMarks?.E}E`,
            cieMarks?.F && `${cieMarks?.F}F`,
        ];

        // Filter out any undefined or falsy values, then join the results
        return grades.filter(Boolean).join(',');
    };

    const addPercentageSymbolForCurriculum = (marks: string, curriculumCode: string) => {
        if (['CBSE','CISCE',"State Board","NIOS"].includes(curriculumCode)) {
            return marks + '%';
        } else return marks;
    };

  
    const programLevelArray = state?.domain?.get('PROGRAM_LEVEL');
    console.log("educationDetails", model?.educationDetails)
    const educationDetails = model?.educationDetails;

    const handleOpenModal = async (programCode: any, programData: any) => {
        let SubjectWiseMArks;
        console.log("modal_click",programData.subjectWiseMarks)
        if (programData.subjectWiseMarks === '{}' || programData.subjectWiseMarks === '' || programData.subjectWiseMarks ===null) {
            SubjectWiseMArks = [{ subjectCode: '', marks: '', cieGrade: {} }];
        } else {
            SubjectWiseMArks = JSON.parse(programData.subjectWiseMarks);
        }  
        console.log("modal_open_after",SubjectWiseMArks)

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    ...state.data,
                    curriculum: programData.curriculumCode,
                    subjectWiseMarksUpdateId: programData.id,
                    subjectWiseMarks: SubjectWiseMArks,
                },
            },
        });

        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showsubjectmarksModal' } });

        dispatch({
            type: 'SET_MODAL_DATA',
            payload: { title: programCode, data: [programData?.subjectWiseMarks, programData.curriculumCode,programData.id] },
        });
    };

    return (
        <div>
            <div className="educationDetails-detail tab-white-block">
                <div className="blockHeader">
                    <div className="row g-2  align-items-center">
                        <div className="col">
                            <div className="title">
                                School of{' '}
                                <span>
                                    {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                </span>{' '}
                            </div>
                        </div>
                        {model.isEditable && (
                            <div className="col-auto">
                                <a className="btn-add-section" role="button" onClick={() => navigate(`/alumni/${id}/education`)}><b className='icon-edit me-2'></b> <span>Edit</span></a>
                                
                            </div>
                            
                        )}
                    </div>
                </div>
                {programLevelArray &&
                    programLevelArray
                        .filter((program) => parseInt(program.code) < parseInt(pursuingEducationLevelCode))
                        .sort((a, b) => parseInt(b.code) - parseInt(a.code))
                        .map((program) => {
                            // Find matching data for the current program
                            const programData = educationDetails.find(
                                (item: any) => item.recordTypeCode === `CLASS_${program.code}` || item.classCode === program.code
                            );

                            return (
                                <div className="col-12">
                                    <div className="row g-2 align-items-center" key={`class-${program.code}`}>
                                        <div className="col-md">
                                            <div className="row g-2 align-items-center">
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="d-flex flex-column">
                                                        <div className="font-18 text-blue font-500">
                                                            Class {program.code}
                                                        </div>
                                                        <div className="label font-14 font-500 text-gray-64">Completion Year</div>
                                                        <div className="label-detail font-18 font-500">
                                                            {programData ? programData.endYear : '--'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5 col-sm-6">
                                                    <div className="d-flex flex-column">
                                                        <div className="label font-14 font-500 text-gray-64">School &amp; Board</div>
                                                        {programData ? (
                                                            <div className="label-detail font-18 font-500">
                                                                {isNaN(programData.class10SchoolName)
                                                                    ? `${programData.class10SchoolName}`
                                                                    : getDomainValueForCode(
                                                                          programData.class10SchoolName,
                                                                          'INSTITUTE_DOMAIN',
                                                                          state
                                                                      )}
                                                                , {programData.curriculum}
                                                            </div>
                                                        ) : (
                                                            '--'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="d-flex flex-column align-items-start">
                                                        <div className="label font-14 font-500 text-gray-64">Marks/Grade</div>
                                                        <div className="label-detail font-18 font-500">
                                                            {programData?.curriculum === 'CAIE'
                                                                ? calculateCIEGrade(programData?.cieGrade)
                                                                : addPercentageSymbolForCurriculum(
                                                                      programData?.marks,
                                                                      programData?.curriculum
                                                                  ) ?? '--'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {model.isEditable && (
                                            <div className="col-md-auto">
                                                <div className='btn-holder'>
                                                    {programData && !isEmpty(programData.endYear) && (
                                                        <a
                                                            href="javascript:;"
                                                            className="btn-add-section"
                                                            onClick={() => handleOpenModal(program.code, programData)}>
                                                            <b className="icon icon-edit me-2"></b> <span>Subject-wise Marks</span>
                                                        </a>
                                                        )}
                                                 </div>
                                            </div>
                                        )}
                                       
                                    </div>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                            );
                        })}
            </div>

            {/* <div className="modal fade" id="UpskillandEvolve" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
             <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-gray">
                            <h5 id="staticBackdropLabel" className="modal-title font-24 text-dark-blue">12th className Subject-wise Marks</h5>
                            <button type="button" className="btn-close btn-close-small m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-4 px-3 px-sm-4">

                            <form className="popCustForm px-2 px-sm-4">
                                
                                <div className="row g-5">
                    
                                    <div className="col-sm-6 text-gray-41">
                                        <ul className="green-right">
                                            <li>English- <span className="text-black font-500">88</span></li>
                                            <li>Economics- <span className="text-black font-500">92</span></li>
                                            <li>Commerce- <span className="text-black font-500">87</span></li>    
                                        
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 text-gray-41">
                                        <ul className="green-right">
                        
                                            <li>Accountancy- <span className="text-black font-500">93</span></li>
                                            <li>Business Studies- <span className="text-black font-500">95</span></li>                    
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div> */}
            {isConfigAvailable && <EditSubjectWiseMarksModal userdata={{ model, profile }} />}
        </div>
    );
};

export default EducationDetails;
