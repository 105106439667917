import { useContext, useEffect , useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import StudentQna from './StudentQna';
import AlumniQna from './AlumniQna';
import { GlobalContext } from '../../library/Core/GlobalContext';

const StudentDashboard = () => {
    const { ids } = useParams();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const userType = sessionState?.userTypeCode;

    // console.log(userId);
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: { messagesFromCode: null, messageTypeCode: null, filteredMessages: [], searchText: null },
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            
            <>
            <div className="main flex-1 bg-white">
            {userType === 'STUDENT' && (
                <StudentQna/>
            )}

            {userType === 'ALUMNI' && (
                <AlumniQna/>
            )}
            </div>
                <DebugBarControl />
            </>
        </SmartContext.Provider>
    );
};

export default StudentDashboard;
