import { getDomainValueForCode, isEmpty, setError, triggerGridDataOnChange } from '../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import { axiosClient } from '../../library/Service/axiosClient';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';
import { redirect } from 'react-router-dom';

export const handleSave = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const code = state?.internal?.widget_code;
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if ( (!customValidationPassed)) {
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    let saveUrl = `${process.env.REACT_APP_WIDGET_API_SERVER}/action/save`;
    let redirectUrlSlug = 'configure';

    switch (code) {
        case 'current_location_country':
            redirectUrlSlug = 'configuration';
            break;
    }
    // let widgetData = {...state?.data?.widgetData}
    // if ( code == 'top_companies' ) {    // Default value issue need to debug for large & medium heading fields in top companies widget;
    //     if ( isEmpty(state?.data?.widgetData?.largeHeading) ) {
    //         widgetData.largeHeading = `Our Alumni Work with Leading Companies Globally`
    //     }

    //     if ( isEmpty(state?.data?.widgetData?.mediumHeading) ) {
    //         widgetData.mediumHeading = `Alumni Spread Across World's Most Renowned Companies`
    //     }
    // }
    
    axiosClient()
        .post(`${saveUrl}`, {
            widget_code: code,
            data: JSON.stringify(state['data']),
            // data: JSON.stringify({...state['data'], widgetData: widgetData}),
            widget_institute_id: state['routeInfo']['id']
        })
        .then((res: any) => {
            const responseUserData = res.data as any;
            if (responseUserData.success == 0) {
                Swal.fire(responseUserData.message);
                return false;
            } else {
                dispatch({ type: 'ROUTE_INFO', payload: { ...state.routeInfo, id: responseUserData.id } });

                Swal.fire(responseUserData.message)
                setTimeout(() => {
                    state.actions['REDIRECT'](`/widget/${redirectUrlSlug}/${state?.internal?.widget_code}/${responseUserData.id}`);
                }, 1000)
            }
        })
        .catch((err: any) => {
            console.error(err);
            alert('Oops..! Something went wrong, Please try again in a while..!!');
        });
};

export const handlePreview = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${state?.routeInfo?.id}`;
    window.open(url, '_blank');
};

export const handleReset = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const widget_code =state.internal.widget_code;
    const metricData = state?.data?.metricData;
    let ctr = 0;

    if (!Array.isArray(metricData)) {
        console.error('metricData is not an array', metricData);
        return;
    }
    const updatedMetricData = metricData.map(item => ({ ...item }));
    // state.internal.top_careers.forEach((rec: any, idx: any) => {
    //     if (rec?.metricEntityCode == 0 || ctr >= 4) return;
    //     if (updatedMetricData[ctr]) {
    //         updatedMetricData[ctr]['metricType'] = rec?.metricEntityCode;
    //         ctr++;
    //     }
    // });

    let metricTypeKey = 'metricType';
    let metricEntityKey = 'metricEntity';
    let defaultMetricTypeValue: any = 'actual';
    
    switch (widget_code) {
        case 'directory_stats':
            metricTypeKey = 'statMetricType';
            metricEntityKey = 'statMetricEntity';
            defaultMetricTypeValue = 1;
            break;
    }

    state.data.metricData.forEach((rec: any, idx: any) => {
        if (rec?.metricEntityCode == 0) return;
        if (updatedMetricData[ctr]) {
            // updatedMetricData[ctr]['metricType'] = 'actual';
            updatedMetricData[ctr][metricTypeKey] = defaultMetricTypeValue;
            ctr++;
        }
    });

    dispatch({
        type: 'REFRESH_DATA',
        payload: {
            data: {...state.data, metricData: updatedMetricData}
        },
    });

    setTimeout(() => {
        // triggerGridDataOnChange(updatedMetricData, "metricData", "metricEntity");
        triggerGridDataOnChange(updatedMetricData, "metricData", metricEntityKey);
    }, 1000);
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    const widgetData    = state?.data?.widgetData;
    const metricData    = state?.data?.metricData;
    const code          = state?.internal?.widget_code;
    let isFormInvalid   = false;
    const metricEntityIds       = new Set();
    let minRows = 0;
    let metricEntityName = '';
    let metricEntityKey  = 'metricEntity';
    let valueKey         = 'value';
    let largeHeadingRequired = false;
    let mediumHeadingRequired = false;
    let headingRequired = false;

    const indexedMetricData = metricData.map((rec: any, idx: number) => ({ ...rec, originalIndex: idx }));

    switch (code) {
        case 'top_careers':
            minRows = 4;
            metricEntityName = 'career';
            headingRequired = true;
            break;

        case 'top_study_country':
        case 'current_location_country':
            minRows = 4;
            metricEntityName = 'country';
            headingRequired = true;
            break;

        case 'top_companies':
            minRows = 1;
            metricEntityName = 'industry';
            metricEntityKey  = 'metricEntity2';
            valueKey         = 'cnt';
            largeHeadingRequired    = true;
            mediumHeadingRequired   = true;
            break;
    
        case 'top_colleges':
            minRows = 1;
            metricEntityName = 'country';
            metricEntityKey  = 'metricEntity2';
            valueKey         = 'cnt';
            largeHeadingRequired    = true;
            mediumHeadingRequired   = true;
            break;

        case 'directory_stats':
            minRows = 1;
            metricEntityName = 'metric';
            metricEntityKey  = 'statMetricEntity';
            // valueKey         = 'displayValue';
            largeHeadingRequired    = true;
            mediumHeadingRequired   = true;
            break;
    }
    
    indexedMetricData
        // .filter((rec: any) => rec[metricEntityKey] || rec[valueKey])
        .forEach((rec: any) => {
            if ( isEmpty(rec[metricEntityKey]) ) {                
                setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [`Please select ${metricEntityName}!`], dispatch);
                isFormInvalid = true;
                return;
            }

            if (metricEntityIds.has(rec[metricEntityKey])) {
                setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [`Duplicates are not allowed!`], dispatch);
                isFormInvalid = true;
            } else {
                metricEntityIds.add(rec[metricEntityKey]);
            }

            if ( ['top_careers', 'top_study_country', 'current_location_country'].includes(code) ) {
                if ( isEmpty(rec.value) ) {
                    setError(`metricData.${rec.originalIndex}.value`, [`Please fill value..!`], dispatch);
                    isFormInvalid = true;
                }

                if ( !isEmpty(rec.value) && (parseInt(rec.value) <= 0 || parseInt(rec.value) > 100)) {
                    setError(`metricData.${rec.originalIndex}.value`, [`Value must be 1-100`], dispatch);
                    isFormInvalid = true;
                }
            }

            if ( ['directory_stats'].includes(code) ) {
                if ( isEmpty(rec?.displayValue) ) {
                    setError(`metricData.${rec.originalIndex}.displayValue`, [`Please fill value..!`], dispatch);
                    isFormInvalid = true;
                } else {
                    if (rec?.statMetricType == 1) {
                        setError(`metricData.${rec.originalIndex}.displayValue`, [], dispatch);
                    }
                }
            }

            if ( ['top_companies'].includes(code) ) {
                if ( !isEmpty(rec.cnt) && (parseInt(rec.cnt) <= 0)) {
                    let err = "No companies found!";
                    if ( state?.data?.widgetData?.sourceType == 'external_data') {
                        err = 'No company added!';
                    }
                    setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [err], dispatch);
                    isFormInvalid = true;
                }
            }

            if ( ['top_colleges'].includes(code) ) {
                if ( !isEmpty(rec.cnt) && (parseInt(rec.cnt) <= 0)) {
                    let err = "No colleges found!";
                    if ( state?.data?.widgetData?.sourceType == 'external_data') {
                        err = 'No college added!';
                    }
                    setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [err], dispatch);
                    isFormInvalid = true;
                }
            }

        });

    if ( !isEmpty(widgetData.other) && (parseInt(widgetData.other) < 0 || parseInt(widgetData.other) > 100)) {
        setError(`widgetData.other`, [`Invalid Percentage value`], dispatch);
        isFormInvalid = true;
    }

    if ( ['top_careers', 'top_study_country', 'top_companies', 'top_colleges', 'directory_stats'].includes(code) ) {
        if ( metricEntityIds.size <= (minRows-1) ) {
            errorMessages.push(`At least ${minRows} distinct ${metricEntityName} must be added to continue..!`);
            isFormInvalid = true;
        }
    }
    
    if (headingRequired && isEmpty(widgetData?.heading?.trim())) {
        setError(`widgetData.heading`, [`Please enter "Heading"`], dispatch);
        isFormInvalid = true;
    }

    if (largeHeadingRequired && isEmpty(widgetData?.largeHeading?.trim())) {
        setError(`widgetData.largeHeading`, [`Please enter "Large Heading"`], dispatch);
        isFormInvalid = true;
    }

    if (mediumHeadingRequired && isEmpty(widgetData?.mediumHeading?.trim())) {
        setError(`widgetData.mediumHeading`, [`Please enter "Medium Heading"`], dispatch);
        isFormInvalid = true;
    }
    
    // Dispatch the validation errors to the state
    dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'metricData', errorMessages },
    });

    return !isFormInvalid;
};

export const handleViewList = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const widget_code = state.internal.widget_code;
    let popupId     = '';
    const target    = event.target as HTMLButtonElement;
    const entityId  = target.dataset.val ?? '';
    let cnt         = 0;

    let entityName;
    let title;
    let icon;
    let noImg;
    let domainCode;
    let popupData: any = [];

    switch (widget_code) {
        case 'top_companies':
            popupId = 'top_companies_list';
            noImg   = 'briefcase.svg';
            domainCode = 'INDUSTRY_CODE';
            entityName = getDomainValueForCode(entityId, domainCode, state);
            break;

        case 'top_colleges':
            popupId = 'top_colleges_list';
            noImg   = 'no_university_img.svg';
            domainCode = 'COUNTRY_CODE';
            entityName = getDomainValueForCode(entityId, domainCode, state);
            break;
    }

    if (isEmpty(entityName)) {
        entityName = "Others";
    }

    title   = `${state.internal?.widget_name} with Alumni - ${entityName}`;
    icon    = `${process.env.REACT_APP_IMAGE_BASEPATH}/${noImg}`

    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'popupData', value: popupData } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_title', value: title } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_icon', value: icon } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedEntityId', value: entityId } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isModalOpen', value: true } });
    handlePopup(popupId, state);
}

export const handleAddNew = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const widget_code = state.internal.widget_code;
    let popupId     = '';
    const target    = event.target as HTMLButtonElement;
    const entityId  = target.dataset.val ?? '';
    let cnt         = 0;

    let entityName;
    let title;
    let icon;
    let noImg;
    let domainCode;

    switch (widget_code) {
        case 'top_companies':
            popupId = 'add_company';
            noImg = 'briefcase.svg';
            domainCode = 'INDUSTRY_CODE';
            entityName  = getDomainValueForCode(entityId, domainCode, state);

            if (isEmpty(entityName)) {
                entityName = "Others";
            }

            title   = `Add Company - ${entityName}`;
            icon    = `${process.env.REACT_APP_IMAGE_BASEPATH}/${noImg}`
    
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_title', value: title } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_icon', value: icon } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedEntityId', value: entityId } });
            break;
    }
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isModalOpen', value: true } });
    handlePopup(popupId, state);
}

const handlePopup = async (popupId: string, state: State) => {
    try {
            const modalElement = document.getElementById(popupId);
            if (modalElement) {
                if (!state.internal.isModalOpen) {
                    const modal = new Modal(modalElement);
                    modal.show();
                } else {
                    const modal = Modal.getInstance(modalElement);
                    if (modal) {
                        modal.hide();
                    }
                }
            }
    } catch (error) {
        console.error('Error opening modal:', error);
    }
};